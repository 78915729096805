import colors from '@theme/colors';
import { getDateInFormatLocale } from '@utils/dateUtils';
import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface OpenPeriod {
  start: string;
  end: string;
  payDateWithdrawal?: boolean;
}

interface OpenPayPeriodTextProps {
  openPeriod?: OpenPeriod;
}

const OpenPayPeriodText: React.FC<OpenPayPeriodTextProps> = ({
  openPeriod = { start: '', end: '', payDateWithdrawal: false },
}) => {
  const { t } = useTranslation('common');
  const { start, end, payDateWithdrawal } = openPeriod;

  if (start !== '' && end !== '') {
    return (
      <Text
        testID="txtOpenPayPeriod"
        marginBottom="16px"
        textAlign="center"
        variant="xsm-bold"
        color={colors.neutral.darkMist}
        flex={1}>
        {t('withdrawlSlider.openPeriodText')} {getDateInFormatLocale(start, 'DD MMM YYYY')} -{' '}
        {getDateInFormatLocale(end, 'DD MMM YYYY')} {payDateWithdrawal ? t('withdrawlSlider.payDateWithdrawal') : ''}
      </Text>
    );
  }
  return null;
};

export default OpenPayPeriodText;
