import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ActivityIndicator } from 'react-native';
import Modal from 'react-native-modal';
import styles from './styles';

interface BackdropLoaderProps {
  loading?: boolean;
}

const BackdropLoader: React.FC<BackdropLoaderProps> = ({ loading = false }) => {
  const { t } = useTranslation('common');

  if (!loading) {
    return null;
  }

  return (
    <Modal
      isVisible
      avoidKeyboard
      backdropColor={colors.black}
      style={styles.containerStyle}
      useNativeDriver
      backdropTransitionOutTiming={0}>
      <View style={styles.modal2}>
        <View style={styles.subView}>
          <Text testID="globalLoader" variant="md-bold" style={styles.textStyle} color={colors.neutral.charcoal}>
            {t('common.loader')}
          </Text>
          <ActivityIndicator size={scale(32)} color={colors.primary.carnation} />
        </View>
      </View>
    </Modal>
  );
};

export default BackdropLoader;
