import ArrowDown from '@atoms/ArrowDown';
import Baru from '@atoms/Loan/Baru';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import ModalAccounts from '@molecules/ModalAccounts';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Button, Icon, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, SafeAreaView, TouchableOpacity, Pressable } from 'react-native';
import { logAccountClick, logAccountSelected } from './analyticsAccountSelector';
import styles from './styles';

function openAccountSelector(setShowAccountSelector, selectedAccount, source, meAmplitudeContext) {
  return () => {
    setShowAccountSelector(true);
    logAccountClick(source, selectedAccount, meAmplitudeContext);
  };
}

function getAccountSelector(
  selectedAccount,
  setShowAccountSelector,
  homePage,
  txnPage,
  t,
  source,
  showBaruTag,
  meAmplitudeContext,
) {
  const { icon, color: accountBGColor, displayName, balance, accountTypeId } = selectedAccount;
  if (txnPage) {
    return (
      <Pressable
        testID="btnAccountSelector"
        sentry-label="btnAccountSelector"
        onPress={openAccountSelector(setShowAccountSelector, selectedAccount, source, meAmplitudeContext)}
        style={styles.container}>
        <Text variant="md-bold">
          {t('accountSelectorText.basedOn')} {displayName}
        </Text>
        <View style={styles.iconContainer} />
        <MaterialIcons name="keyboard-arrow-down" style={styles.arrowIcon} />
      </Pressable>
    );
  }

  if (homePage) {
    const customStyles = {
      borderRadius: 12,
    };
    return (
      <>
        <Baru showBaru={showBaruTag} animate customStyles={styles.homePageBaru} />
        <Button
          size=""
          style={[styles.accountSelectorContainer, { backgroundColor: accountBGColor, ...customStyles }]}
          testID="btnAccountSelector"
          sentry-label="btnAccountSelector"
          _text={{ color: colors.neutral.charcoal, variant: 'sm-bold' }}
          android_ripple={{ color: colors.disabled.smoke }}
          leftIcon={<RemoteImage uri={icon} width={15} height={15} />}
          rightIcon={<ArrowDown />}
          onPress={openAccountSelector(setShowAccountSelector, selectedAccount, source, meAmplitudeContext)}>
          {displayName}
        </Button>
      </>
    );
  }
  return (
    <>
      <Baru showBaru={showBaruTag} animate customStyles={styles.baruStyle} />
      <TouchableOpacity
        testID="btnAccountSelector"
        sentry-label="btnAccountSelector"
        onPress={openAccountSelector(setShowAccountSelector, selectedAccount, source, meAmplitudeContext)}
        style={styles.toStyles(accountBGColor)}>
        <RemoteImage uri={icon} width={15} height={15} />
        <Text flex={1} color={colors.neutral.charcoal} variant="sm-bold" style={appStyles.marginLeft(4.5)}>
          {displayName}
        </Text>
        <TextCurrency
          amountCurrency={balance}
          showCurrencySymbol={accountTypeId !== 1}
          variant="sm-bold"
          style={appStyles.marginRight(8)}
        />
        <Icon as={AntDesign} name="down" color={colors.neutral.charcoal} style={appStyles.marginRight(17)} />
      </TouchableOpacity>
    </>
  );
}

function AccountSelector(props) {
  const [showAccountSelector, setShowAccountSelector] = useState(false);
  const { t } = useTranslation('common');
  const {
    selectedAccount,
    withdrawlAccounts,
    setSelectedAccount,
    homePage,
    txnPage,
    source,
    meAmplitudeContext,
    excludeNewTag,
  } = props;

  const selectedAccountHandler = accountDetails => {
    logAccountSelected(source, accountDetails, meAmplitudeContext);
    setSelectedAccount(accountDetails);
  };

  let showBaruTag = false;

  if (!excludeNewTag) showBaruTag = withdrawlAccounts.some(e => e.canShowNew === true);

  return (
    <SafeAreaView>
      <View style={styles.accountSelectorRow}>
        {getAccountSelector(
          selectedAccount,
          setShowAccountSelector,
          homePage,
          txnPage,
          t,
          source,
          showBaruTag,
          meAmplitudeContext,
        )}
      </View>
      <ModalAccounts
        showAccountSelector={showAccountSelector}
        setShowAccountSelector={setShowAccountSelector}
        withdrawlAccounts={withdrawlAccounts}
        setSelectedAccount={selectedAccountHandler}
        source={source}
        selectedAccount={selectedAccount}
        excludeNewTag={excludeNewTag}
      />
    </SafeAreaView>
  );
}

AccountSelector.propTypes = {
  selectedAccount: PropTypes.shape({
    icon: PropTypes.string,
    color: PropTypes.string,
    displayName: PropTypes.string,
  }),
  withdrawlAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      accountTypeId: PropTypes.number,
      balance: PropTypes.number,
      displayBalance: PropTypes.bool,
      icon: PropTypes.string,
      color: PropTypes.string,
      displayName: PropTypes.string,
      amplitudeAccountName: PropTypes.string,
      isDynamic: PropTypes.bool,
      displayOrder: PropTypes.number,
      enableAccountCard: PropTypes.bool,
      screenName: PropTypes.string, // Can be null
      hasSlider: PropTypes.bool,
      sliderStep: PropTypes.number,
      buttonText: PropTypes.string,
      defaultSliderPercentage: PropTypes.number,
      minTxnValue: PropTypes.number,
      isWithdrawalEligible: PropTypes.bool,
      isBillPaymentEligible: PropTypes.bool,
      status: PropTypes.string,
      openPeriod: PropTypes.shape({
        start: PropTypes.string, // Assuming it's a date in string format
        end: PropTypes.string,
        payDateWithdrawal: PropTypes.bool,
      }),
      slider: PropTypes.shape({
        enabled: PropTypes.bool,
        incrementBy: PropTypes.number,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
      }),
      withdrawalButton: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      defaultWithdrawalAmount: PropTypes.number,
      canShowNew: PropTypes.bool,
    }),
  ),
  setSelectedAccount: PropTypes.func,
  homePage: PropTypes.bool,
  txnPage: PropTypes.bool,
  source: PropTypes.string.isRequired,
  meAmplitudeContext: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      event: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  excludeNewTag: PropTypes.bool,
};
AccountSelector.defaultProps = {
  selectedAccount: {
    icon: '',
    color: '',
    displayName: '',
  },
  withdrawlAccounts: [{}],
  setSelectedAccount: () => {},
  homePage: true,
  txnPage: false,
  meAmplitudeContext: [],
  excludeNewTag: false,
};

export default AccountSelector;
