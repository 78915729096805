import logEyeIconClicked from '@atoms/BankRow/analyticsBankRow';
import DotComponent from '@atoms/DotComponent';
import { Ionicons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { showStatusViewForBank } from '@utils/utils';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BankDetails {
  id: number;
  accountNumber: string;
  beneficiaryName: string;
  nickname?: string;
  status: string;
  bank?: {
    bankName: string,
  };
}

interface BankRowStatusRejectedProps {
  maskData?: boolean;
  maskIcon?: string;
  setMaskIcon?: (value: string) => void;
  setMaskData?: (value: boolean) => void;
  fromTransfer?: boolean;
  source?: string;
  item?: BankDetails;
}

const BankRowStatusRejected: React.FC<BankRowStatusRejectedProps> = ({
  maskData = false,
  maskIcon = '',
  setMaskIcon = () => {},
  setMaskData = () => {},
  fromTransfer = false,
  source = '',
  item = {
    id: 0,
    accountNumber: '',
    beneficiaryName: '',
    nickname: '',
    status: '',
    bank: {
      bankName: '',
    },
  },
}) => {
  const { t } = useTranslation('common');
  const { bankForTransfer } = getStore();

  return (
    <View style={appStyles.marginVertical(8)}>
      <Text variant="md-bold">{item?.bank?.bankName}</Text>
      <View flexDirection="row" alignItems="center">
        <Text variant="sm-normal">{getMaskedOrUnmaskedData(maskData, item?.accountNumber)}</Text>
        <Ionicons
          name={maskIcon}
          size={18}
          color="black"
          testID="btnMaskUnmaskImage"
          style={[appStyles.marginLeft(8), appStyles.flex1]}
          onPress={() => {
            logEyeIconClicked(source, maskData, item);
            setMaskIcon(setMaskIconValue(maskData));
            setMaskData(!maskData);
          }}
        />
        <View flex={1} alignItems="flex-end">
          {showStatusViewForBank(item, t, fromTransfer, bankForTransfer)}
        </View>
      </View>
      <View flexDirection="row" alignItems="center">
        {item?.nickname ? (
          <View flexDirection="row" alignItems="center">
            <Text color={colors.neutral.darkMist} variant="md-bold">
              {item?.nickname}
            </Text>
            <DotComponent />
          </View>
        ) : null}
        <Text color={colors.neutral.darkMist} variant="sm-normal">
          {item?.beneficiaryName}
        </Text>
      </View>
    </View>
  );
};

export default BankRowStatusRejected;
