import { Text } from 'native-base';
import React, { ReactNode } from 'react';
import { View } from 'react-native';
import styles from './style';

interface DetailRowProps {
  title: string;
  text?: string;
  customComponent?: ReactNode;
  show?: boolean | string;
}

const DetailRow: React.FC<DetailRowProps> = ({ title, text = '', customComponent = null, show = true }) => {
  const shouldRenderText = (textCopy: string, cComponent: ReactNode) => {
    if (textCopy) {
      return (
        <Text variant="md-normal" style={styles.textStyle}>
          {textCopy}
        </Text>
      );
    }
    return cComponent;
  };

  if (!show) return null;

  return (
    <>
      <View style={styles.rowStyle}>
        <Text variant="md-normal" style={styles.titleStyle}>
          {title}
        </Text>
        {shouldRenderText(text, customComponent)}
      </View>
      <View style={styles.lowerBorderStyle} />
    </>
  );
};

export default DetailRow;
