const sInternalError = 'Internal error';
const sPayloadBadRequest = 'Payload bad request';
const txnIncomeTextEn = 'Total Income refers to the funds provided by your company';
const sBalanceNotEnoughEn = 'Balance not enough';
const sNotInOpenPeriodEn = 'Not in open period';
const sAccountDisabledEn = 'Account is disabled';
const sAccountVerificationRuleEn = 'Account validation rules failed';
const sInvalidReferralEn = 'Invalid Referral Code';
const customerIdEng = 'Customer ID';
const termsandConditionEng = 'Terms And Conditions';
const phoneNumberEn = 'Add cellphone number';
const phoneNumberEng = 'Phone Number';
const phoneNumberInd = 'Data Pribadi';
const transaksiDetails = 'Transaction details';
const lockPeriodText =
  'Your transaction period is currently inactive. Please check your points account, office funds, or cash if available';
const inactiveEmployerStatus = 'Employer status inactive';
const en = {
  errorCodes: {
    'GG-BENE-01': 'Record conflict',
    'GG-BENE-02': sPayloadBadRequest,
    'GG-BENE-03': 'Already exists',
    'GG-BENE-04': 'Allowed enrolled beneficiary reached',
    'GG-BENE-05': 'Deletion not allowed',
    'GG-CON-OTP-01': 'Otp token required',
    'GG-CON-OTP-02': 'Otp token invalid',
    'GG-CON-OTP-03': 'Otp token already verified',
    'GG-CON-OTP-04': 'Please try again or re-send the code',
    'GG-CON-OTP-05': 'Otp token not found',
    'GG-CON-OTP-06': 'Otp token valid',
    'GG-CON-OTP-07': 'Verification code is invalid.\nPlease try again or re-send the code',
    'GG-CON-OTP-08': 'Too much request for verification code.\nPlease wait 1 hour to try again',
    'GG-EMP-01': 'Employee not found',
    'GG-EMP-02': 'Employee status inactive',
    'GG-EMP-03': 'Employee suspended',
    'GG-EMP-04': 'Employee employer suspended',
    'GG-EMP-05': 'Employee transaction is locked',
    'GG-EMP-06': 'Employee employer inactive',
    'GG-EMPYER-01': 'Employer not found',
    'GG-EMPYER-02': inactiveEmployerStatus,
    'GG-EMPYER-03': 'Employer suspended',
    'GG-EMPYER-04': 'Employer save failed',
    'GG-EMPYER-05': 'Conficting Record',
    'GG-EMPYER-LOGIN-01': 'Email not valid',
    'GG-EMPYER-LOGIN-02': 'Email or Password incorrect',
    'GG-EMPYER-LOGIN-03': 'Employer suspended',
    'GG-EMPYER-LOGIN-04': inactiveEmployerStatus,
    'GG-GEN-01': 'Bad Request',
    'GG-GEN-02': 'Too many request',
    'GG-GEN-03': 'Api Not found',
    'GG-GEN-04': sInternalError,
    'GG-GEN-05': 'Unauthorized JWT',
    'GG-GEN-06': 'Unauthorized AppKey',
    'GG-GEN-07': 'Unauthorized feature flag',
    'GG-GEN-08': 'Response timeout',
    'GG-GEN-09': 'Conficting Record',
    'GG-GEN-10': 'Limit parameter error',
    'GG-GEN-11': 'Skip parameter error',
    'GG-GEN-12': 'Limit parameter invalid',
    'GG-GEN-13': 'Skip parameter invalid',
    'GG-GOLD-01': 'Invalid Request Data',
    'GG-GOLD-02': 'Profile Already Exist',
    'GG-GOLD-03': 'Treasury Profile Not Found',
    'GG-OTP-01': 'Phone number not valid',
    'GG-OTP-02': 'Phone number is blocked',
    'GG-OTP-03': 'Invalid app key',
    'GG-OTP-04': 'Resend exhausted',
    'GG-OTP-05': 'Otp sms service error',
    'GG-OTP-06': 'Messaging Type invalid',
    'GG-OTP-07': 'Phone number not registered',
    'GG-OTP-08': 'Too much request for verification code.Please wait 1 hour to try again',
    'GG-PHONE-01': sPayloadBadRequest,
    'GG-PHONE-02': 'Already exists',
    'GG-PHONE-03': 'Allowed enrolled phone number reached',
    'GG-PHONE-04': 'Deletion not allowed',
    'GG-PRODUCT-01': sInternalError,
    'GG-PRODUCT-02': 'Product not found',
    'GG-PRODUCT-03': 'Provider is feature flag',
    'GG-PRODUCT-04': 'Product unavailable',
    'GG-SIGNUP-01': 'Record already exists',
    'GG-SIGNUP-02': sInternalError,
    'GG-SIGNUP-03': sPayloadBadRequest,
    'GG-SIGNUP-04': 'Invalid Employer Name',
    'GG-SIGNUP-05': 'Invalid User Name',
    'GG-SIGNUP-06': 'Invalid Phone Number payload',
    'GG-SIGNUP-07': 'Invalid Identity type',
    'GG-SIGNUP-08': 'Invalid Identity id',
    'GG-TRX-01': 'User in lock period',
    'GG-TRX-02': 'Employee transaction suspended',
    'GG-TRX-03': 'Employee category blocked',
    'GG-TRX-04': 'Account blocked for category',
    'GG-TRX-05': 'Insufficient Balance',
    'GG-TRX-06': sInternalError,
    'GG-TRX-07': 'Provider error',
    'GG-TRX-08': 'Product not found',
    'GG-TRX-09': 'Product unavailable',
    'GG-USER-01': 'User not found',
    'GG-USER-02': 'User status inactive',
    'GG-USER-03': 'User suspended',
    'GG-USER-04': 'Failed to retrieve user info',
    'GG-USER-05': 'User KTP Not Found',
    'GG-KTP-01': 'Ktp number is invalid',
    'GG-KTP-02': 'Ktp name is invalid',
    'GG-KTP-03': 'Ktp number length exceed limit',
    'GG-KTP-04': 'Ktp name length exceed limit',
    'GG-KTP-05': 'Ktp number length less than required length',
    'GG-RESEND-OTP-01': 'Otp token required',
    'GG-RESEND-OTP-02': 'Otp token not found',
    'GG-TRX-10': 'Invalid Transaction Catergory',
    'GG-TRX-11': 'Bank info not found',
    'GG-TRX-13': 'Registered Bank not approved',
    'GG-TRX-14': 'The requested amount is less than the minimum withdrawal',
    'GG-PRODUCT-05': 'Provided account type id is invalid',
    'GG-PRODUCT-06': 'Payload bad request',
    'GG-REASON-TYPE-01': 'Reason type filter invalid',
    'GG-EXT-01': 'Partner Side API Error',
    'GG-PARTNER-01': 'employerIds required',
    'GG-PARTNER-02': 'employerIds should be an array',
    'GG-PARTNER-03': 'groupName should be an array',
    'GG-PARTNER-04': 'secondaryGroupName should be an array',
    'GG-PARTNER-05': 'Withdeleted should be boolean',
    'GG-PARTNER-06': 'page should be a number',
    'GG-PARTNER-07': 'page number invalid',
    'GG-PARTNER-08': 'limit should be a number',
    'GG-PARTNER-09': 'limit invalid',
    'GG-INVOICE-01': 'No record found for RN generation',
    'GG-PC-01': 'Payment cycle Not Found',
    'GG-TRXH-01': 'Status not an array',
    'GG-TRXH-02': 'Payment cycle id is not a string',
    'GG-REQ-WD-01': 'Account invalid',
    'GG-REQ-WD-02': 'Reason invalid',
    'GG-REQ-WD-03': sBalanceNotEnoughEn,
    'GG-REQ-WD-04': sNotInOpenPeriodEn,
    'GG-REQ-WD-05': sAccountDisabledEn,
    'GG-REQ-WD-06': sAccountVerificationRuleEn,
    'GG-REQ-WD-07': 'Bank Account Number not enrolled / approved',
    'GG-REQ-WD-08': 'Invalid Bank Account Number',
    'GG-REQ-WD-09': 'Invalid Request Amount',
    'GG-REQ-WD-10': 'Invalid Reason Id',
    'GG-REQ-WD-11': 'Invalid Account Number',
    'GG-REQ-WD-12': sInvalidReferralEn,
    'GG-CON-WD-01': 'Transaction not found',
    'GG-CON-WD-02': 'Account invalid',
    'GG-CON-WD-03': sBalanceNotEnoughEn,
    'GG-CON-WD-04': sNotInOpenPeriodEn,
    'GG-CON-WD-05': sAccountDisabledEn,
    'GG-CON-WD-06': sAccountVerificationRuleEn,
    'GG-RF-01': sInvalidReferralEn,
    'GG-RF-02': "Referral Code Not Applicable. Since its not first tnx'",
    'GG-RF-03': "You can't use your own Referral code. Please use your friend's Referral code.",
    'GG-RF-04': 'Invalid code. Please double-check the referral code from your friend.',
    'GG-RF-05': 'The account of the friend who owns the referral code is no longer active. Please use another code.',
    'GG-RF-06': 'The status of the friend who owns the referral code is currently inactive. Please use another code.',
    'GG-POINT-01': 'Point Account does not exists',
    'GG-SF-01': 'Salary profile has not been registered',
    'GG-LOAN-01': 'Invalid prodcut code for loan',
    'GG-LOAN-02': 'Employee not eligible for loan product',
    'GG-LOAN-03': 'Loan code must be a string',
    'GG-LOAN-04': 'Loan code must not be empty',
    'GG-LOAN-05': 'Loan application already exists',
    'GG-LOAN-06': 'Invalid application Id',
    'GG-LOAN-07': 'Application ID must be a valid number and not null',
    'GG-CON-BP-01': 'Provider Id not found',
    'GG-CON-BP-02': 'The request has expired. Please ask again',
    'GG-REQ-BP-01': 'Account not found',
    'GG-REQ-BP-02': 'Reason invalid',
    'GG-REQ-BP-03': 'Gajigesa balance is not enough to conduct this transaction.',
    'GG-REQ-BP-04': sNotInOpenPeriodEn,
    'GG-REQ-BP-05': sAccountDisabledEn,
    'GG-REQ-BP-06': sAccountVerificationRuleEn,
    'GG-REQ-BP-09': 'Invalid Request Amount',
    'GG-REQ-BP-10': 'Invalid Reason Id',
    'GG-REQ-BP-11': 'Invalid Account Number',
    'GG-REQ-BP-12': sInvalidReferralEn,
    'GG-REQ-BP-13': 'Invalid reason description',
    'GG-REQ-BP-14': 'Invalid postpaid amount from ayoconnect',
    'GG-REQ-BP-15': 'Oops, an error occurred. Please try again',
    'GG-REQ-BP-16': 'Billing is not yet available',
    'GG-REQ-BP-17': 'Customer Mobile Number/ ID is wrong.Please check again',
    'GG-REQ-BP-18': 'This number is not postpaid',
    'GG-REQ-BP-19': 'Invalid Transaction BP Catergory',
    'GG-REQ-BP-20': 'Transaction has failed, please try again',
    'GG-REQ-BP-21': 'Vouchers have expired. Look forward to regular updates, OK!',
    'GG-REQ-BP-22': 'Request Transaction already confirmed',
    'GG-REQ-BP-23': 'The bill has been paid!',
    'GG-VAL-01':
      'Employee Status is invalid. employeeStatus must be active, inactive, withdraw_suspended, and withdraw_accrual_suspended',
    'GG-VAL-02': 'Invalid format for minAmount. Should be a number',
    'GG-VAL-03': 'Invalid format for maxAmount. Should be a number',
    'GG-VAL-04': 'Invalid format for payDate. Should be a string',
    'GG-VAL-05': 'accountType must be EWA, POINTS, GOLD, SALARY_DISBURSEMENT, UANG_KANTOR, UANG_KAS, SD_OFFLINE',
    'GG-VAL-06': 'Invalid format for employeeGroup. Should be an array of strings',
    'GG-VAL-07': 'Invalid format for search. Should be a string',
    'GG-VAL-08': 'Invalid format for employeeCode. Should be an array of string',
    'GG-VAL-09': 'Invalid format for secondaryEmployeeGroup. Should be an array of string',
    'GG-VAL-10': 'Invalid format for employeeId. Should be a number',
    'GG-VAL-11': 'Invalid format for suspensionCategory. Should be string',
    'GG-VAL-12': 'Invalid format for suspendedReason. Should be string',
    'GG-VAL-13': 'Invalid email format',
    'GG-VAL-14': 'Invalid format for fromTransactionDate. Should be a date string',
    'GG-VAL-15': 'Invalid format for toTransactionDate. Should be a date string',
    'GG-VAL-16':
      'Invalid value for transaction status. Transaction status must be PENDING, COMPLETED, REJECTED, FAILED',
    'GG-VAL-17': 'Invalid format for transactionCategoryId. Should be an array of string',
    'GG-VAL-18': 'Invalid format for fromTransactionAmount. Should be a number',
    'GG-VAL-19': 'Invalid format for toTransactionAmount. Should be a number',
    'GG-VAL-20': 'Invalid format for partnerId. Should be a number',
    'GG-VAL-21': 'Invalid format for employeeCode. Should be string',
    'GG-VAL-22':
      'Invalid format for employee request status. request status must be pending, completed, processing, failed or rejected',
    'GG-VAL-23': 'collar type must be either "BLUE" or "WHITE"',
    'GG-VAL-24': 'Invalid format for userId. Should be number',
    'GG-VAL-25': 'Invalid format for firstName. Should be string',
    'GG-VAL-26': 'Invalid format for lastName. Should be string',
    'GG-VAL-27': 'Invalid format for request type. Should be string',
    'GG-VAL-28': 'Invalid format for birthDate. Should be string',
    'GG-VAL-29': 'Invalid format for mobile number. Should be string',
    'GG-VAL-30': 'Invalid format for gender. Should be string',
    'GG-VAL-31': 'Invalid format for ktp number. Should be string',
    'GG-VAL-32': 'Invalid format for joined at. Should be string',
    'GG-VAL-33': 'Invalid format for group name. Should be string',
    'GG-VAL-34': 'Invalid format for department name. Should be string',
    'GG-VAL-35': 'Invalid format for designation name. Should be string',
    'GG-VAL-36':
      'Invalid format for salary type id. SalaryType must be MONTHLY, DAILY, WEEKLY, WORK_UNIT, HOURLY, BIWEEKLY, FIXED_MONTH, FIXED_LENGTH, NEW_WEEKLY, BI_MONTHLY',
    'GG-VAL-37': 'Invalid format for salary amount. Should be number',
    'GG-VAL-38': 'Invalid format for employees_bank_name. Should be string',
    'GG-VAL-39': 'Invalid format for employees bank account no. Should be string',
    'GG-VAL-40': 'Invalid format for beneficiary name. Should be string',
    'GG-VAL-41': 'Invalid format for file name. Should be string',
    'GG-VAL-42': 'Invalid format for employee name. Should be string',
    'GG-VAL-43':
      'Invalid format for employee request sort by. sort by must be employeeCode, employeeName or uploadedAt',
    'GG-VAL-44': 'Invalid format for employee request sort order. sort order must be asc or desc',
    'GG-GEN-14': 'Page number parameter invalid',
    'GG-GEN-15': 'Limit should not be empty',
    'GG-GEN-16': 'pageNo should not be empty',
    'GG-GEN-17': 'Token Expired',
    'GG-INVOICE-02': 'Group Name is required',
    'GG-VAL-47': 'Invalid value for gender. Gender must be MALE or FEMALE.',
    'GG-EMP-REQ-01': 'Invalid employee request type',
    'GG-EMP-REQ-02': 'Employee Update request already exists',
    'GG-EMP-REQ-03': 'No data to be updated',
    'GG-EMP-REQ-04': 'Suspension Category not provided',
    'GG-EMP-REQ-05': 'Employee request not found',
    'GG-EMP-REQ-06': 'Failed to upload file',
    'GG-EMP-REQ-07': 'Employer Id not found',
    'GG-EMP-REQ-08': 'Employer not found',
    'GG-EMP-REQ-09': 'Phone number is invalid',
    'GG-GEN-18': 'Record not found',
    'GG-KTP-06': 'Promo code is invalid',
    'GG-OTP-09': 'Cannot access Employer Dashboard',
    'GG-REF-TKN-01': 'Refresh token required',
    'GG-REF-TKN-02': 'Refresh Token exhausted',
    'GG-REF-TKN-03': 'Refresh token not found',
    'GG-REF-TKN-04': 'Refresh token expired',
    'GG-EMP-07': 'Can not upgrade employee status',
    'GG-EMP-08': 'Employee has no infuencer role',
    'GG-EMPYER-06': 'Employer email not found',
    'GG-EMPYER-07': 'Transaction Summary Request not found',
    'GG-BENE-06': 'Invalid beneficiary id',
    'GG-BENE-07': 'Invalid account number',
    'GG-BENE-08': 'Invalid bank id',
    'GG-BENE-09': 'Invalid beneficiary name',
    'GG-BENE-10': 'Invalid beneficiary nickname',
    'GG-BENE-11': 'Invalid security pin format',
    'GG-PHONE-05': 'Invalid phone number id',
    'GG-PHONE-06': 'Invalid mobile number',
    'GG-PHONE-07': 'Invalid owner name',
    'GG-PHONE-08': 'Invalid security pin format',
    'GG-PRODUCT-07': 'Category Id invalid',
    'GG-GOLD-04': 'Invalid transaction id',
    'GG-RESEND-OTP-03': 'Otp token expired',
    'GG-RESEND-OTP-04': 'Otp resend exhausted',
    'GG-REQ-WD-13': 'Invalid reason description',
    'GG-REQ-WD-14': 'Amount receivable is below 10k',
    'GG-PARTNER-LOGIN-01': 'x-auth-api-key not found',
    'GG-PARTNER-LOGIN-02': 'Employer not found. Invalid x-auth-api-key or employerCode',
    'GG-BULK-SD-01': 'Failed to upload file',
    'GG-ATD-01': 'No attendance record available for given date',
    'GG-ATD-02': 'Attendance Date must be in range of payment cycle',
    'GG-ATD-03': 'No attendance records to update',
    'GG-ATD-04': 'Balance recalculation failed',
    'GG-VAL-45': 'Invalid format for attendance date. date must be a valid date string',
    'GG-VAL-46': 'Invalid format for attendance value. It must be "ABSENT" or "PRESENT"',
    'GG-PR-CON-01': 'No pricing config found',
    'GG-WHATSAPP-APP-OT-01': inactiveEmployerStatus,
    'GG-WHATSAPP-APP-OT-02': 'Mobile Number is Required',
    'GG-WHATSAPP-APP-OT-03': 'Amount is Required',
    'GG-SEC-PIN-01': 'Security Pin Invalid',
    'GG-SEC-PIN-02': 'Uploading failed',
    'GG-SEC-PIN-03': 'Registration failed',
    'GG-SEC-PIN-04': 'Security Pin not registered',
    'GG-SEC-PIN-05': 'Security Pin reset needed',
    'GG-SEC-PIN-06': 'Security Pin Invalid',
    SERVER_PING_FAILED: 'Server Ping Failed',
    REDIS_PING_FAILED: 'Unable to connect to Redis',
    DB_PING_FAILED: 'Unable to connect to Database',
    KAFKA_PING_FAILED: 'Unable to connect to Kafka',
    NOT_ENOUGH_STORAGE: 'Disk Storage is running out. Free space is less than 20%',
    HEAP_MEMORY_EXCEEDED: 'Heap Memory exceeded 300MB',
    RSS_MEMORY_EXCEEDED: 'RSS Memory exceeded 300MB',
    'GG-DIS-01': 'Invalid recipient bank',
    'GG-DIS-02': 'Invalid bank account',
    'GG-DIS-03': 'Invalid amount',
    'GG-DIS-04': 'Invalid transaction id',
    'GG-DIS-05': 'Invalid disbursement provider',
    'GG-DIS-06': 'Invalid note',
    'GG-DIS-07': 'Invalid webhook url',
    'GG-DIS-08': 'Transaction already disbursed',
    'GG-MSG-01': 'Channel invalid. it should be "SMS", "WHATSAPP"',
    'GG-MSG-02': 'Content must be string',
    'GG-MSG-03': 'Send to provider not successful. Trigger retry',
    'GG-NOTIFY-01': 'Channel invalid. it should be "DISCORD"',
    'GG-NOTIFY-02': 'Content must be string',
    'GG-NOTIFY-03': 'Send to provider not successful. Trigger retry',
    'GG-NOTIFY-04': 'Webhook invalid',
    'GG-NOTIFY-05': 'Avatar link invalid',
    'GG-NOTIFY-06': 'Sender name invalid',
    'STATUS-01': 'Your account is not active',
    'STATUS-02': 'Account not allowed',
    'STATUS-03': 'Balance Insufficient',
    'STATUS-04': 'You are not in the transaction cycle',
  },
  tnc: {
    headerText: termsandConditionEng,
    tncHeader: termsandConditionEng,
    tncLastUpdated: 'Last updated',
    subTitle01: '1. General provisions',
    subTitle02: '2. Use of services',
    subTitle03: '3. Akun is underground',
    subTitle04: '4. Service',
    subTitle05: '5. PAYMENT',
    subTitle06: '6. Privacy Policy',
    subTitle07: '7. Ownership rights and intellectual property',
    subTitle08: '8. Account cancellation',
    subTitle09: '9. Services are provided "as are" and "as available"',
    subTitle10: '10. Restrictions on responsibility and compensation',
    subTitle11: '11. Applicable law and jurisdiction',
    subTitle12: '12. COMMUNICATION',
    subTitle13: '13. Others',
    subTitle14: '14. CUSTOMER SERVICE',
    dataSubTitle1Data0Text:
      'I. The provisions of this use describe the provisions governing the use of Gajigesa services provided for you, in the form of sales and provision of features, technology, products, software, and services, or other functions provided by the product or service by Gajigesa, includingbut not limited to:',
    dataSubTitle1List0: 'a. Gajigesa application ("application");',
    dataSubTitle1List1: 'b. www.gajigesa.com and app.gajigesa.com ("site");',
    dataSubTitle1List2: 'c. Product service;',
    dataSubTitle1List3: 'd. Services for companies;',
    dataSubTitle1List4: 'e. Services for employees;',
    dataSubTitle1List5:
      'f. Services, content, updates, features, software, and other products as well as new releases that we may provide from time to time. NEFE is together referred to as "service".',
    dataSubTitle1Data1Text:
      'II. Gajigesa can change the terms of use from time to time, and Gajigesa will notify the changes to the user via email or by giving a written message when the user uses the service.If the user continues to use the service after receiving the message, the user is considered to agree to submit to the changes in the provisions of the use.',
    dataSubTitle1Data2Text:
      'III. From time to time, Gajigesa can add, change or eliminate all or some features or functions in service.Users can install the latest version to accept these changes.Gajigesa can also decide to stop providing all or part of the service at all times, and there is no one in the provisions of this use that can be used as a guarantee that the application or part of any service will always be available, both in the current form or formOthers, and/or that Gajigesa will continue to support, maintain, or continue to offer services and/or applications or any version from it.',
    dataSubTitle1Data3Text:
      'IV. Gajigesa can offer special features that have separate terms and conditions in addition to the provisions of this use ("Separate Use Terms").In this case, there are separate use provisions that will apply if there is a discrepancy with the provisions of this use.',
    tncDataDescription:
      'Terms and Conditions for this Use ("Terms of Use") Regulate the relationship between PT Easy Management Systems ("Gajigesa", "We", or "Our") with its users ("Users", "Customers", "You") In connection withService (as defined below). nmmis review the provisions of this use carefully.By removing, the user states that the user has read, understanding, accepting, agreeing, and declaring willing to be bound to the provisions of this use.',
    dataSubTitle2FooterDescription:
      'If you violate one of the above or more conditions, the provisions of this use and your right to use services can be ended unilaterally by Gajigesa and Gajigesa can take other actions allowed by applicable law.',
    dataSubTitle2Data0Text:
      'I. By subject to the provisions in the provisions of this use, Gajigesa hereby gives you rights and licenses that are limited, can be withdrawn, not exclusive, cannot be transferred (unless explicitly mentioned in the provisions of this use) to install and use services.',
    dataSubTitle2Data1Text:
      'II.You agree that the service can only be used for purposes as permissible based on (a) the provisions of this use;and (b) every applicable law, regulations, practices, or guidelines that are generally accepted in related jurisdiction.',
    dataSubTitle2Data2Text:
      'III.You are not allowed (and you are not allowed to allow other parties) to copy, change, make derivatives from, Reverse Engineer (Reverse Engineer), parses, or otherwise try to take the source code (source code) service, content (as definedbelow), or any part of it.',
    dataSubTitle2Data3Text:
      'IV.You agree to use services in a reasonable way.If Gajigesa assumes that you use services unnaturally or can cause a decrease in service performance for customers or other users, then Gajigesa can impose restrictions on your use of services.If possible, Gajigesa will give a notification of 24 (twenty four) hours before to you and ask you to reduce use before being subject to any restrictions.',
    dataSubTitle2Data4Text:
      'V. You are fully responsible for the use of services and agree not to hold accountable from Gajigesa for all demands, losses, and/or loss in connection with the use of services.',
    dataSubTitle2Data5Text:
      'VI.Applications and services may only be used on compatible and appropriate devices.You are responsible for checking and ensuring that you have downloaded applications and services on compatible and appropriate devices.Gajigesa is not responsible if you don"t have a compatible and appropriate device.',
    dataSubTitle2Data6Text: 'VII.You are forbidden to:',

    dataSubTitle2Data6List0:
      'a.Use services in a way that violates the law, or for purposes that violate the law or fraud, or in any way that is not consistent with the provisions of use;',
    dataSubTitle2Data6List1:
      'b.Violate Gajigesa"s intellectual property rights or third parties in connection with the use of services;',
    dataSubTitle2Data6List2:
      'c.Send any material that is slandering, insulting, or other controversial in connection with your use of services;',
    dataSubTitle2Data6List3:
      'd.Use services in a way that can damage, paralyze, burden, make defects, or weaken the system or safety of Gajigesa or intervene in other users;',
    dataSubTitle2Data6List4:
      'e.Collect or take any information from the gajigesa facility or system, or try to decipher (decipher) transmission for or from servers that use services;',
    dataSubTitle2Data6List5: 'f.Access or register via user login using a bot or other automatic methods;',
    dataSubTitle2Data6List6:
      'g.Reproducing, duplicating, copying, deconstructing, selling, trading, or reselling the intended services;',
    dataSubTitle2Data6List7:
      'h.Copying, changing, making derivatives from, doing reverse engineers (reversed engineering), decomposes, or otherwise try to take the source code (source code) service, content (as defined below), or any part of it or allow other parties todo it.',
    dataSubTitle3Data0Text:
      "I. To be able to use the Service, you must first register as a GajiGesa user. To become a registered user on GajiGesa, the company you work for will provide the necessary data to GajiGesa to register you as an active GajiGesa user. You are not allowed to use another person's account without permission. You must provide accurate, up-to-date, and complete account information, and you must keep the account information correct, accurate, up-to-date, and complete.",
    dataSubTitle3Data1Text:
      'II. You are the sole person authorized to access and use your account. You are required to maintain the security and confidentiality of your account and password. You are prohibited from allowing or enabling others to access or use your account and password. You may share your account and password with trusted representatives, but you are solely responsible for the use of the Service by anyone using your account and password. You hereby release and indemnify us from any liability, actions, claims, demands, costs, expenses, or losses incurred or suffered by us as a result of the misuse of the Service using your account and password.',
    dataSubTitle3Data2Text:
      'III. You must immediately notify GajiGesa in the event of any security breach or unauthorized use of your account. You are also required to promptly change your password. We reserve the right to terminate your account or block your access to the Service if you violate the provisions regarding the security of your password.',
    dataSubTitle4Data0Text:
      'I. GajiGesa provides you with a Service period for a specific duration as agreed upon between PT Easy Management Systems and the company you work for. All data you store in connection with the Service will be stored and become the property of PT Easy Management Systems.',
    dataSubTitle4Data1Text:
      'II. Your subscription period for the Service will end at the end of the subscription period as agreed upon with the company you work for. If you choose not to subscribe to the Service until the end of the subscription period, you will not be able to continue using the Service. All data you store in connection with the Service will be stored and can be accessed again after you resubscribe to the Service. We reserve the right to delete your data if it has been more than 30 (thirty) days since the end of the Service subscription period.',
    dataSubTitle4Data2Text:
      'III. The Service may include features that connect you to one of our partners. By choosing to use such Service, you understand and agree to be subject to the applicable terms and conditions of the partner, which complement these Terms of Use.',
    dataSubTitle5Data0Text:
      'I. Users acknowledge that, if applicable, Users agree to the fees, pricing, and billing cycle related to the Service as (i) indicated on the Application and/or Site at the time of using the Service, and (ii) changed from time to time in accordance with the Terms of Use as agreed upon with the company you work for at present.',
    dataSubTitle5Data1Text:
      'II. GajiGesa may, at its own discretion, change the terms of fee imposition and pricing applicable to any type of service, including changing the fees and prices of the Service, by providing notice to you. If you do not agree with such changes, you may terminate your subscription to the Service.',
    dataSubTitle5Data2Text:
      'III. If your payment and registration information is incorrect, outdated, or incomplete, and you fail to promptly notify us of such changes, we may refuse to provide the Service to you in any form.',
    dataSubTitle5Data3Text:
      'IV. All payments will be made without any deductions, particularly tax or any other kind of tax deductions, therefore you are fully responsible for them.',
    dataSubTitle6Data0Text:
      'I. You hereby grant GajiGesa a royalty-free, irrevocable, perpetual, sublicensable, and worldwide permission to use (including for commercial purposes) the information and/or data collected by GajiGesa through your use of the Service, provided that GajiGesa will aggregate (with data from other users) or anonymize the information or data before using it. Except for this right, GajiGesa does not claim any other intellectual property rights related to the information or content provided by you on the Service.',
    dataSubTitle6Data1Text:
      'II. The use and/or access to the Service will also be subject to all rules set forth in the GajiGesa privacy policy available on the Application and/or Site (“Privacy Policy”). By agreeing to these Terms of Use, we assume that you have read and agreed to the contents of the Privacy Policy. The Privacy Policy and these Terms of Use are a unified document that must be agreed upon by the User before registering and using the Service.',
    dataSubTitle7Data0Text:
      'I. You acknowledge that all information and data, such as website pages, site services, data files, text, computer programs, videos, audios, images, photos, animations, or other materials (collectively referred to as “Content”) that you can access as part of, or through your use of the Service, are solely owned by GajiGesa. You acknowledge and agree that GajiGesa has all legal rights, ownership, and legitimate interests in and to the Service and Content, including any intellectual property rights existing in the Service and Content (whether registered or not, and in any country where such rights arise). You agree not to challenge or do anything inconsistent with such ownership.',
    dataSubTitle7Data1Text:
      "II. The rights to use granted to you by these Terms of Use do not constitute a transfer or sale of GajiGesa's ownership rights in the Service, Content, systems, or any part thereof. Except for the rights of use mentioned in Section 2.1 of these Terms of Use, GajiGesa retains all rights, ownership, and interests (including all intellectual property rights) in the Service or its copies. Any attempt to use the Service beyond what is permitted by this license will automatically terminate the license.",
    dataSubTitle7Data2Text:
      'III. None of the provisions in these Terms of Use give you the right to use any trademark, trade name, service mark, logo, or domain name of GajiGesa in any way that is intended or likely to cause confusion to consumers regarding the ownership or authorized users of any such trademarks, trade names, service marks, logos, or domain names. You are not allowed to publish or use any GajiGesa trademarks, trade names, service marks, or logos without prior written consent from GajiGesa.',
    dataSubTitle8Data0Text: 'I. GajiGesa may cancel or terminate User accounts at any time based on its own policies.',
    dataSubTitle8Data1Text:
      "II. Users have the right to cancel their accounts in the Service at any time by following the instructions available on the User account through the Application or Site, or by contacting or sending an email to GajiGesa's Customer Support. If Users cancel their own accounts before the end of the Service subscription period (whether monthly, yearly, or for a specific period), the cancellation will take effect immediately.",
    dataSubTitle8Data2Text:
      "III. In the event of an account cancellation, (a) the account may be deactivated or deleted; (b) all User rights under these Terms of Use will immediately cease; and (c) without prejudice to applicable laws, all User data and content may be promptly deleted from the GajiGesa system. GajiGesa cannot be held liable for any loss or damage resulting from the cancellation of a User account, and it is the User's responsibility to ensure that any necessary content and data are transferred or duplicated before the account cancellation takes place.",
    dataSubTitle9Data0Text:
      "I. The Service is provided on an 'as is' and 'as available' basis, and your use of it is at your own risk. GajiGesa will promptly (during normal business hours) address any technical issues that arise in connection with the Service.",
    dataSubTitle9Data1Text: 'II. GajiGesa does not state or warrant that:',
    dataSubTitle9Data2Text:
      "III. You acknowledge and agree that GajiGesa may use third-party suppliers to provide software, networking, connectivity, storage, and other technologies to provide the Service. The actions and conduct of such suppliers may be beyond GajiGesa's control, and GajiGesa will not accept any liability for any loss or damage suffered by you as a result of the actions or conduct of such suppliers. On behalf of itself and the third-party suppliers, GajiGesa excludes all warranties that may be implied or otherwise applicable under applicable laws and regulations, to the extent permitted by applicable law.",
    dataSubTitle9Data3Text:
      'IV. For your convenience, GajiGesa may provide links to websites or content owned or operated by third parties on the internet. The linked websites or content are not under the control of GajiGesa, and GajiGesa is not responsible for any inaccuracies, omissions, delays, slanders, defamations, false statements, pornography, indecent content, inaccuracies, or other materials contained in such websites or content, or the consequences of accessing any such linked websites or applications, as well as the accuracy of information, content, products, or services offered by, or information contained in the linked websites or applications. As third-party websites or applications may have different privacy policies and/or security standards applicable to them, we recommend that you review the privacy policies and terms and conditions of such websites and applications before providing any personal information.',
    dataSubTitle9Data4Text:
      'V. Any links to any other websites or content are not an endorsement or verification of such websites or content, and you agree that the risk of accessing such linked websites or content lies entirely with you.',
    dataSubTitle9Data1List0: 'a. The Service will meet your specific needs;',
    dataSubTitle9Data1List1:
      'b. The Service will always be timely, secure, and free from errors (error-free), interruptions, theft, or destruction;',
    dataSubTitle9Data1List2: 'c. The Service will always be accurate and reliable;',
    dataSubTitle9Data1List3:
      'd. The quality of any products, services, information, or materials purchased or obtained by you through the Service will meet your expectations;',
    dataSubTitle9Data1List4: 'e. Any errors in the Service will always be corrected;',
    dataSubTitle9Data1List5:
      'f. The Service will be compatible with any mobile device, computer, operating system, or other device;',
    dataSubTitle9Data1List6:
      'g. The Service provided will be entirely secure and free from bugs, viruses, trojans, and other harmful software components. It is your responsibility and obligation to ensure that your mobile devices and computer systems have reliable and regularly updated antivirus software, and that you regularly perform backups of the devices used to access the Service.',
    dataSubTitle10Data0Text:
      'I. The extent to which is allowed by applicable law, Gajigesa will not be responsible for all damage and/or direct, indirect, incidental, special, consequence, or typical losses (examples of damage) that may be caused or suffered by you, including but not limited toLoss of profits, efforts, good intentions, reputation, data or other intangible losses.',
    dataSubTitle10Data1Text:
      'II. Gajigesa also will not be responsible for any damage or loss arising as a result of (a) the use or inability to use services (overall or in part) or any technical damage;(b) fees for procurement of replacement goods or services;(c) forbidden access to, or changes to, communication or user data;(d) statements or actions of any third party in connection with services;(e) your reliability of the completeness or accuracy of any service or any part of it, including but not limited to, every mathematical error or numerical number related to any financial transactions provided in the service;(f) every change that Gajigesa might make for services, or every permanent and temporary termination of services;(g) deletion, corruption, or failure to store any content and other communication data that are maintained or transmitted by or through the use of services by you;(h) your failure to provide accurate account information to Gajigesa;or (i) all other problems regarding services.',
    dataSubTitle10Data2Text:
      'III. In any case, the maximum responsibility of Gajigesa in connection with the provisions of use or use of services by users is limited to the amount of service use costs paid by the user to Gajigesa for the past 12 (twelve) months before the claim or the underlying demands.',
    dataSubTitle10Data3Text:
      'IV. Limitation of Gajigesa"s responsibility to you as stipulated in this section will apply even if Gajigesa has been given suggestions for the possibility of damage or loss.',
    dataSubTitle10Data4Text:
      'V. You agree to give compensation to and free Gajigesa and affiliation, officials, agents, employees and partners from all demands or claims, including reasonable legal costs, from any third party arising from and/or in connection with the use of services by youContrary to the provisions of this use or the provision of inaccurate or incomplete data and information to Gajigesa in connection with the use of services by you.',
    dataSubTitle11FooterText:
      'You agree that: (i) services will be provided from Indonesia;(Ii) Indonesian law applies to the provisions of this use, including every contractual or non-contractual problem or dispute arising from or in connection with the provisions of this use, access and use of services by you, and the relationship between Gajigesa and you;and (iii) any conflict arising from or in connection with the service or conflict between Gajigesa and you in connection with the service will be submitted and resolved first through deliberations for consensus within 30 (thirty) days from the receipt of conflict notification.If after the referral period is not reached an agreement, the conflict resolution will be resolved by the Indonesian National Arbitration Agency (BANI).By not rushing aside the above, you agree that we can ask for a provision decision, interlocutory decision or protective relief before a competent court in any jurisdiction region.',
    dataSubTitle11Data0Text:
      'I. You hereby declare and guarantee to Gajigesa that you have read, understand, and approve all the provisions in the provisions of this use, and you agree to be bound by it and comply with all the provisions regulated in it.',
    dataSubTitle11Data1Text:
      'II. The provisions of this use are regulated and interpreted in accordance with applicable laws in the Republic of Indonesia.Every dispute that arises in connection with the provisions of this use will be resolved through deliberations to reach consensus.If the deliberation does not produce an agreement, the dispute will be referred to the local district court in the jurisdiction of the Republic of Indonesia.',
    dataSubTitle11Data2Text:
      'III. The provisions of this use apply and are binding since you register yourself and start using the Gajigesa service.The terms of this use will continue to apply as long as you use the service and will continue to apply after the end of the service, both by you and by Gajigesa.',
    dataSubTitle12FooterText:
      'You agree to allow us to contact you by email, telephone, post, upload (post) or documents that can be downloaded (or unless you are stated otherwise) and submit advertisements, marketing materials, new information, newspapers, notifications, and other information any other informationTo you through the contact address that you convey to us at the time of registration and/or other contact addresses from time to time.',
    dataSubTitle13Data0Text:
      'I. Whole.The provisions of this use are the overall agreement between you and Gajigesa and replace all understanding, communication and previous approval, both written and oral, related to the subject matter of the provisions of this use.The provisions of this use must be read together and apply jointly with privacy policies, separate use conditions and/or other terms and conditions that apply to the services you choose, including from third parties or partners (if any).',
    dataSubTitle13Data1Text:
      'II. Separation.If the legal decision with legal force still decides that there are parts of this non -applicable use, the section will be eliminated without influencing other parts of this provisions and other provisions will remain valid and can be enforced.',
    dataSubTitle13Data2Text:
      'III. Transfer.You cannot transfer your rights or obligations based on the provisions of this use to anyone (unless it is stated explicitly in the provisions of this use) without previous written approval from Gajigesa.However, Gajigesa can divert their rights and obligations based on the provisions of this use without your approval to (a) affiliateother.The transfer other than according to the provisions of this section is considered invalid.',
    dataSubTitle14Data0Text:
      'I. You can submit questions and complaints related to services to our customer service (Customer Support) at info@gajigesa.com.',
    dataSubTitle14Data1Text:
      'II. Gajigesa will verify user data based on user data stored in the Gajigesa system.We have the right to refuse to process questions and complaints that you ask related to services in terms of your verified data do not match the user data stored in our system.',
  },
  common: {
    noInternetConnection: 'Connection problems, check your internet connection and try refreshing page',
    Language: 'en',
    continue: 'Carry on',
    later: 'Later',
    allow: 'Allow',
    Layinnya: 'All Products',
    oopsError: "Oops, there's an error!",
    oopsErrorDescription: "Relax, we're checking for you. Please close and reopen GajiGesanya.",
    oopsRetryApp: 'Restart App',
    oopsRetryWeb: 'Refresh Halaman',
    cancelled: 'Cancel',
    confirmed: 'Confirmation',
    ok: 'Ok',
    loader: 'Please wait',
    help: 'Help',
    lockPeriod: lockPeriodText,
    suspendedError: 'Tidak bisa bertransaksi, silahkan hubungi HR untuk mengecek status akun!',
    emptyString: '',
    homeTab: 'Home',
    transactionTab: 'Transaction',
    financialTab: 'Financial',
    bpTab: 'Payment',
    profileTab: 'Profile',
    lockPeriodModalText:
      'You are not in the transaction period.Please try using a point account, cash or office money if available.',
    suspendedModalText: "Sorry, but you can't transact yet.Please contact HR to check the account status!",
    storageTitle: 'Almost full storage space',
    storageContent:
      'Your cellphone storage space is almost full.Please delete files or applications that are rare or not used anymore and try again',
  },
  codepush: {
    isUpdating: 'Is updating…',
    dontMissText: "Don't miss the latest application version!",
    updateAppText: 'Update the Gajigesa application to get the latest features and',
    wait: 'Please wait...',
    whileUpdating:
      "Don't close your application when updating, the application will start automatically after the update is complete",
    updateBtnText: 'Update now',
    laterBtnTxt: 'Later',
    update: 'Update',
  },
  referral: {
    invite: 'Invite friends',
    referralHeader: 'Referral Code',
    inputPlaceHolder: 'Enter the code here',
    verifyBtn: 'WEAR',
    validReferral: 'VALID',
    toolTip: 'Enter the referral code or promo code and get Gajigesa Points after finishing transactions',
    referralPageTitle: 'Prize point',
    txtInviteFriends: 'Invite friends to trade, BONUS Points!',
    txtCollectPrize: 'Collect prizes up to',
    txtAchievePrize: 'How to achieve a referral prize',
    txtCollectedText: 'That is already accumulated:',
    txtGGReferralCode: 'Gajigesa referral code',
    txtYou: 'You',
    txtShareCode: 'Share code',
    txtYourFriend: 'Your friend',
    txtUseCode: 'Use the code',
    txtTransaction: 'Transaction',
    txtDoYou: 'Do you',
    txtSuccess: 'who succeeded in inviting coworkers using Gajigesa',
    txtForFriends: 'For your friends',
    txtFirstTx: 'After successfully making the first transaction',
    txtReferralTnCHeader: 'Referral Program Terms & Conditions',
    codeCopiedSuccessfully: 'The code was successfully copied.',
    txtGift: 'Get a gift',
    txtPoinShare: "Dr. Gajigesa's point.Enter this code in the app when the first TRX:",
    txtDownloadApp: 'Download skrg',
    txtTnCPoins: '\n\n*Terms and Conditions Apply',
    referralError: 'There was an error',
    txtPoin: ' Poin',
    copyButtonText: 'Copy Code',
    shareButtonText: 'Share',
    appliedReferral1: 'The referral code was successfully used.',
    appliedReferral2: 'Gajigesa point',
    appliedReferral3: 'will be added after this transaction is successful',
    textCopiedToClipboardPart1:
      'Eh, did you know that GajiGesa, our company partner, is giving away special BONUS? Yep, after withdrawing your salary for the first time using this referral code: ',
    textCopiedToClipboardPart2:
      ' You will immediately get 25,000 GG Points!\n' +
      '\n' +
      'Raise? Come on, download the apk directly here so we can profit together: ',
    validCodeText: 'Hooray!Promo code can already be used',
    textPoinNote:
      '"Now you can withdraw Points to registered banks and use them to top up Ovo, GoPay, and other e-wallets!',
    floatingText1: 'Want BONUS Points up to',
    floatingText2: '2.5 Million?',
    floatingText3: 'Share Code Now!',
  },
  login: {
    welcome: 'Welcome',
    phoneNumber: phoneNumberEng,
    whatsapp: 'Whatsapp',
    sms: 'SMS',
    noAccount: "Don't have account? ",
    signUpNow: 'Signup now',
    help: 'Need our help?',
    agreeToContinue: 'By continuing, you agree',
    tnc: 'Other terms and conditions',
    invalidNumber: 'Invalid mobile number',
    loginHelpText:
      'Withdraw your salary early, pay bills, top-up credit or e-wallet and exchange reward points to save even more',
    tncText: 'By continuing, you agree ',
    tncHyperLink: 'Other Terms & Conditions',
    GG_429_TITLE: 'Please Wait a Moment',
    GG_429_DESCRIPTION: 'We are receiving too many OTP requests to this mobile number. Try again after 1 hour!',
    GG_429_BUTTON_TEXT: 'Ok',
    GG_429_OTP_SCREEN_ERROR: 'Too many verification code requests. \nPlease wait 1 hour to try again',
    GG_500_TITLE: 'Back again later!',
    GG_500_DESCRIPTION: "We're taking care of some problems, don't worry we will come back soon",
    GG_500_BUTTON_TEXT: 'Ok',
    GG_4031_TITLE: 'Wow, your account access is locked',
    GG_4031_DESCRIPTION:
      'At present, your account access has been blocked based on your office HR request.If this is an error, please contact your HR for more information',
    GG_4031_BUTTON_TEXT: 'Ok',
    GG_4032_TITLE: 'Sorry, brother!',
    GG_4032_DESCRIPTION:
      'Currently, access your account is temporarily locked.Contact your HR for more info.We will give news if your account is active again',
    GG_4032_BUTTON_TEXT: 'Ok',
    GG_4033_TITLE: 'Wow…',
    GG_4033_DESCRIPTION:
      "There is something wrong, here.But, it's not wrong.Better contact your HR for more information",
    GG_4033_BUTTON_TEXT: 'Ok',
    GG_404_TITLE: 'Mobile number is not registered',
    GG_404_DESCRIPTION: 'It seems, cellphone number',
    GG_404_DESCRIPTION2:
      'This is not in our database.Re -check the number you enter or click check your data so we can check.',
    GG_404_BUTTON_TEXT: 'Try again',
    GG_404_BUTTON_TEXT2: 'Check your data',
    IN_PROCESS_TITLE: 'Data is being processed',
    IN_PROCESS_DESCRIPTION:
      'This process can take 5 working days.Contact the Gajigesa team if there is no further info',
    IN_PROCESS_BUTTONTEXT: 'Okay, understand!',
    REGISTERED_USER_TITLE: 'Mobile number is registered',
    REGISTERED_USER_DESCRIPTION: 'Please try to enter with the cellphone number',
    REGISTERED_USER_BUTTONTEXT: 'Re-enter',
    RESEND_OTP_TITLE: 'Verification code is sent',
    RESEND_OTP_DESCRIPTION: 'Check your verification code that we sent to the cellphone number',
    RESEND_OTP_DESCRIPTION2: '. You can request the verification code again after 30 seconds',
    RESEND_OTP_BUTTONTEXT: 'Ok',
    LOGOUT_TITLE: 'You are sure you want to get out of the application?',
    LOGOUT_DESCRIPTION:
      "If you get out of your application you can't withdraw salaries early and will miss the promo promo attractive! ",
    LOGOUT_BUTTONTEXT: 'Keep out',
    LOGOUT_BUTTONTEXT2: 'Cancel',
    PHONE_DELETE_TITLE: 'Sure you want to delete?',
    PHONE_DELETE_DESCRIPTION: 'To add a cellphone number, you need to repeat the verification process from the start.',
    PHONE_DELETE_BUTTON_TEXT1: 'Delete number',
    PHONE_DELETE_BUTTON_TEXT2: 'Cancelled',
    PHONE_MAX_LIMIT_TITLE: 'Wow, it has reached the limit, here ...',
    PHONE_MAX_LIMIT_DESCRIPTION:
      'Sorry, there are already 10 cellphone numbers that you registered in Gajigesa.Add more if you have deleted the unused cellphone number.',
    PHONE_MAX_LIMIT_BUTTON_TEXT: 'I understand',
    PHONE_DUPLICATE_ENTRY: 'Oops, something is wrong, here ...',
    PHONE_DUPLICATE_ENTRY_DESCRIPTION:
      'This number is on your list.Please add a number that is not yet on the list, yes!',
    SECURITY_PIN_FALLBACK_TITLE: 'The PIN was successfully made',
    SECURITY_PIN_FALLBACK_DESCRIPTION: 'PIN is active, you can already transactions safer and more comfortable',
    SECURITY_PIN_BUTTON_OK: 'Ok',
    SECURITY_PIN_GG_01_TITLE: 'The PIN was successfully made',
    SECURITY_PIN_GG_01_DESCRIPTION: 'PIN is active, now you can add mobile number and other bank accounts safely!',
    SECURITY_PIN_GG_01_BUTTON1: 'Add No.HP',
    SECURITY_PIN_GG_01_BUTTON2: 'Add bank',
    SECURITY_PIN_ACTIVE_01_TITLE: 'The benefits of having a pin',
    SECURITY_PIN_ACTIVE_01_BUTTON1: 'Add No.HP',
    SECURITY_PIN_ACTIVE_01_BUTTON2: 'Added to the bank',
    SECURITY_PIN_ACTIVE_PHONE_ENROLL_BUTTON1: phoneNumberEn,
    SECURITY_PIN_ACTIVE_BANK_ENROLL_BUTTON: 'Tambah Akun Bank',
    BANK_DELETE_TITLE: 'Sure you want to delete?',
    BANK_DELETE_DESCRIPTION: 'To add a repeat account, you need to repeat the verification process from the start.',
    BANK_DELETE_BUTTONTEXT1: 'Delete number',
    BANK_DELETE_BUTTONTEXT2: 'Cancelled',
    BANK_MAX_LIMIT_TITLE: 'Wow, it has reached the limit, here ...',
    BANK_MAX_LIMIT_DESCRIPTION:
      'Sorry, you have added 10 account numbers in Gajigesa.Add more if you have deleted an unused account number.',
    BANK_MAX_BUTTONTEXT1: 'I understand',
    BANK_DUPLICATE_ENTRY_TITLE: 'Oops, something is wrong, here ...',
    BANK_DUPLICATE_ENTRY_DESCRIPTION:
      'This account number is already on your list.Please change this number or delete then add back',
    BANK_DUPLICATE_BUTTONTEXT1: 'Return',
    LOAN_APPLICATION_TITLE_1: 'Application is Currently in Process',
    LOAN_APPLICATION_DESCRIPTION_1:
      'Please wait for the verification and transfer process, maximum 1 x 24 working hours by the GajiGesa team and your workplace.',
    LOAN_BUTTON_TEXT_1: 'Okay',
    LOAN_APPLICATION_TITLE_2: 'e-Cash Advance Application Approved!',
    LOAN_APPLICATION_DESCRIPTION_2: 'Check the funds entering the registered (main) account in GajiGesa.',
    LOAN_BUTTON_TEXT_2: 'View Details',
    LOAN_APPLICATION_TITLE_3: 'e-Cash Advance Application Approved!',
    LOAN_APPLICATION_DESCRIPTION_3: 'Check the funds entering the registered (main) account in GajiGesa.',
    LOAN_BUTTON_TEXT_3: 'View Details',
    LOAN_APPLICATION_TITLE_4: 'e-Cash Advance Application Denied!',
    LOAN_APPLICATION_DESCRIPTION_4:
      'Sorry, your application has not been approved by the company because it does not meet the terms and conditions, but you can still use EWA. Please try applying at another time.',
    LOAN_BUTTON_TEXT_4: 'Okay',
    LOAN_APPLICATION_TITLE_5: 'e-Cash Advance Installment Completed!',
    LOAN_APPLICATION_DESCRIPTION_5: 'You are eligible to apply for e-Cash Advance again. Interested in applying again?',
    LOAN_BUTTON_TEXT_5_1: 'Later',
    LOAN_BUTTON_TEXT_5_2: 'Apply Again',
    KTP_MODAL_TITLE_1: `Let's Complete Your Data...`,
    KTP_MODAL_DESCRIPTION_1:
      'Before starting to save, please complete your personal data for security during transactions',
    KTP_MODAL_TEXT_1: 'Complete Your Data Now',
    KTP_MODAL_TITLE_2: 'Feature Coming Soon!',
    KTP_MODAL_DESCRIPTION_2: 'Be patient, this feature will be available shortly, and we will inform you right away!',
    KTP_MODAL_TEXT_2: 'Back',
    THREE_RETRY_TITLE: 'PIN is wrong 3 times',
    THREE_RETRY_DESCRIPTION:
      "Because you have entered the PIN that is not the same 3 times, let's repeat for a new pin again",
    THREE_RETRY_BUTTONTEXT1: 'Repeat for a pin',
    THREE_RETRY_VERIFICATION_TITLE: 'PIN is wrong 3 times',
    THREE_RETRY_VERIFICATION_DESCRIPTION:
      'You have been wrong 3 times entering the wrong pin.Your request will still be processed, please contact customer service for the reset pin process',
    THREE_RETRY_VERIFICATION_BUTTONTEXT1: 'Later',
    THREE_RETRY_VERIFICATION_BUTTONTEXT2: 'resetPin',
    BEFORE_CREATE_PIN_TITLE: "Eits, activate the pin first, let's!",
    BEFORE_CREATE_PIN_DESCRIPTION:
      "It's time to make your transactions safer and more comfortable in Gajigesa with PIN activation",
    BEFORE_CREATE_PIN_BUTTONTEXT2: 'Do it now',
    USER_BANK_PREVENT_TITLE: 'Are you sure you want to cancel?',
    USER_BANK_PREVENT_DESCRIPTION:
      'By canceling this process, you need to repeat all verification processes, starting from uploading photos.',
    USER_BANK_PREVENT_BUTTONTEXT1: 'Cancel',
    USER_BANK_PREVENT_BUTTONTEXT2: 'Do not cancel',
    SECURITY_PIN_GG_01_PHONE_ENROLL_DESCRIPTION: 'PIN is active, now you can add another cellphone number safely!',
    SECURITY_PIN_GG_01_BANK_ENROLL_DESCRIPTION: 'PIN is active, now you can add another bank account!',
  },
  otp: {
    enterOTP: 'Enter the Verification Code we sent to',
    headerText: 'Data Check',
    continue: 'Continue',
    resendText: "Didn't receive code? Resend to",
  },
  permissionsModal: {
    contactAccess: 'Contact access',
    contactText1: 'Help you check your coworkers who have not activated Gajigesa',
    contactText2:
      'Make it easy for you to share a referral code for coworkers.There are many Bonus Gajigesa points waiting for you',
    locationAccess: 'Location access',
    locationText1: 'Give you better application performance',
    locationText2: 'Give you easier user experience',
    contactAndLocation: 'Contact access and location',
    allowContactAndLocation: 'Allow access for contact and location',
  },
  signup: {
    headerText: 'Check data',
    selectData: 'Select data',
    signupInformation: 'Fill in the following data to help us check your account',
    nameTxtInput: 'Full name',
    nameTxtInputPlaceholder: 'Enter Full Name',
    placeHolderEmpId: 'enter employee id',
    comapnyName: 'Company name',
    placeHolderCompanyName: 'Enter the name of where you work',
    employeeIdHeader: 'Employee ID',
    employeeIdPlaceholder: 'Enter the employee ID',
    ktpHeader: 'KTP (Identity Card)',
    ktpPlaceholder: 'Enter your KTP number',
    dataTypeForChecking: 'Data type for checking',
  },
  withdrawlSlider: {
    heading: 'Withdrawl Amount',
    selectData: 'Select Account',
    openPeriodText: 'Transactions can be done on',
    payDateWithdrawal: 'and on payday date',
    withdrawButton: 'Withdraw Salary Now',
    withdrawalReason: 'Reasons for Withdrawing Money',
  },
  withdrawal: {
    status: {
      inactive: 'Inactive withdrawal status',
      withdrawalSuspended: 'Withdrawal is delayed',
    },
  },
  profile: {
    hi: 'Hi',
    lihat: 'Look',
    bonusHeader: 'Bonuses and prizes',
    information: 'Information',
    securityHeader: 'Account security',
    referralHeader: 'Financial page',
    defaultBankHeader: 'Registered account (Main)',
    addBankString: 'Add another account',
    registeredBanks: 'See registered bank account',
    myPoints: 'Poin Saya',
    zonaRekanHR: 'HR Peer Zone',
    phoneEnroll: 'List of HP Numbers',
    salaryInfo: 'Salary Information',
    picZone: 'PIC Zone',
    pinsText: 'Security PINs',
    manageSecurityPin: 'Manage PINs',
    securityPinActive: 'Active',
    securityPinRejected: 'Do it now!',
    bantuanHeader: 'Help',
    customerService: 'Customer service',
    bankList: 'List of banks',
    logout: 'Quit Application',
    primaryBankHeader: 'Registered account (main)',
    bankListInfo: 'The main account is a bank account number registered by your workplace in Gajigesa.',
    bankOtherList: 'Other registered accounts',
    bankMaxLimit: '(Max. 10 other accounts)',
    appVersion: 'App version',
    otaVersion: 'OTA Version',
  },
  bp: {
    phoneTransfer: 'Change',
    customerId: customerIdEng,
    customerIdInput: 'Enter the Customer ID',
    selectIdHeader: 'Select Customer ID',
    selectedId: customerIdEng,
    selectRegionHeader: 'Region',
    selectRegion: 'Choose region',
    subProductNominals: 'Choose the product:',
    price: 'Price',
    voucherTnC: 'Exchange before 7 days from the date of purchase of the voucher',
    voucherHeader1: 'What you get',
    voucherHeader2: termsandConditionEng,
    voucherHeader3: 'How to Redeem Vouchers',
    provider: 'Provider',
    bpDetails: 'Payment details',
    regionSelect: 'Look for the area',
    selectOrInputAccountNumber: 'Please fill in your customer ID',
    unavailableProduct: 'Gajigesa balance is not enough to conduct this transaction.Try to check your other account',
    unavailableNominalHeader: 'Products are not found',
    unavailableNominalDescription: 'Please check again the number or customer ID',
    pascabayarDetails: 'Details number',
    pdamAccountModalHeaderTitle: 'Select Customer ID',
    new: 'New',
    registeredID: 'Registered ID',
  },
  reqTxSheet: {
    chosenProduct: 'Choose the product',
    phoneNumber: 'Phone number',
    provider: 'Provider',
    requestedAmount: 'Nominal pulse',
    customerId: 'The customer ID',
    customerName: "Customer's name",
    tokenPLNNominal: 'Nominal Token',
    voucherType: 'Voucher type',
    voucherNominal: 'Nominal Voucher',
    emoneyProvider: 'Tipe E-Money',
    emoneyNominal: 'Nominal Top Up',
    paketDataName: 'Type of data package',
    monthYear: 'Month/Year',
    regionName: 'Regional name',
    subTotal: 'Total payment',
    total: 'Total',
    totalPostpaid: 'Admin fee',
    subTotalPostpaid: 'PLN Bill',
    pdamBill: 'PDAM bill',
    tncHeader: 'Service provisions',
    bpTnc:
      'If there is, I am willing to pay the fees that appear on the use of Gajigesa services.Approved the salary that I will receive from the company on the payday date will be deducted by a number of transactions that I made through the Gajigesa service.',
    bpDetails: 'Cut from :',
    cancelTnC: 'Cancelled',
    acceptTnC: 'Agree',
    checkBill: 'Check the bill',
    confirmTnC: 'I confirm the transfer details.',
    goldCreatedAt: 'Transaction Time',
    goldPrice: 'Gold Price',
    goldUnit: 'Gold Weight',
    tagihanPascabayar: 'Postpaid bill',
  },
  successPage: {
    txInProcess: 'Transaction in the process',
    purchased: 'Payment',
    txTimeTaken: 'The process can take up to 5 minutes',
  },
  transactionHistory: {
    kodeLink: 'Code/link Available',
    belum: 'No Transactions Yet',
    belumDes: 'Start transacting now and track your transaction \nhistory here',
  },
  transactionSummary: {
    totalIncome: 'Total income',
    totalExpenses: 'Total expenditure',
    total: {
      0: 'Your Money is the Total Income - Total Expenses',
      1: 'Total GajiGesa Points received from the reward scheme',
      3: 'Accounts received through GajiGesa sponsorship',
      4: 'Accounts used as office operational funds',
      5: 'Total GajiGesa Points exchanged for cash',
    },
    income: {
      0: 'Total Income is Base Salary + Allowances, Bonuses, and Commissions',
      1: 'Total Income refers to the reward you receive',
      3: txnIncomeTextEn,
      4: txnIncomeTextEn,
      5: 'Total Income refers to the Point balance you have exchanged.',
    },
  },
  transStatus: {
    wait: 'Waiting',
    success: 'Success',
    failed: 'Failed',
    rejected: 'Rejected',
  },
  accountSelectorText: {
    basedOn: 'Based on',
  },
  reasonsModal: {
    previous: 'Previously',
    next: 'Furthermore',
    reasonForWithdrawal: 'Reasons for withdrawal',
  },
  withdrawalPage: {
    header: 'Money withdrawal',
    detailsHeader: transaksiDetails,
    amountOfWithdrawal: 'Number of withdrawals',
    adminFee: 'Admin fee',
    withdrawalTotal: 'The amount received',
    destinationAccount: 'Destination account',
  },
  withdrawalSuccess: {
    withdrawText: 'Salary withdrawal of',
    pendingText: 'Transaction in the process',
    successText: 'Successful transactions',
  },
  editTransactions: {
    status: 'Status',
    transDate: 'Date',
    transaksi: 'Transaction',
    nominalPulsa: 'Credit Amount',
    phoneNumber: phoneNumberEng,
    totalPembayaran: 'Total Payment',
    kantongDana: 'Funds Wallet',
    provider: 'Provider',
    customerId: customerIdEng,
    customerName: 'Customer Name',
    nominalToken: 'Token Amount',
    nominalVoucher: 'Voucher Amount',
    emoneyPhoneNumber: 'Customer Number',
    emoneyNominal: 'Top-up Amount',
    emoneyProductName: 'E-Money Type',
    packetProductName: 'Data Package Type',
    bulanTahun: 'Month/ Year',
    tagihanPascabayar: 'Postpaid Bill',
    adminFee: 'Admin Fee',
    nominalPLN: 'PLN Bill Amount',
    pdamNama: 'PDAM Name',
    pdamCustomerName: 'PDAM Bill',
    hargaBelis: 'Gold Purchase Price',
    jumlahBerat: 'Gold Weight Amount',
    noOfReferensi: 'No. Reference',
    jumlahPenarikan: 'Withdrawal Amount',
    jumlahPenyesusian: 'Adjustment Amount',
    bankName: 'Receiving Bank',
    transReceivedAmount: 'Amount Received',
    beneficiaryName: 'Account Holder',
    bankAccountNumber: 'Receiving Account Number',
    failedToUpload: '(Gagal Upload)',
    loanBankName: 'Destination Bank',
    loanBankAccountNo: 'Account Number',
  },
  transactionDetailsScreen: {
    detailTransaksi: transaksiDetails,
    keterangan: 'Description',
    uangdescTitle: 'Transaction Description',
    masukkanKeterangan: 'Enter Description',
    Instructions: 'Do not close this page until your transaction proof has been uploaded and sent.',
    InstructionTwo: 'Please only upload 1 (one) photo file containing a combination of multiple proofs.',
    kodeVoucherTersalin: 'Voucher Code Copied!',
    uploadBuktiPembayaran: 'Upload Payment Proof (JPG, PNG)',
    uploadBuktiPembayaranShort: 'Upload Payment Proof',
    limitDescription: 'You can only upload 1 transaction proof, so combine the transaction proofs into one file',
    dariGaleri: 'From Gallery',
    simpan: 'Save',
  },
  autoDisburse: {
    navTitle: termsandConditionEng,
    lockPeriod: lockPeriodText,
    suspended: 'Unable to transact, please contact HR to check your account status!',
    optIn: {
      first: 'Your transaction closing period will start on',
      linkButton: 'Learn more',
    },
    optOut: {
      first: 'The withdrawal of your remaining salary will be transferred to your primary bank on',
      linkButton: 'Cancel?',
    },
    successOptIn: 'Request to withdraw remaining salary successful!',
    terms: [
      'The remaining balance in your account will be automatically sent to your primary bank account every last day of the transaction period at 5 PM',
      'The automatic balance withdrawal process will incur an admin fee',
      'The balance will be deducted directly from your salary account (the same deduction process applies to other transactions)',
      'To cancel automatic balance withdrawal, please contact the GajiGesa operations team immediately',
      'Cancellation can only be done a maximum of 2 days before the salary closing cycle begins',
    ],
    mainTitle: 'Withdraw Your Salary Before the Transaction Closing Period Ends!',
    descOne:
      'If you agree to the Terms and Conditions below, the remaining balance in your Salary Account will be sent to your registered primary bank account in GajiGesa.',
    descTwo: 'The entire balance will be sent on the last day of the transaction period, IF your company approves.',
    bulletOne: termsandConditionEng,
    termsTitle: 'I agree to the applicable terms and conditions',
    continue: 'Continue',
  },

  phoneTransfer: {
    phoneTitle: 'Stored cellphone number',
    noRegisteredText: 'There is no registered cellphone number',
    phoneAddPurpose:
      'Other mobile numbers are still empty, use this feature for top-up pulses, data packages, or e-wallet balances for the people closest to you.',
    phoneAddBtnText: 'Add cellphone number',
    phoneTransferSupport: 'Verification now',
    phoneVerify: 'In verification',
    phoneRejected: 'Rejected',
    userPhone: 'Your cellphone number (main)',
    phoneMaxLimit: '(Max. 10 Other numbers)',
    phoneTransferWarning: 'The cellphone number that you registered needs to be validated first before it can be used.',
  },
  addPhone: {
    phonePlaceHolderText: 'Example: Father, Mother, Joko, Siti',
    addPhoneText: 'Enter the cellphone number',
    addPhoneNameText: 'Enter name',
    addPhoneInfo1:
      'Make it easy for credit buying transactions, data packages, and e-wallet top-ups for others by registering their numbers.',
    addPhoneInfo2: 'This number will be verified manually by the Gajigesa Team',
    addPhoneButtonText: 'Save the number',
    numberHeader: 'Other cellphone number',
  },
  userProfile: {
    noteText: 'Exclusive promotion awaits you as an HR Partner!',
    unTukText: 'For data changes, please contact your HR',
    selectableText: 'Save and Continue',
    departmentEmpty: 'Choose Your Department',
    desginationEmpty: 'Choose Your Position',
    lihatGateway: 'View HR Partner Benefits',
    tag: 'HR Partner',
    textOne: 'Personal Data',
    textTwo: 'Name',
    textThree: phoneNumberEng,
    textFour: 'Company Data',
    textFive: 'Company Data',
    textSix: 'Department',
    textSeven: 'Position',
    headerBack: 'Personal Information',
    textEight: 'Hi',
    textNine: phoneNumberInd,
    toastSuccess: 'Departments and Positions Saved!',
    SUB_TERMS: [
      'You have been selected as a Gajigesa HR Partner on August 1, 2023.',
      "You invite Adi, your colleague, to transact on the GajiGesa app by sending a WhatsApp invitation through the WhatsApp icon on Adi's contact in the HR Partner Zone on August 2, 2023.",
      'Adi then logs into the GajiGesa app on August 3, 2023.',
      'As a result, you will receive an incentive in the form of GajiGesa points amounting to 20,000.',
    ],
    PIC_TERMS_TEXT: [
      'You are an HR Partner selected to receive an incentive in the form of GajiGesa points of 20,000 for every employee/colleague in your company who logs in to the GajiGesa app after you invite them via WhatsApp. For example:',
      'You can also get an incentive of 25,000 GajiGesa points if your colleague uses your referral code for their first transaction on GajiGesa. Learn more, [Read the terms and conditions here](https://gajigesa.com/gajigesa-referral)',
      'This program is only valid for fellow employees working in the same office/company.',
      'This program is only valid for transactions using the Gajigesa app.',
      'Read the terms and conditions here',
      'https://gajigesa.com/gajigesa-referral',
      'https://gajigesa.com/rekan-hr',
    ],
    termsText: 'Check Terms & Conditions',
    linkTerms: 'Here!',
    textInputPlaceHolder: `Search for Your Friend Name`,
    titleOne: 'Friend List',
    titleTwo: 'Invitation Status',
    listingItemOne: 'First Login',
    listingItemTwo: 'Has Made Transactions',
    whatsappContentOne:
      'Join the office benefits now! As an office employee, you can withdraw your salary before the payday with GajiGesa. Open the GajiGesa app now and get FREE 45,000 Points for your first transaction.',
    whatsappContentTwo: '➡️ Download the GajiGesa app at bit.ly/gajigesa-android or visit app.gajigesa.com.',
    departmentSearch: 'Search for Job Title',
    jabatan: 'Job Title',
    poinEarned: 'Accumulated Points',
    tooltipBanner: 'The total points you have successfully collected during the influencer promo period',
    picLandingTitle: 'HR Partner Zone',
    deptDesginTitle: 'Choose Department',
    designTitle: 'Select Position',
    termsAndCondition: termsandConditionEng,
  },
  addBank: {
    bankTitle: 'Add Bank Account',
    bankTitlePlaceholder: 'Choose a bank',
    bankNameTitle: 'Bank name',
    bankNameTitlePlaceholder: 'Example: 3216549870',
    bankAccontNumTitle: 'Account number',
    bankAccountName: 'The name of the account owner',
    bankAccountNamePlaceholder: 'Example: Mardiasih',
    bankAccountNickName: 'Account owner label (optional)',
    bankAccountNickNamePlaceholder: "Example: Father's account, bill record",
    bankAccountAdd: 'Save account data',
    bankAccountAddInfo:
      'Your account data will be diverted manually by the Gajigesa team. Accelerate the verification process by contacting the Gajigesa Support Team, or waiting for max.3 x 24 hours.',
    findBank: 'Find a bank name',
    bankList: 'Registered bank account',
    bankHeaderFromTransfer: 'Select an account',
    addPending: 'The verification process began to be processed after you click Verification now',
    addApproved: 'New account saved',
    accountNameError: 'The name of the account owner must not contain special characters and numbers.',
    selectBank: 'Please select bank',
    numValidation: 'Amount exceeds number validation',
    requiredTransfer: 'Amount is required for transfer',
    numericString: 'Account number must be a numeric string',
    reqName: 'Account name is required',
  },
  bankTransfer: {
    sourceOfFunds: 'Source of funds',
    transferOfDay: 'The transfer of the day',
    accountName: 'Account name',
    transferInfo: 'The source of funds for this transfer comes from the Gajigesa salary account.',
    amountForTransfer: 'Nominal delivery',
    successText: 'Funds delivery of',
    notEnoughBalance: 'Not enough balance',
  },
  bankEmptyPage: {
    emptyBanksTitle: 'Other account numbers are still empty',
    useBankAdd: 'Use this feature to transfer funds to other registered accounts.',
    addBank: 'Tambah Rekening',
  },
  bankList: {
    accountList: 'Account list',
    accountListForTransfer: 'Account is stored',
    addBankAccount: 'Add your account',
    hanya: 'Only verified bank account numbers can be used."',
  },
  securityPin: {
    pinScreenTitle: 'Security Pin',
    bannerTitle: 'Activation of PIN for your account security',
    setPinText: 'Do it now!',
    reEnterPin: 'Re -enter the pin',
    makeNewPin: 'Create a new pin',
    enterPin: 'Enter your PIN',
    securityInfo: 'Avoid using a pin with a series of numbers that are easy to guess.',
    bankInfo: 'You can withdraw and send your salary to your other bank accounts',
    phoneInfo: 'You can buy credit, data packages and other transactions to your other cellphone numbers',
    errorPinNotSame: 'PIN NOT SAME',
    errorPinFormat: 'Format Pin Format, please repeat and check the information below',
    resetLinkText: 'Any obstacles? Contact customer service',
    retry1: 'The pin entered is wrong, stay ',
    retry2: 'times again before the pin is blocked',
  },
  treasury: {
    bannerTitle: 'Complete your ID Card and Gold Transactions, Get a Bonus of 35,000 Points!',
    bannerButton: 'Claim Reward',
    goldTerms: [
      'You cannot merge personal Treasury accounts with Treasury accounts integrated on the GajiGesa platform.',
      'You are currently unable to convert your digital gold into physical gold. For now, you can only buy and sell gold in digital form.',
      'If your GajiGesa account is no longer accessible or inactive, contact the GajiGesa Customer Support team for access claims and/or the digital gold balance of your Treasury account.',
    ],
    ktpFieldError: 'Invalid ID Card Number',
    ktpTitle: 'Please fill in your information',
    ktpTitleOne: 'Name as per ID Card',
    ktpPlaceholder: 'Enter your full name',
    ktpTitleTwo: 'ID Card Number / Citizen Identification Number',
    ktpPlaceHOlderTwo: 'Enter your ID Card Number',
    ktpTItleThree: 'Promo Code (Not Mandatory)',
    ktpPlaceHolder: 'Enter referral code or promo code here',
    infoTextBucket: [
      'To use the gold savings feature, please complete your personal data with a valid ID Card number.',
      'Your data privacy is crucial to us. Maintaining its confidentiality is a top priority at GajiGesa. Thank you for your cooperation!',
    ],
    ktpLanjuktan: 'Save and Continue',
    benifitsDisplayOne: 'Low Capital',
    benifitsDisplayTwo: 'Easy to Cash Out',
    benifitsDisplayThree: 'Wealth Value Protection',
    termsBandOne: 'Check the Terms and Conditions for Gold Savings',
    termsBandTwo: 'Here!',
    hadia: 'A Gift for You!',
    hadiatwo: '*Bonus will be given within a maximum of 3 days after the first transaction',
    sponsorOne: 'Supported by',
    sponsortwo: 'Licensed & supervised by',
    ktpHeaderTitle: 'Registration of Personal Data',
    webViewTitle: 'Gold Savings - GajiGesa',
    termsAndConditions: termsandConditionEng,
    mainButtontitle: 'My Gold',
    kodePromo: 'Promo Code',
  },
  loan: {
    LOAN_TERMS: [
      'I agree that the approval of the Kasbon service is the full authority of the company where I work.',
      'I am willing to pay the service fees and installments of Kasbon that arise from using this service.',
      'I agree that the service fees will be deducted directly from the amount of funds I apply for, and I will receive the funds in my account minus the incurred service fees.',
      'I agree that the salary I will receive from the company on each payday will be deducted by the total transaction amount I make through this service.',
      'I will be responsible for settling this Kasbon service obligation (if any), regardless of my employment status with the company.',
    ],
    agree: 'I Agree',
    INFO_STATUS_DESC_1:
      'e-Kasbon approved! Funds have been successfully transferred to the registered (main) account in GajiGesa',
    INFO_STATUS_DESC_2:
      'Your application is currently in the verification process. We will inform you once it is approved',
    INFO_STATUS_DESC_3: 'If approved, the funds will be transferred to the registered (main) bank account in GajiGesa',
    loanHeaderText:
      'Apply for fast funds according to your needs with e-Kasbon. Pay in installments up to 3 times, settle directly!',
    toastErrorLoan: 'Sorry, you cannot make transactions at the moment. Contact your HR if you want to transact again.',
    loanTermsText: 'I agree to the applicable Terms & Conditions.',
    harapa: 'Please Note!',
    baca: 'Read the following terms before proceeding with the loan application process',
    riwayat: 'e-Kasbon History',
    pilih: 'Choose the amount of funds you need',
    menunggu: 'Waiting for Approval',
    jumlah: 'Amount of received funds',
    cicilan: 'Installment',
    ciciBulan: 'Installment per month',
    pelajari: 'Learn How to Apply Here',
    loanText1: 'Installment Details',
    loanText2: 'Deductions will be made for e-Kasbon installments every payroll cycle, according to the tenor',
    loanText3: 'Installment Number',
    loanText4: 'Date and Status',
    loanItemText1: 'Amount of received funds',
    loanItemText2: 'Tenor',
    loanItemText3: 'Installment per month',
    loanMetaText1: 'Service fees will be directly deducted from the approved amount',
    loanMetaText2: 'Service fees',
    loanMetaText3: 'Details',
    loanMetaInfo1: 'Choose the amount of funds you need',
    loanMetaInfo2: 'Wait for the verification process by your workplace',
    loanMetaInfo3: 'After approval, check the funds in the registered (primary) account in GajiGesa',
    loanMetaInfo4: 'On payday, there will be a deduction for e-Kasbon installment according to the tenor',
    loanMetaInfo5: 'Apply for another e-Kasbon if your installment is completed',
    loanMetaInfo6: 'Read the terms and conditions in full here:',
    transaksiDetail: transaksiDetails,
    transaksiDetail2: 'Service Fee',
    transaksiDetail3: 'Destination Bank',
    transaksiDetail4: 'Account Number',
    transaksiDetail5: 'Account Owner',
    termsAndCondition: termsandConditionEng,
    loanButton: 'Continue Process',
  },
  salaryInfo: {
    textOne: 'Remaining Balance in Salary Account Available',
    textTwo: 'Check Salary Account Limit for This Period',
    textThree: `Remaining Balance in Salary Account is\nAccumulated Total Salary - Total Expenditure`,
    textFour: 'Total Accumulative Salary',
    textFive: 'Total Expenditure',
    textSix: 'Transaction Status',
    textSeven: 'Active Until',
    textEight: 'Currently Inactive',
    textNine: lockPeriodText,
    textTen: 'Your transaction period is',
    textEleven: 'GajiGesa Education',
    FAQ_TITLE_ONE: 'What is a Salary Account?',
    FAQ_DESC_1:
      'Salary Account is the fund that you can access per salary cycle. The balance increases daily, adjusting to the number of working days.',
    FAQ_DESC_2:
      'This balance can be used for direct salary withdrawal, bill payments, mobile credit top-up, and/or e-money balance top-up. The amount adjusts based on your total expenditures.',
    FAQ_DESC_3:
      'The Salary Account amount is reset when the transaction period becomes active again. The remaining balance will be transferred to your account along with your salary.',
    FAQ_TITLE_TWO: 'What is the Transaction Period?',
    FAQ_DESC_4:
      'During the active transaction period, you can access your salary. Thus, you can make salary withdrawals or bill payments using the GajiGesa Balance.',
    FAQ_DESC_5: 'During your inactive transaction period, you can only make transactions with GajiGesa Points.',
    FAQ_DESC_6:
      'This transaction period usually aligns with your payday. Contact your HR for more information about the transaction period.',
    tooltipTitle: 'Salary Account Limit',
    tooltipDesc:
      'This amount represents the total maximum balance that can be accessed per transaction period/salary cycle, in accordance with your workplace regulations.',
    harja: 'Total Working Days',
    xGaji: 'x Salary Per Day',
  },
  sdModal: {
    waitAMinute: 'Wait a minute',
    chooseSD:
      'In this period, you can only disburse the remaining salary this month.Please choose salary disbursement.',
    sdBtn: 'Okay, I understand',
  },
  appUpdate: {
    textOne: 'Hey, Update Your GajiGesa App!',
    textTwo: 'Enjoy better app performance by updating to the latest version of GajiGesa on the Play Store.',
    textThree: 'Update Now',
    textFour: 'Update to the Latest GajiGesa Version!',
    textFive: 'Please update the application to enjoy improved services.',
    textSix: 'UPDATE',
  },
  new: 'new',
};

export default en;
