import useFeatureFlag from '@customHooks/useFeatureFlag';
import BottomSheet from '@molecules/LoginInfoSheet';
import { useNavigation } from '@react-navigation/native';
import { FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { MODAL_KEYS, getModalKeyBasedOnFeatureFlags } from '@screens/GGCreatePin/pinUtils';
import colors from '@theme/colors';
import { returnBottomSheetContent } from '@utils/utils';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback } from 'react-native';
import profilePinActiveBottomSheetHandler from './profilePinRowUtils';

function ProfileActivePinRow(props) {
  const { childRef } = props;
  const navigation = useNavigation();
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.BANK_SEL_TRNSF, FEATURE_FLAGS.PHONE_ENROLL],
  });
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      SECURITY_PIN_INFO_MODAL[MODAL_KEYS.GG_01_FALLBACK],
      SECURITY_PIN_INFO_MODAL[MODAL_KEYS.GG_01_FALLBACK],
    ),
    errorCode: MODAL_KEYS.GG_01_FALLBACK,
  });
  const { t } = useTranslation('common');

  const getModalNameKey = getModalKeyBasedOnFeatureFlags(isFeatureAvailale, MODAL_KEYS)();

  const { onPrimaryPress, onPressSecondaryHandler, onCloseModal } = profilePinActiveBottomSheetHandler(
    setInfoModal,
    infoModal,
    navigation,
  );

  return (
    <TouchableWithoutFeedback
      ref={childRef}
      testID="btnPressSecurityPin"
      onPress={() => {
        setInfoModal({
          visible: true,
          data: returnBottomSheetContent(
            SECURITY_PIN_INFO_MODAL[getModalNameKey],
            SECURITY_PIN_INFO_MODAL[getModalNameKey],
          ),
          errorCode: getModalNameKey,
        });
      }}>
      <View>
        <View backgroundColor={colors.success.softCactus} borderRadius="12px" marginRight="8px">
          <Text
            testID="txtSecurityPinActive"
            marginX="8px"
            marginY="5px"
            variant="xsm-normal"
            color={colors.success.darkCactus}>
            {t('profile.securityPinActive')}
          </Text>
        </View>
        <BottomSheet
          onPressPrimary={onPrimaryPress}
          onPressSecondary={onPressSecondaryHandler}
          loginInfo={infoModal}
          onClose={onCloseModal}
        />
      </View>
    </TouchableWithoutFeedback>
  );
}

ProfileActivePinRow.propTypes = {
  childRef: PropTypes.oneOfType([PropTypes.object]),
};
export default ProfileActivePinRow;
