import { goldRequest, verifyKTP } from '@api/useQueryCalls';
import { ktpGoldBanner } from '@assets/png/imagesPng';
import InfoTextComponent from '@atoms/InfoTextComponent';
import TextInputComponent from '@atoms/TextInputComponent';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { formatUnderScoreLowerCase } from '@utils/utils';
import { Button, Text, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Image, ScrollView, KeyboardAvoidingView } from 'react-native';
import styles from './style';

export function validateKTP(ktpNum) {
  const pattern =
    /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
  return pattern.test(ktpNum);
}
export function onSuccessGoldRequest(navigation) {
  return data => {
    const webUrl = data?.webView;
    if (webUrl) navigation.replace('GoldWebView', { webUrl, entrySource: 'ktp' });
  };
}

export function verifyKTPSuccessHandler(goldRequestCall, promoInput) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.Treasury.ktp_number_submitted;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'gold',
      [e.attributes.promo_code]: promoInput,
    });
    goldRequestCall();
  };
}

export function onChangeKTPNumberHandler(isError, setError, setKTPNum) {
  return tx => {
    if (isError) setError(false);
    setKTPNum(tx);
  };
}

export function onChangePromocodeInputHandler(setPromoInput) {
  return text => {
    setPromoInput(text);
  };
}

export function onKTPSuccessHandler(
  setError,
  ktpNum,
  promoInput,
  setPromocode,
  setIsValidPromocode,
  verifyKTPCall,
  validateKTPHandler,
  fieldErrorMessage,
) {
  return async () => {
    setError(false);
    Keyboard.dismiss();
    if (validateKTPHandler(ktpNum)) {
      if (promoInput.length > 0) {
        setPromocode(promoInput);
        setIsValidPromocode(true);
      }
      verifyKTPCall();
    } else {
      setError(true);
      const e2 = AMPLITUDE_CONSTANTS.Treasury.ktp_number_failed;
      AmplitudeHelper.logEvent(e2.name, {
        [e2.attributes.source]: 'gold',
        [e2.attributes.failure_message]: formatUnderScoreLowerCase(fieldErrorMessage),
      });
    }
  };
}

const logEvent = (e, ktpName, ktpNum) => {
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'gold',
    [e.attributes.name]: ktpName ?? null,
    [e.attributes.ktp_number]: ktpNum ?? null,
  });
};

const GoldKTP = () => {
  const [ktpName, setKTPName] = useState('');
  const [ktpNum, setKTPNum] = useState('');
  const [isError, setError] = useState(false);

  const { setPromocode, setIsValidPromocode, employeeId, setInitialData } = getStore();

  const navigation = useNavigation();
  const [promoInput, setPromoInput] = useState('');

  const {
    isLoading: isGoldRequestLoading,
    mutate: goldRequestCall,
    isError: isGoldReqError,
    error: goldReqError,
  } = goldRequest({ employeeId }, onSuccessGoldRequest(navigation));

  const {
    isLoading: isVerifyLoading,
    mutate: verifyKTPCall,
    isError: ktpError,
    error: ktpErrorLoad,
  } = verifyKTP(
    { ktpName, ktpNumber: ktpNum, ktpRegistPromoCode: promoInput ?? undefined },
    verifyKTPSuccessHandler(goldRequestCall, promoInput),
  );

  const toast = useToast();
  const { t } = useTranslation('common');

  useEffect(() => {
    shouldShowError(isGoldReqError, goldReqError, toast, t, navigation, setInitialData);
  }, [isError, goldReqError]);

  useEffect(() => {
    shouldShowError(ktpError, ktpErrorLoad, toast, t, navigation, setInitialData);
  }, [ktpError, ktpErrorLoad]);

  const fieldErrorMessage = t('treasury.ktpFieldError');

  return (
    <KeyboardAvoidingView style={styles.mainContainer} behavior="height" keyboardVerticalOffset={30}>
      <ScrollView style={styles.containerStyle} contentContainerStyle={styles.contentcontainerStyle}>
        <Image source={ktpGoldBanner} style={styles.imageStyle} contentFit="contain" />
        <Text variant="lg-bold" style={styles.titleTextStyle}>
          {t('treasury.ktpTitle')}
        </Text>
        <TextInputComponent
          title={t('treasury.ktpTitleOne')}
          textInputProps={{
            placeholder: t('treasury.ktpPlaceholder'),
            numberOfLines: 1,
            onChangeText: setKTPName,
            value: ktpName,
            placeholderTextColor: colors.neutral.darkMist,
            testID: 'ktpTitleOne',
            onFocus: () => logEvent(AMPLITUDE_CONSTANTS.Treasury.name_clicked),
            onBlur: () => logEvent(AMPLITUDE_CONSTANTS.Treasury.name_inputted, ktpName, null),
          }}
        />
        <TextInputComponent
          title={t('treasury.ktpTitleTwo')}
          isError={isError}
          errorMessage={fieldErrorMessage}
          textInputProps={{
            placeholder: t('treasury.ktpPlaceHOlderTwo'),
            numberOfLines: 1,
            onChangeText: onChangeKTPNumberHandler(isError, setError, setKTPNum),
            value: ktpNum,
            maxLength: 16,
            placeholderTextColor: colors.neutral.darkMist,
            keyboardType: 'number-pad',
            onFocus: () => logEvent(AMPLITUDE_CONSTANTS.Treasury.ktp_number_clicked),
            onBlur: () => logEvent(AMPLITUDE_CONSTANTS.Treasury.ktp_number_inputted, null, ktpNum),
          }}
        />
        <TextInputComponent
          title={t('treasury.ktpTItleThree')}
          textInputStyle={{ fontSize: scale(12) }}
          textInputProps={{
            placeholder: t('treasury.ktpPlaceHolder'),
            numberOfLines: 1,
            onChangeText: onChangePromocodeInputHandler(setPromoInput),
            value: promoInput,
            maxLength: 16,
            placeholderTextColor: colors.neutral.darkMist,
            onFocus: () => {
              const e = AMPLITUDE_CONSTANTS.Treasury.promo_code_clicked;
              AmplitudeHelper.logEvent(e.name, {
                [e.attributes.source]: 'financial_page',
              });
            },
            onBlur: () => {
              const e = AMPLITUDE_CONSTANTS.Treasury.promo_code_inputted;
              AmplitudeHelper.logEvent(e.name, {
                [e.attributes.source]: 'financial_page',
                [e.attributes.promo_code]: promoInput,
              });
            },
          }}
        />
        <InfoTextComponent
          show
          iconColor={colors.neutral.charcoal}
          textBucket={[t('treasury.infoTextBucket.0'), t('treasury.infoTextBucket.1')]}
          containerStyle={styles.infoTextContainer}
          variant="xsm-bold"
          customTextStyle={styles.textStyle}
          size={scale(24)}
        />

        <Button
          isLoading={isGoldRequestLoading || isVerifyLoading}
          isDisabled={isGoldRequestLoading || isVerifyLoading || ktpNum.length < 16 || ktpName.length === 0}
          textAlign="center"
          testID="ktpCheckout"
          onPress={onKTPSuccessHandler(
            setError,
            ktpNum,
            promoInput,
            setPromocode,
            setIsValidPromocode,
            verifyKTPCall,
            validateKTP,
            fieldErrorMessage,
          )}>
          <Text variant="md-bold" color={colors.neutral.cotton}>
            {t('treasury.ktpLanjuktan')}
          </Text>
        </Button>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default GoldKTP;
