import colors from '@theme/colors';

interface SelectedReason {
  selected: boolean;
}

export function getSelectedReasonBackgroundColor(selectedReason: SelectedReason): string {
  return selectedReason.selected ? colors.primary.carnation : colors.neutral.cotton;
}

export function getSelectedReasonBorderColor(selectedReason: SelectedReason): string {
  return selectedReason.selected ? colors.primary.carnation : colors.disabled.smoke;
}
