import { getStore } from '@store/storeUtils';
import { verticalScale } from '@utils/normalize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { logDropDownPressed } from './analyticsBPAccountSelector';
import { dropDownOnChange, dropDownRenderItem, dropDownRenderLeftIcon } from './bpReqAccSelectorUtils';
import styles from './styles';

function BPRequestAccountSelector(props) {
  const { selectedBPAccount, employeeId, validatedReferralCode } = getStore();
  const { paymentMethods, setSelectedBPAccount, bpReqData, source, productConfig } = props;
  const [eligibleAccounts, setEligibleAccounts] = useState(paymentMethods);
  const [selectedEligibleAccount, setSelectedEligibleAccount] = useState(eligibleAccounts[0]);
  useEffect(() => {
    const enabledAccounts = paymentMethods;
    setEligibleAccounts(enabledAccounts);
    setSelectedEligibleAccount(enabledAccounts.filter(item => item.id === selectedBPAccount.id)[0]);
  }, [paymentMethods]);

  return (
    <Dropdown
      testID="dropDownAccounts"
      data={eligibleAccounts}
      value={selectedEligibleAccount}
      maxHeight={Platform.OS === 'web' ? null : verticalScale(150)}
      onFocus={() => {
        logDropDownPressed(source, employeeId, productConfig, bpReqData, validatedReferralCode);
      }}
      onChange={dropDownOnChange(
        setSelectedEligibleAccount,
        setSelectedBPAccount,
        employeeId,
        productConfig?.transaction_category_id,
        bpReqData?.productCode,
        source,
        bpReqData,
        validatedReferralCode,
      )}
      labelField="label"
      valueField="value"
      containerStyle={styles.dropDownContainerStyles}
      style={styles.dropDownStyles}
      renderLeftIcon={dropDownRenderLeftIcon(selectedEligibleAccount)}
      renderItem={dropDownRenderItem(selectedEligibleAccount)}
    />
  );
}

BPRequestAccountSelector.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  setSelectedBPAccount: PropTypes.func,
  bpReqData: PropTypes.shape({
    productCode: PropTypes.string,
  }),
  source: PropTypes.string,
  productConfig: PropTypes.shape({
    transaction_category_id: PropTypes.string,
  }),
};
BPRequestAccountSelector.defaultProps = {
  paymentMethods: [{ name: '' }],
  setSelectedBPAccount: () => {},
  bpReqData: {
    productCode: '',
  },
  source: '',
  productConfig: {
    transaction_category_id: '',
  },
};
export default BPRequestAccountSelector;
