import { fetchSubProductsOrNominals } from '@api/useQueryCalls';
import BPNoDataFound from '@atoms/BPNoDataFound';
import LoginPhoneTxt from '@atoms/LoginPhoneTxt';
import useBPMutation from '@customHooks/useBPRequest';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { View, Text, useToast, Button } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
  fetchAvailableProviderFromResponse,
  refetchBPIfStale,
  shouldShowPhoneSelection,
  shouldShowTitle,
  showErrorOnAPI,
} from '../BPUtils';
import { logErrorOnAnalytics, logPageBack, logPageLaunch, logRequestBP } from '../GGNominals/analyticsNominals';

function GGPascabayar(props) {
  const [showEmptyView, setShowEmptyView] = useState(false);
  const { setLoading, setInitialData, bpPhone, selectedBPAccount, validatedReferralCode, meAmplitudeContext } =
    getStore();
  const { accountTypeId, amplitudeAccountName } = selectedBPAccount;
  const { route } = props;
  const { params } = route;
  const { t } = useTranslation('common');
  const toast = useToast();
  const { productConfig, productApiData, source } = params;
  const { productId } = productConfig;
  const navigation = useNavigation();

  const { mobileNumber } = bpPhone;
  const [availablePostPaidProvider, setAvailablePostPaidProvider] = useState({});
  const { isLoading, isSuccess, data, isError, error, isStale, refetch } = fetchSubProductsOrNominals({
    billAccountNumber: mobileNumber,
    productId,
    accountTypeId,
  });
  const [openRTS, setOpenRTS] = useState(false);
  const [bpReqData, setBpReqData] = useState({});
  const { requestBP, isBPReqError, bpError, apiPayload } = useBPMutation({ setOpenRTS, setBpReqData });
  useEffect(() => {
    logPageLaunch(productConfig, source, validatedReferralCode);
    refetchBPIfStale(isStale, refetch);
    return () => logPageBack(productConfig, source, validatedReferralCode);
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    if (isBPReqError && bpError) {
      logErrorOnAnalytics(
        isBPReqError,
        mobileNumber,
        productConfig,
        apiPayload,
        t,
        bpError,
        source,
        validatedReferralCode,
      );
    }
  }, [isBPReqError, bpError]);

  useEffect(() => {
    fetchAvailableProviderFromResponse(isSuccess, data, setAvailablePostPaidProvider, setShowEmptyView);
  }, [isSuccess, data]);
  return (
    <View style={appStyles.safeAreaView}>
      {shouldShowPhoneSelection(params)}
      {shouldShowTitle(params)}
      <View style={[appStyles.marginHorizontal(16), appStyles.flex1]}>
        {showEmptyView ? (
          <BPNoDataFound />
        ) : (
          <View>
            <Text variant="md-bold" color={colors.neutral.charcoal} style={appStyles.marginTop(16)}>
              {t('bp.pascabayarDetails')}
            </Text>
            <View style={styles.dashedBorder}>
              <View style={[appStyles.marginLeft(16), appStyles.marginTop(16), appStyles.marginBottom(16)]}>
                <LoginPhoneTxt variant="lg-bold" marginTop={0} />
                <Text testID="txtPhoneNumber" variant="lg-normal" style={appStyles.marginTop(4)}>
                  {mobileNumber}
                </Text>
                <Text testID="txtProviderLbl" variant="lg-bold" style={appStyles.marginTop(24)}>
                  {t('bp.provider')}
                </Text>
                <Text testID="txtProviderName" variant="lg-normal" style={appStyles.marginTop(4)}>
                  {availablePostPaidProvider.name}
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
      <View
        style={[
          appStyles.borderWithShadow,
          appStyles.marginHorizontal(0),
          appStyles.marginTop(0),
          appStyles.paddingHorizontal(0),
          appStyles.paddingTop(0),
          appStyles.borderTopLeftRadius(0),
          appStyles.borderTopRightRadius(0),
          appStyles.borderBottomLeftRadius(0),
          appStyles.borderBottomRightRadius(0),
        ]}>
        <Button
          testID="btnPascabayarRequest"
          isDisabled={showEmptyView}
          onPress={() => {
            logRequestBP(
              mobileNumber,
              productConfig,
              availablePostPaidProvider,
              source,
              validatedReferralCode,
              meAmplitudeContext,
              amplitudeAccountName,
            );
            const request = {
              accountNumber: mobileNumber,
              employeeAccountNumber: selectedBPAccount.id,
              transCategoryPrefix: productApiData.prefix,
              productCode: availablePostPaidProvider.code,
              referralCode: validatedReferralCode,
              zoneId: null,
              month: null,
            };
            requestBP(request);
          }}
          style={[appStyles.marginHorizontal(16), appStyles.marginVertical(16)]}>
          {t('reqTxSheet.checkBill')}
        </Button>
        <RequestTransactionSheet
          openRTS={openRTS}
          setOpenRTS={setOpenRTS}
          productConfig={productConfig}
          productApiData={productApiData}
          selectedAccountNumber={mobileNumber}
          nominalSelected={availablePostPaidProvider}
          bpReqData={bpReqData}
        />
      </View>
    </View>
  );
}

GGPascabayar.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      productConfig: PropTypes.shape({
        productId: PropTypes.number,
        productName: PropTypes.string,
        noOfColumns: PropTypes.number,
      }),
      productApiData: PropTypes.shape({
        prefix: PropTypes.string,
      }),
    }),
  }),
};

GGPascabayar.defaultProps = {
  route: {
    params: {
      source: '',
      productConfig: {
        productId: '',
        productName: '',
        noOfColumns: 1,
      },
      productApiData: {
        prefix: '',
      },
    },
  },
};
export default GGPascabayar;
