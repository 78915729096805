import RemoteImage from '@atoms/RemoteImage';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import logSubProdClick from './analyticsSubProduct';
import styles from './styles';

function getSelectedBGColor(selectedIndex, index) {
  return selectedIndex === index ? colors.secondary.softOrchid : colors.neutral.cotton;
}

function getSelectedBorderColor(selectedIndex, index) {
  return selectedIndex === index ? colors.secondary.orchid : colors.neutral.cotton;
}

function BPSubProductTile(props) {
  const { selectedProductItem, productItem, iconLink, setSelectedProductItem, productConfig } = props;

  return (
    <TouchableOpacity
      testID={productItem}
      style={[
        appStyles.borderWithShadow,
        styles.subProductTile,
        {
          borderColor: getSelectedBorderColor(selectedProductItem, productItem),
          backgroundColor: getSelectedBGColor(selectedProductItem, productItem),
        },
      ]}
      onPress={() => {
        logSubProdClick(productItem, productConfig);
        setSelectedProductItem(productItem);
      }}>
      <RemoteImage width={scale(62)} height={verticalScale(28)} contentFit="cover" uri={iconLink} />
    </TouchableOpacity>
  );
}

BPSubProductTile.propTypes = {
  productConfig: PropTypes.shape({}),
  selectedProductItem: PropTypes.string,
  productItem: PropTypes.string,
  iconLink: PropTypes.string,
  setSelectedProductItem: PropTypes.func,
};

BPSubProductTile.defaultProps = {
  productConfig: {},
  selectedProductItem: {},
  productItem: {},
  iconLink: '',
  setSelectedProductItem: () => {},
};

export default BPSubProductTile;
