import { eKasbon } from '@assets/png/imagesPng';
import { Text } from 'native-base';
import React from 'react';
import { View, Image, Pressable } from 'react-native';
import styles from './style';

interface LoanHeaderProps {
  headerText?: string;
  onLinkPress?: () => void;
  subHeader?: string;
}

const LoanHeader: React.FC<LoanHeaderProps> = ({ headerText = '', onLinkPress = () => { }, subHeader = '' }) => (
  <View style={styles.infoOuterContainer}>
    <View style={styles.infoLeftSubContainer}>
      <Image source={eKasbon} contentFit="contain" style={styles.logoImageStyle} />
    </View>
    <View style={styles.infoRightSubContainer}>
      <Text variant="md-bold" style={styles.infoTextStyle}>
        {headerText}
      </Text>
      <Pressable testID="linkBtn" onPress={onLinkPress} style={styles.linkStyle}>
        <Text variant="sm-bold" style={styles.linkTextStyle}>
          {subHeader}
        </Text>
      </Pressable>
    </View>
  </View>
);

export default LoanHeader;
