import InfoTextComponent from '@atoms/InfoTextComponent';
import MyModal from '@atoms/MyModal';
import AntDesign from '@expo/vector-icons/AntDesign';
import MCIcon from '@expo/vector-icons/MaterialCommunityIcons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View, Pressable } from 'react-native';
import styles from './style';

interface ToolTipModalProps {
  isVisible?: boolean;
  handleVisible: () => void;
  withdrawalLimit: string;
  t: (key: string) => string;
}

const ToolTipModal: React.FC<ToolTipModalProps> = ({ isVisible = false, handleVisible, withdrawalLimit, t }) => (
  <MyModal isVisible={isVisible} onPress={handleVisible} containerStyle={styles.containerStyle}>
    <View style={styles.innerContainer}>
      <View style={styles.rowStyle}>
        <View style={styles.subRow}>
          <View style={styles.iconContainer}>
            <MCIcon name="wallet" size={scale(17)} color={colors.secondary.orchid} style={styles.modalIconStyle} />
          </View>

          <Text variant="md-bold" style={styles.limitStyle}>
            {t('salaryInfo.tooltipTitle')}
          </Text>
        </View>

        <Pressable style={styles.crossPressable} onPress={handleVisible}>
          <AntDesign name="close" size={scale(20)} />
        </Pressable>
      </View>

      <View style={styles.witdrawLimitStyle}>
        <Text variant="xl-bold">{withdrawalLimit}</Text>
      </View>
      <InfoTextComponent
        customTextStyle={styles.tooltipBannerTextStyle}
        iconContainer={styles.tooltipBannerIconContainerStyle}
        containerStyle={styles.tooltipBannerContainerStyle}
        iconName="info-outline"
        infoText={t('salaryInfo.tooltipDesc')}
        size={scale(22)}
        iconColor={colors.neutral.charcoal}
        show
        variant="xsm-bold"
      />
    </View>
  </MyModal>
);

export default ToolTipModal;
