import TextCurrency from '@atoms/TextCurrency';
import { Divider, Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

type LoanMetaDetailProps = {
  upFrontFee: number,
  show: boolean,
  t: (key: string) => string,
};

const LoanMetaDetail: React.FC<LoanMetaDetailProps> = ({ upFrontFee, show, t }) => {
  if (!show) return null;

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{t('loan.loanMetaText3')}</Text>
      <Text style={styles.descriptText}>{t('loan.loanMetaText1')}</Text>
      <View style={styles.rowContainer}>
        <Text style={styles.keyStyle}>{t('loan.loanMetaText2')}</Text>
        <TextCurrency
          variant="sm-bold"
          style={styles.keyStyle}
          amountCurrency={parseInt(String(upFrontFee), 10)}
          showCurrencySymbol
        />
      </View>
      <Divider style={styles.dividerStyle} />
    </View>
  );
};

export default LoanMetaDetail;
