import InfoTextComponent from '@atoms/InfoTextComponent';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import { useNavigation } from '@react-navigation/native';
import { FEATURE_FLAGS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { AUTO_DISBURSE_KEYS, AUTO_DISBURSE_UI, showBanner } from './autoDisburseUtils';
import styles from './styles';

interface AutoWithdrawalProps {
  isBillPayment?: boolean;
}

const AutoWithdrawal: React.FC<AutoWithdrawalProps> = ({ isBillPayment = false }) => {
  const { isInLockPeriod, isSuspended, autoDisburseData } = getStore();
  const navigation = useNavigation();
  const { t } = useTranslation('common');

  // Feature flag check
  const [isFeatureAvailable] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.AUTODISBURSE],
  });

  // Determine the Auto Disburse Title
  const AUTO_DISBURSE_TITLE = useMemo(() => {
    if (isSuspended) return AUTO_DISBURSE_KEYS.SUSPENDED;
    if (isInLockPeriod) return AUTO_DISBURSE_KEYS.LOCKPERIOD;
    if (autoDisburseData?.allowEwaAutoDisburse) return AUTO_DISBURSE_KEYS.OPT_OUT;
    return AUTO_DISBURSE_KEYS.OPT_IN;
  }, [isSuspended, isInLockPeriod, autoDisburseData]);

  // Handle cases where no data is available
  if (!autoDisburseData || Object.keys(autoDisburseData).length === 0) return null;

  // Derive UI props from the computed title
  const uiProps = AUTO_DISBURSE_UI[AUTO_DISBURSE_TITLE];

  return (
    <InfoTextComponent
      show={showBanner(
        isInLockPeriod,
        isSuspended,
        isBillPayment,
        autoDisburseData,
        isFeatureAvailable[FEATURE_FLAGS.AUTODISBURSE]?.show,
      )}
      customTextStyle={styles.lockPeriodBannerTextStyle}
      containerStyle={{
        ...styles.lockperiodBannerContainerStyle,
        backgroundColor: uiProps.containerBackground,
      }}
      iconContainer={styles.lockPeriodBannerIconContainerStyle}
      iconName={uiProps.iconName}
      infoText={uiProps.infoText(t, autoDisburseData, navigation, Linking)}
      size={24} // scale removed for simplicity
      iconColor={uiProps.iconColor}
      variant="sm-bold"
    />
  );
};

export default React.memo(AutoWithdrawal);
