import { fetchSalaryInfo } from '@api/useQueryCalls';
import InfoTextComponent from '@atoms/InfoTextComponent';
import AbsoluteCard from '@atoms/SalaryInfo/AbsoluteCard';
import FAQSection from '@atoms/SalaryInfo/FAQSection';
import StatusBox from '@atoms/SalaryInfo/StatusBox';
import TitleText from '@atoms/SalaryInfo/TitleText';
import ToolTipModal from '@atoms/SalaryInfo/ToolTipModal';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { getDateInFormatLocaleWithInputFormat } from '@utils/dateUtils';
import { scale } from '@utils/normalize';
import { SVG_ICONS } from '@utils/SvgIcons';
import { numberWithDots } from '@utils/utils';
import { Center, Skeleton, VStack, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import styles from './style';

const FAQ_ONE = {
  title: 'FAQ_TITLE_ONE',
  data: [
    {
      icon: SVG_ICONS.salaryInfo.checkSalaryInfo,
      desc: 'FAQ_DESC_1',
    },
    {
      icon: SVG_ICONS.salaryInfo.calculator,
      desc: 'FAQ_DESC_2',
    },
    {
      icon: SVG_ICONS.salaryInfo.refreshSalaryInfo,
      desc: 'FAQ_DESC_3',
    },
  ],
};

const FAQ_TWO = {
  title: 'FAQ_TITLE_TWO',
  data: [
    {
      icon: SVG_ICONS.salaryInfo.phoneCheck,
      desc: 'FAQ_DESC_4',
    },
    {
      icon: SVG_ICONS.salaryInfo.phoneLock,
      desc: 'FAQ_DESC_5',
    },
    {
      icon: SVG_ICONS.salaryInfo.phoneVocal,
      desc: 'FAQ_DESC_6',
    },
  ],
};
export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';

export function handleCollapsePressHandler(setCollapsed) {
  return () => setCollapsed(prevState => !prevState);
}

export function getCollpaseState(isCollapsed) {
  return isCollapsed ? 'expand' : 'collapse';
}
export function handleFIrstFaqCollapse(firstFaqCollapse, setFirstFaqCollapse) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.faq_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.question]: 'available_balance',
      [e.attributes.action]: getCollpaseState(firstFaqCollapse),
    });

    setFirstFaqCollapse(prevState => !prevState);
  };
}

export function handleSecondFaqCollapse(secondFaqCollapse, setSecondFaqCollapse) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.faq_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.question]: 'transaction_period',
      [e.attributes.action]: getCollpaseState(secondFaqCollapse),
    });
    setSecondFaqCollapse(prevState => !prevState);
  };
}
export function handleVisible(setTooltipVisible, isClicked) {
  return () => {
    if (isClicked) {
      const e = AMPLITUDE_CONSTANTS.salaryInfo.limit_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
    }

    setTooltipVisible(prevState => !prevState);
  };
}

export function onMountHandler(isTootipVisible) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo;
    if (isTootipVisible)
      AmplitudeHelper.logEvent(e.limit_popup_appear.name, {
        [e.limit_popup_appear.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
    if (isTootipVisible === false)
      AmplitudeHelper.logEvent(e.limit_popup_closed.name, {
        [e.limit_popup_closed.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
  };
}

export function getDisplayText(isInLockPeriod, t) {
  return !isInLockPeriod ? t('salaryInfo.textSeven') : t('salaryInfo.textEight');
}

export const SkeletonLoader = () => (
  <Center w="100%">
    <VStack
      w="100%"
      borderWidth="1"
      space={8}
      overflow="hidden"
      rounded="md"
      _dark={{
        borderColor: 'coolGray.500',
      }}
      _light={{
        borderColor: 'coolGray.200',
      }}>
      <Skeleton h="40" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
    </VStack>
  </Center>
);

const GGSalaryInformation = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [firstFaqCollapse, setFirstFaqCollapse] = useState(true);
  const [secondFaqCollapse, setSecondFaqCollapse] = useState(true);
  const { isLoading: loadingSalaryInfo, isError, error: salaryInfoError, data: salaryInfoData } = fetchSalaryInfo();
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  const { isInLockPeriod, tenantSettings, setInitialData } = getStore();

  useEffect(() => {
    shouldShowError(isError, salaryInfoError, toast, t, navigation, setInitialData);
  }, [isError, salaryInfoError]);

  const { currencySymbol } = tenantSettings;

  const [isTootipVisible, setTooltipVisible] = useState(false);

  useEffect(onMountHandler(isTootipVisible), [isTootipVisible]);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.salary_info_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
  }, []);

  useEffect(
    () => () => {
      const e = AMPLITUDE_CONSTANTS.salaryInfo.back_button_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
    },
    [],
  );

  if (loadingSalaryInfo || !salaryInfoData) return <SkeletonLoader />;

  const containerStyle = isInLockPeriod ? styles.lockperiodBannerContainerStyle : styles.periodContainer;
  const iconColor = isInLockPeriod ? colors.primary.carnation : colors.secondary.darkOrchid;

  return (
    <ScrollView style={styles.parentStyle} contentContainerStyle={styles.parentContainer}>
      <View style={styles.absoluteAdj}>
        <AbsoluteCard
          isCollapsed={isCollapsed}
          handleColllapsePress={handleCollapsePressHandler(setCollapsed)}
          ewaBalance={`${currencySymbol}${numberWithDots(salaryInfoData.availableBalance)}`}
          withdrawalLimit={`${currencySymbol}${salaryInfoData.withdrawalLimitPerCycle}`}
          totalAccuredSalary={`${currencySymbol}${numberWithDots(salaryInfoData.accruedSalaryInCycle)}`}
          workingDays={salaryInfoData?.daysInCycle?.workingPresent}
          totalExpense={`-${currencySymbol}${numberWithDots(salaryInfoData.usedBalance)}`}
          daySalary={`${currencySymbol}${numberWithDots(salaryInfoData.dailyAccrual)}`}
          handleLimitPress={handleVisible(setTooltipVisible, true)}
          t={t}
        />

        <TitleText title={t('salaryInfo.textSiz')} />

        <StatusBox
          displayText={getDisplayText(isInLockPeriod, t)}
          isActive={!isInLockPeriod}
          timeStamp={getDateInFormatLocaleWithInputFormat(
            salaryInfoData.openPeriodEnd,
            'DD MMMM YYYY',
            INPUT_DATE_FORMAT,
          )}
        />

        <InfoTextComponent
          iconContainer={styles.lockPeriodBannerIconContainerStyle}
          containerStyle={containerStyle}
          iconName="info-outline"
          infoText={`${t('salaryInfo.textTen')} ${getDateInFormatLocaleWithInputFormat(
            salaryInfoData.openPeriodStart,
            'DD/MM/YYYY',
            INPUT_DATE_FORMAT,
          )} - ${getDateInFormatLocaleWithInputFormat(salaryInfoData.openPeriodEnd, 'DD/MM/YYYY', INPUT_DATE_FORMAT)}`}
          customTextStyle={styles.bannerTextStyle}
          size={scale(22)}
          variant="xsm-bold"
          iconColor={iconColor}
          show
        />

        <TitleText title={t('salaryInfo.textEleven')} />
        <FAQSection
          title={t(`salaryInfo.${FAQ_ONE.title}`)}
          data={FAQ_ONE.data}
          handleCollapse={handleFIrstFaqCollapse(firstFaqCollapse, setFirstFaqCollapse)}
          collapse={firstFaqCollapse}
          containerStyle={appStyles.marginTop(14)}
        />
        <FAQSection
          title={t(`salaryInfo.${FAQ_TWO.title}`)}
          data={FAQ_TWO.data}
          handleCollapse={handleSecondFaqCollapse(secondFaqCollapse, setSecondFaqCollapse)}
          collapse={secondFaqCollapse}
        />
      </View>

      <ToolTipModal
        isVisible={isTootipVisible}
        handleVisible={handleVisible(setTooltipVisible)}
        withdrawalLimit={numberWithDots(salaryInfoData.withdrawalLimitPerCycle)}
        t={t}
      />
    </ScrollView>
  );
};

export default GGSalaryInformation;
