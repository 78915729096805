import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import statusRowStyles from './style';

interface StatusRowProps {
  title?: string;
  value?: string;
  show?: boolean;
}

const StatusRow: React.FC<StatusRowProps> = ({ title = '', value = '', show = false }) => {
  if (!show) return null;

  return (
    <View style={statusRowStyles.container}>
      <Text variant="md-normal" style={statusRowStyles.title}>
        {title}
      </Text>
      <Text variant="md-normal" style={statusRowStyles.value}>
        {value}
      </Text>
    </View>
  );
};

export default StatusRow;
