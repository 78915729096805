import appStyles from '@root/appStyles';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { restartWebOnError } from '@utils/helper.web';
import { SentryHelper } from '@utils/performance/SentryHelper';
import { formatUnderScoreLowerCase } from '@utils/utils';
import React, { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import GenericErrorComponent from './GenericErrorComponent';

interface ErrorFallBackProps extends WithTranslation {
  children?: ReactNode;
}

interface ErrorFallBackState {
  hasError: boolean;
  error: Error | null;
}

class ErrorFallBackWeb extends Component<ErrorFallBackProps, ErrorFallBackState> {
  constructor(props: ErrorFallBackProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorFallBackState {
    return {
      error,
      hasError: true,
    };
  }

  componentDidCatch(error: Error) {
    this.setState({
      error,
      hasError: true,
    });

    SentryHelper.captureException(error);
    const errorEvent = AMPLITUDE_CONSTANTS.errorPage.app_error_page;
    AmplitudeHelper.logEvent(errorEvent.name, {
      [errorEvent.attributes.event_triggers]: formatUnderScoreLowerCase(error?.message || error?.stack || ''),
    });
  }

  render() {
    const { children, t } = this.props;
    const { hasError, error } = this.state;
    const buttonStyles = { ...appStyles.marginHorizontal(16), ...appStyles.marginBottom(16) };

    if (hasError) {
      return <GenericErrorComponent t={t} buttonStyles={buttonStyles} error={error} restartApp={restartWebOnError} />;
    }

    return children;
  }
}

export default React.memo(withTranslation('common')(ErrorFallBackWeb));
