import { Text } from 'native-base';
import React, { ReactNode } from 'react';
import { View } from 'react-native';
import styles from './style';

interface SectionTitleProps {
  title?: string;
  show?: boolean;
  children?: ReactNode;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title = '', show = false, children = null }) => {
  if (!show) return null;

  return (
    <>
      <View style={styles.container}>
        <Text variant="md-normal" style={styles.titleText}>
          {title}
        </Text>
      </View>
      {children}
    </>
  );
};

export default SectionTitle;
