import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logOptInHandler(): void {
  const e = AMPLITUDE_CONSTANTS.AutoDisburse.opt_in_autowd_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'banner_homepage',
  });
}

export function logOptOutHandler(): void {
  const e = AMPLITUDE_CONSTANTS.AutoDisburse.opt_out_autowd_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'banner_homepage',
  });
}
