import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  inputStyles: {
    borderColor: colors.neutral.darkMist,
    borderRadius: scale(8),
    borderWidth: 1,
    color: colors.neutral.charcoal,
    fontFamily: regularFont,
    fontSize: scale(14),
    lineHeight: scale(21),
    marginTop: scale(4),
    paddingHorizontal: scale(16),
    paddingVertical: scale(13),
    ...(Platform.OS === 'web' && { outlineStyle: 'none' }), // ✅ Only applies on web
  },
});

export default styles;
