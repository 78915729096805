import ReferralEarnPoins from '@atoms/ReferralEarnPoins';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ReferralInfoTxCardProps {
  amount?: number;
}

const ReferralInfoTxCard: React.FC<ReferralInfoTxCardProps> = ({ amount = 0 }) => {
  const { t } = useTranslation('common');

  return (
    <View
      backgroundColor={colors.warning.softSunflower}
      style={[
        appStyles.flex1,
        appStyles.borderRadius(12),
        appStyles.paddingHorizontal(10),
        appStyles.paddingVertical(12),
      ]}>
      <View>
        <ReferralEarnPoins amount={amount} />
        <Text
          color={colors.neutral.charcoal}
          variant="lg-bold"
          alignSelf="center"
          textAlign="center"
          style={appStyles.marginTop(3)}>
          {t('referral.txtForFriends')}
        </Text>
        <Text color={colors.neutral.charcoal} variant="sm-normal" alignSelf="center" textAlign="center">
          {t('referral.txtFirstTx')}
        </Text>
      </View>
    </View>
  );
};

export default ReferralInfoTxCard;
