import RemoteImage from '@atoms/RemoteImage';
import { Entypo } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';
import { View, Pressable } from 'react-native';
import styles from './styles';

interface ReasonSelectorProps {
  onPress: () => void;
  selectedReason: string;
  disabled: boolean;
  iconUrl: string;
}

const ReasonSelector: React.FC<ReasonSelectorProps> = ({ onPress, selectedReason, disabled, iconUrl }) => (
  <View style={styles.container}>
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={styles.button}
      testID="reasonSelectorBtn"
      android_ripple={{ color: colors.greyShade }}>
      <View style={styles.iconContainer}>
        <RemoteImage uri={iconUrl} width={32} height={32} />
        <Text variant="md-bold" style={{ ...styles.textReason, color: colors.neutral.charcoal }}>
          {selectedReason}
        </Text>
      </View>
      <Entypo name="chevron-down" size={18} color={colors.neutral.charcoal} />
    </Pressable>
  </View>
);

export default ReasonSelector;
