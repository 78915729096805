import { fetchDepartments, fetchRoles } from '@api/useQueryCalls';
import CommonTextInput from '@atoms/CommonTextInput';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { NavigationProps } from '@navigation/types';
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StatusBar, View, Pressable, FlatList, ScrollView } from 'react-native';
import { logRoleChoosen, logisDeparmentChoose } from './analyticsDepDesign';
import styles from './style';

export function searchDepartments(listData: string[], query: string): string[] {
  const sanitizedQuery = query.toLowerCase();
  return listData.filter(d => d.toLowerCase().includes(sanitizedQuery));
}

export function shouldRenderClose(show: boolean, setSearchText: (text: string) => void) {
  if (show)
    return (
      <Pressable testID="closeBtnIcon" onPress={() => setSearchText('')}>
        <Ionicons name="close-circle" color={colors.neutral.charcoal} size={scale(18)} style={styles.closeIconStyle} />
      </Pressable>
    );
  return null;
}

export function shouldProcessRolesResult(
  roleList: ({ role_name: string } | string)[],
  setDeptOrDesign: (roles: string[]) => void
): void {
  const roleNames = roleList.map(role => (typeof role === 'string' ? role : role.role_name));
  setDeptOrDesign(roleNames);
}

export function shouldRenderDepartments(
  departmentList: ({ department_name: string } | string)[],
  setDeptOrDesign: (departments: string[]) => void
): void {
  const departmentNames = departmentList?.map(department =>
    typeof department === 'string' ? department : department.department_name,
  );
  setDeptOrDesign(departmentNames);
}

export function onPressHandler(
  item: string,
  isDepartment: boolean,
  setEmployeeProfile: (profile: any) => void,
  employeeProfile: any,
  employeeId: string,
  employerId: string,
  navigation: NavigationProp<any>
): void {
  if (isDepartment) {
    setEmployeeProfile({ ...employeeProfile, selectedDepartmentName: item });
    logisDeparmentChoose(employeeId, employerId);
  } else {
    setEmployeeProfile({ ...employeeProfile, selectedDesignationName: item });
    logRoleChoosen(employeeId, employerId);
  }
  navigation.goBack();
}

interface DeptDesignListRouteParams {
  isDepartment?: boolean;
}

type DeptDesignListRouteProp = RouteProp<{ params: DeptDesignListRouteParams }, 'params'>;

const DeptDesignList: React.FC = () => {
  const navigation = useNavigation<NavigationProps>();
  const route = useRoute<DeptDesignListRouteProp>();
  const isDepartment = route?.params?.isDepartment;

  const { t } = useTranslation('common');

  const { employeeId, employerId, setEmployeeProfile, employeeProfile } = getStore();

  const [deptOrDesign, setDeptOrDesign] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (!isDepartment) {
      navigation.setOptions({ headerTitle: t('userProfile.designTitle') });
    }
  }, [isDepartment, navigation, t]);

  const { refetch: refetchRoles } = fetchRoles({
    onSuccess: (roles: string[]) => {
      if (roles?.length > 0) shouldProcessRolesResult(roles, setDeptOrDesign);
    },
  });

  const { refetch: refetchDepartments } = fetchDepartments({
    onSuccess: (departments: string[]) => {
      if (departments?.length > 0) shouldRenderDepartments(departments, setDeptOrDesign);
    },
  });

  useEffect(() => {
    if (isDepartment) refetchDepartments();
    else refetchRoles();
  }, [isDepartment, refetchDepartments, refetchRoles]);

  const onChangeSearchText = (txt: string) => setSearchText(txt);

  const renderItem = ({ item }: { item: string }) => (
    <TouchableOpacity
      testID="itemBtn"
      onPress={() =>
        onPressHandler(item, isDepartment, setEmployeeProfile, employeeProfile, employeeId, employerId, navigation)
      }
      style={styles.itemContainer}>
      <Text style={styles.itemTextStyle}>{item}</Text>
    </TouchableOpacity>
  );

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <StatusBar translucent={false} backgroundColor={colors.secondary.orchid} />

      <View style={styles.containerStyle}>
        <View style={styles.inputTextContainer}>
          <CommonTextInput
            value={searchText}
            testID="textInput"
            style={styles.inputSearch}
            customBorderStyle={styles.borderStyle}
            onChangeText={onChangeSearchText}
            placeholder={t('userProfile.departmentSearch')}
            placeholderTextColor={colors.neutral.darkMist}
          />
          {shouldRenderClose(searchText.length > 0, setSearchText)}
          <View testID="search">
            <MaterialIcons name="search" color={colors.neutral.charcoal} size={scale(25)} />
          </View>
        </View>
      </View>

      <FlatList
        data={searchDepartments(deptOrDesign, searchText)}
        keyExtractor={item => item}
        contentContainerStyle={styles.contentContainerStyle}
        testID="depDesignFlatList"
        renderItem={renderItem}
      />
    </ScrollView>
  );
};

export default DeptDesignList;
