import navigationConstants from '@navigation/navigationConstants';
import { NavigationProp } from '@react-navigation/native';
import { COMMON_CONSTANTS } from '@root/constants';
import colors from '@theme/colors';
import { getDateInFormatLocale, getDateInFormatLocaleSubtract } from '@utils/dateUtils';
import { Text } from 'native-base';
import React, { ReactNode } from 'react';
import { logOptInHandler, logOptOutHandler } from './analyticsAutoWithdrawal';
import styles from './styles';

export const AUTO_DISBURSE_KEYS = {
  LOCKPERIOD: 'LOCKPERIOD',
  OPT_IN: 'OPT_IN',
  OPT_OUT: 'OPT_OUT',
  SUSPENDED: 'SUSPENDED',
} as const;

export type AutoDisburseKey = keyof typeof AUTO_DISBURSE_KEYS;

export const LOCK_PERIOD_FORMAT = 'YYYY-MM-DD';

const querybuilderTxt = 'query-builder';

export function onOptInHandler(navigation: NavigationProp<any>) {
  return () => {
    logOptInHandler();
    navigation?.navigate(navigationConstants.GG_AUTO_DISBURSE_TERMS);
  };
}

export function onOptOutHandler(Linking: typeof import('react-native').Linking) {
  return () => {
    logOptOutHandler();
    Linking.openURL(COMMON_CONSTANTS.support);
  };
}

interface AutoDisburseUIConfig {
  containerBackground: string;
  iconName: string;
  iconColor: string;
  infoText: (
    t: (key: string) => string,
    autoDisburseLoad?: { lockPeriodDate?: string },
    navigation?: NavigationProp<any>,
    Linking?: typeof import('react-native').Linking,
  ) => ReactNode;
}

export const AUTO_DISBURSE_UI: Record<AutoDisburseKey, AutoDisburseUIConfig> = {
  SUSPENDED: {
    containerBackground: colors.error.softRose,
    iconName: querybuilderTxt,
    iconColor: colors.primary.carnation,
    infoText: t => t('autoDisburse.suspended'),
  },
  LOCKPERIOD: {
    containerBackground: colors.error.softRose,
    iconName: querybuilderTxt,
    iconColor: colors.primary.carnation,
    infoText: t => t('autoDisburse.lockPeriod'),
  },
  OPT_IN: {
    containerBackground: colors.warning.softSunflower,
    iconName: querybuilderTxt,
    iconColor: colors.warning.sunflower,
    infoText: (t, autoDisburseLoad, navigation) => (
      <Text variant="sm-bold">
        {t('autoDisburse.optIn.first')} {getDateInFormatLocale(autoDisburseLoad?.lockPeriodDate ?? '', 'DD MMMM YYYY')}{' '}
        <Text onPress={onOptInHandler(navigation!)} style={styles.autoDisburseBlueLink}>
          {t('autoDisburse.optIn.linkButton')}
        </Text>
      </Text>
    ),
  },
  OPT_OUT: {
    containerBackground: colors.success.softCactus,
    iconName: 'check-circle-outline',
    iconColor: colors.success.cactus,
    infoText: (t, autoDisburseLoad, _, Linking) => (
      <Text variant="sm-bold">
        {t('autoDisburse.optOut.first')}{' '}
        {getDateInFormatLocaleSubtract(autoDisburseLoad?.lockPeriodDate ?? '', 'DD MMMM YYYY')}{' '}
        <Text onPress={onOptOutHandler(Linking!)} style={styles.autoDisburseRedLink}>
          {t('autoDisburse.optOut.linkButton')}
        </Text>
      </Text>
    ),
  },
};

export function showBanner(
  lockPeriod: boolean,
  isSuspended: boolean,
  isBillPayment: boolean,
  autoDisburseData: any,
  isFeaturePresent: boolean,
): boolean {
  return lockPeriod || isSuspended || (autoDisburseData && isFeaturePresent && !isBillPayment);
}
