import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';
import { View, Linking } from 'react-native';
import styles from './style';

const LOAN_READ_LINK = 'https://gajigesa.com/e-Kasbon';

export const onLinkPress = () => Linking.openURL(LOAN_READ_LINK);

export function shouldRenderLoanLinkItem(item: string) {
  if (item === 'loanMetaInfo6') {
    return (
      <Text style={{ color: colors.secondary.orchid }} onPress={onLinkPress}>
        {' '}
        {LOAN_READ_LINK}
      </Text>
    );
  }
  return null;
}

type LoanMetaItemProps = {
  item: string,
  index: number,
  t: (key: string) => string,
};

const LoanMetaItem: React.FC<LoanMetaItemProps> = ({ item, index, t }) => (
  <View style={styles.termsRow}>
    <View style={styles.termsIconContainer}>
      <View style={styles.circlePlaceholder}>
        <Text style={{ color: colors.neutral.cotton }}>{index + 1}</Text>
      </View>
    </View>
    <View style={styles.termsTextContainer}>
      <Text style={styles.termsTextStyle}>
        {t(`loan.${item}`)}
        {shouldRenderLoanLinkItem(item)}
      </Text>
    </View>
  </View>
);

export default LoanMetaItem;
