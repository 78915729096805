import ToolTipPopOver from '@atoms/ToolTipPopOver';
import { Tooltip } from '@rneui/base';
import { scale } from '@utils/normalize';
import React, { ReactNode, useState } from 'react';
import { Dimensions } from 'react-native';
import logCustomTooltip from './analyticsCustomTooltip';
import styles from './styles';

interface LogParams {
  [key: string]: string | number;
}

interface ControlledTooltipProps {
  translateString?: string;
  children?: ReactNode;
  logParams?: LogParams;
}

const ControlledTooltip: React.FC<ControlledTooltipProps> = ({
  translateString = '',
  children = null,
  logParams = {},
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      containerStyle={styles.tooltipContainerStyle}
      width={Dimensions.get('screen').width - scale(24)}
      popover={<ToolTipPopOver setOpen={setOpen} translateString={translateString} />}
      skipAndroidStatusBar
      withPointer={false}
      closeOnlyOnBackdropPress
      visible={open}
      onOpen={() => {
        if (open) return;
        logCustomTooltip(logParams);
        setOpen(true);
      }}
      onClose={() => setOpen(false)}>
      {children}
    </Tooltip>
  );
};

export default React.memo(ControlledTooltip);
