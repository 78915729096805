import { Entypo } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text, View } from 'native-base';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Collapsible from 'react-native-collapsible';

interface CollapsibleTermsProps {
  tncContent: string[];
  textKey: string;
}

const CollapsibleTerms: React.FC<CollapsibleTermsProps> = ({ tncContent = [''], textKey = '' }) => {
  const firstItem = tncContent[0];
  const remainingItems = tncContent.slice(1);
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <View>
      <Text variant="md-normal">
        <Entypo name="dot-single" size={scale(15)} />
        {firstItem}
      </Text>
      <Collapsible collapsed={isCollapsed}>
        {remainingItems.map(tncText => (
          <Text style={appStyles.marginVertical(6)} key={`${textKey}${tncText}`} variant="md-normal">
            <Entypo name="dot-single" size={scale(15)} />
            {tncText}
          </Text>
        ))}
      </Collapsible>
      <TouchableOpacity
        testID={`btn${textKey}`}
        style={appStyles.marginTop(4)}
        onPress={() => setIsCollapsed(!isCollapsed)}>
        <Text variant="md-normal" style={{ color: colors.secondary.orchid }}>
          {isCollapsed ? 'Selengkapnya' : 'Sembunyikan'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default CollapsibleTerms;
