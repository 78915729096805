import CommonTextInput from '@atoms/CommonTextInput';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import styles from './styles';

interface ReferralUserInputProps {
  setReferralCode?: (value: string) => void;
  pageSource?: string;
}

const ReferralUserInput: React.FC<ReferralUserInputProps> = ({ setReferralCode = () => {}, pageSource = '' }) => {
  const { t } = useTranslation('common');

  return (
    <CommonTextInput
      style={styles.referralInputStyle}
      customBorderStyle={{ borderRadius: scale(8), borderColor: colors.disabled.smoke }}
      testID="txtInputReferral"
      keyboardType="number-pad"
      placeholder={t('referral.inputPlaceHolder')}
      onChangeText={val => setReferralCode(val)}
      amplitudeCallBackOnBlur={(event: any) => {
        let textValue = '';

        // ✅ Check platform for correct event handling
        if (Platform.OS === 'web') {
          textValue = event.target.value ?? ''; // Web (react-native-web)
        } else {
          textValue = (event as NativeSyntheticEvent<TextInputFocusEventData>).nativeEvent.text ?? ''; // Mobile (React Native)
        }

        const e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_inputted;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: textValue,
          [e.attributes.source]: pageSource,
        });
      }}
    />
  );
};

export default ReferralUserInput;
