import { fetchUserProperties } from '@api/useQueryCalls';
import SkeletonComponent from '@atoms/SkeletonComponent';
import navigationConstants from '@navigation/navigationConstants';
import { onGetUserPropertiesCallBack } from '@organisms/ProfileTab/profileUtils';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import logLihatPress from './analyticsProfileHaeder';
import ProfileHeaderComponent from './ProfileHeaderComponent';

interface ProfileHeaderProps {
  isPICReferral?: string | boolean;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ isPICReferral = false }) => {
  const { setEmployeeProfile, employeeProfile } = getStore();

  const navigation = useNavigation();

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  const { refetch: getUserProperties, isLoading: loading } = fetchUserProperties({
    onSuccess: onGetUserPropertiesCallBack(setEmployeeProfile),
  });

  useEffect(() => {
    getUserProperties();
  }, []);

  const onLihatPressHandler = () => {
    logLihatPress(isPIC);

    navigation.navigate(navigationConstants.USER_PROFILE);
  };
  if (loading) {
    return <SkeletonComponent />;
  }
  return (
    <ProfileHeaderComponent
      profileData={employeeProfile}
      onLihatPressHandler={onLihatPressHandler}
      isPICReferral={isPICReferral}
    />
  );
};

ProfileHeader.propTypes = {
  isPICReferral: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
ProfileHeader.defaultProps = {
  isPICReferral: false,
};
export default ProfileHeader;
