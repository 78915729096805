import { MaterialIcons } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

const IconRightArrow: React.FC = () => (
  <MaterialIcons
    name="keyboard-arrow-right"
    size={24}
    color="black"
    style={appStyles.marginRight(16) as StyleProp<ViewStyle>}
  />
);

export default IconRightArrow;
