import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  maskIconInit,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React, { useState } from 'react';
import styles from './styles';

interface BankDetails {
  bankName: string;
  accountNumber: string;
  beneficiaryName: string;
}

interface WithdrawalBankDetailsProps {
  bank?: BankDetails;
}

const accountName = 'a/n';

const WithdrawalBankDetails: React.FC<WithdrawalBankDetailsProps> = ({
  bank = { bankName: '', accountNumber: '', beneficiaryName: '' },
}) => {
  const { bankName, accountNumber, beneficiaryName } = bank;
  const [maskData, setMaskData] = useState(true);
  const [maskIcon, setMaskIcon] = useState(maskIconInit(maskData));

  return (
    <View style={styles.bankParent}>
      <View style={styles.bankContainer}>
        <MaterialCommunityIcons name="bank" size={24} color={colors.neutral.cotton} style={styles.bankIconStyles} />
      </View>
      <View style={appStyles.marginLeft(12)}>
        <Text testID="lblBankName" variant="md-normal">
          {bankName}
        </Text>
        <View style={styles.maskUnmaskContainer}>
          <Text testID="lblMaskUnmaskAcNo" variant="lg-bold">
            {getMaskedOrUnmaskedData(maskData, accountNumber)}
          </Text>
          <Ionicons
            name={maskIcon}
            size={16}
            color="black"
            testID="btnMaskUnmaskImage"
            style={appStyles.marginLeft(8)}
            onPress={() => {
              setMaskIcon(setMaskIconValue(maskData));
              setMaskData(!maskData);
            }}
          />
        </View>
        <Text testID="lblBeneficiaryName" variant="sm-normal">
          {accountName} {beneficiaryName}
        </Text>
      </View>
    </View>
  );
};

export default WithdrawalBankDetails;
