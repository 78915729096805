import ReasonsSwitch from '@atoms/ReasonsSwtich';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { View } from 'native-base';
import React, { useRef } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import shouldShowCustomReasons from './reasonsListUtils';
import styles from './styles';

interface Reason {
  id: number;
  [key: string]: any;
  reason: string;
  imageUrl: string;
  selected: boolean;
  empty: boolean;
}

interface ReasonsListProps {
  source?: string;
  transaction_category_id?: string;
  reasons: Reason[];
  setReasonsDisplay: (reasons: Reason[]) => void;
  setDisableContinue: (disable: boolean) => void;
  setCustomReasonText: (text: string) => void;
  isEditReasons?: boolean;
}

export function onUserSwipeHandler(
  source: string,
  validatedReferralCode: string,
  amplitudeAccountName: string,
  setActivePage: (index: number) => void
) {
  return (index: number) => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.referral_code]: validatedReferralCode,
      dynamic_account_name: amplitudeAccountName,
    });
    setActivePage(index);
  };
}

export const getNormalizedData = (data: Reason[], numColumns: number = 3): Reason[] => {
  const remainder = data.length % numColumns;
  if (remainder !== 0) {
    const emptySlots = numColumns - remainder;
    return [...data, ...Array(emptySlots).fill({ empty: true } as Reason)];
  }
  return data;
};

const ReasonsList: React.FC<ReasonsListProps> = ({
  reasons,
  setReasonsDisplay,
  setDisableContinue,
  setCustomReasonText,
  isEditReasons = false,
  source = '',
  transaction_category_id = '',
}) => {
  const reasonsCarousel = useRef<View>(null);

  return (
    <View ref={reasonsCarousel} style={styles.reasonParent}>
      <View style={styles.reasonGrid}>
        {getNormalizedData(reasons).map(reasonItem => (
          <ReasonsSwitch
            key={reasonItem.id}
            reason={reasonItem}
            allReasons={reasons}
            setSelectedReason={setReasonsDisplay}
            setDisableContinue={setDisableContinue}
            source={source}
            transaction_category_id={transaction_category_id}
          />
        ))}
      </View>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        {shouldShowCustomReasons(isEditReasons, setCustomReasonText, source)}
      </KeyboardAvoidingView>
    </View>
  );
};

export default ReasonsList;