import { isFeatureEnabled } from '@utils/utils';
import { isArray } from 'lodash';

export function featureFlagOnComplete(setFeatureFlags, data, onCompleted, featureName, onCompleteWithDynamicFeature) {
  setFeatureFlags(data ?? [], false);
  // dispatch(setFeatureFlagError(false));
  // AsyncStorage.setItem('@featureFlag', JSON.stringify(data.app_feature));
  // dispatch(setFeatureFlagLoading(false));
  if (onCompleted && isArray(data)) {
    onCompleted(isFeatureEnabled(data ?? [], featureName));
  }
  if (onCompleteWithDynamicFeature && isArray(data)) {
    onCompleteWithDynamicFeature(data ?? []);
  }
}

export function featureFlagOnError() {
  // serverError(true, toast, error, navigation);
  // dispatch(setFeatureFlagLoading(false));
  // dispatch(setFeatureFlagError(true));
}
