import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import styles from './styles';

interface SearchListHeaderComponentProps {
  searchData: { [key: string]: string | number }[];
  setFilteredData: (filtered: { [key: string]: string | number }[]) => void;
  searchKey: string;
  searchPlaceholder?: string;
  logSearchDone?: () => void;
}

const SearchListHeaderComponent: React.FC<SearchListHeaderComponentProps> = ({
  searchData = [{ id: 1, name: '' }],
  setFilteredData,
  searchKey,
  searchPlaceholder = '',
  logSearchDone = () => {},
}) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText.length > 0) {
      const filteredData = searchData.filter(data =>
        String(data[searchKey]).toUpperCase().includes(searchText.toUpperCase()),
      );
      setFilteredData(filteredData.length > 0 ? filteredData : []);
    } else {
      setFilteredData(searchData);
    }
  }, [searchText, searchData, searchKey, setFilteredData]);

  return (
    <View backgroundColor={colors.secondary.orchid}>
      <View flexDirection="row" style={styles.searchContainer}>
        <TextInput
          testID="regionInput"
          placeholderTextColor={colors.neutral.darkMist}
          style={styles.txtInputSearch}
          placeholder={searchPlaceholder}
          onChangeText={setSearchText}
          blurOnSubmit
          onBlur={logSearchDone}
        />
        <AntDesign name="search1" size={24} color={colors.neutral.darkMist} style={styles.searchIcon} />
      </View>
    </View>
  );
};

export default SearchListHeaderComponent;
