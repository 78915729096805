import React from 'react';
import { ViewStyle } from 'react-native';
import Modal from 'react-native-modal';
import styles from './styles';

interface MyModalProps {
  isVisible: boolean;
  onPress?: () => void;
  children?: React.ReactNode;
  containerStyle?: ViewStyle;
  hasBackdrop?: boolean;
  customBackdrop?: React.ReactNode;
  backdropOpacity?: number;
  onModalHide?: () => void;
}

const MyModal: React.FC<MyModalProps> = ({
  isVisible = false,
  onPress = () => {},
  children = null,
  containerStyle = {},
  hasBackdrop = true,
  customBackdrop,
  backdropOpacity = 0.7,
  onModalHide = () => {},
}) => (
  <Modal
    testID="modalBackdrop"
    onSwipeComplete={onPress}
    onModalHide={onModalHide}
    hasBackdrop={hasBackdrop}
    useNativeDriver
    animationIn="fadeIn"
    animationOut="fadeOut"
    hideModalContentWhileAnimating
    onBackButtonPress={onPress}
    customBackdrop={customBackdrop}
    backdropOpacity={backdropOpacity}
    backdropTransitionOutTiming={0}
    onBackdropPress={onPress}
    isVisible={isVisible}
    swipeDirection="down"
    style={styles.modalParent(containerStyle)}>
    {children}
  </Modal>
);

export default MyModal;
