import RemoteImage from '@atoms/RemoteImage';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View, Text as MyText } from 'react-native';
import styles from './styles';

export interface TransactionItemType {
  transStatus?: string;
  position?: string;
  transAmount?: number;
  transReason?: string;
  transDate?: string;
  redeemUrl?: string;
  token?: string;
  displayName?: string;
  description?: string;
  iconUrl?: string;
}

export const getStatusAndColor = (status: string) => {
  switch (status) {
    case 'PENDING':
      return { bgColor: colors.warning.softSunflower, color: colors.warning.sunflower, text: 'transStatus.wait' };
    case 'COMPLETED':
    case 'CREDITED':
    case 'DEBITED':
      return { color: colors.success.cactus, bgColor: colors.success.softCactus, text: 'transStatus.success' };
    case 'FAILED':
      return { bgColor: colors.error.softRose, color: colors.error.rose, text: 'transStatus.failed' };
    case 'REJECTED':
      return { bgColor: colors.error.softRose, color: colors.error.rose, text: 'transStatus.rejected' };
    default:
      return { color: colors.neutral.cotton, text: '' };
  }
};

export function shouldShowRedeemURL(item: TransactionItemType, t: (key: string) => string): JSX.Element | null {
  let displayLabel: string | null = null;

  // ✅ Ensure the original logic remains intact
  if (item.redeemUrl || item.token) {
    displayLabel = t('transactionHistory.kodeLink');
  }

  return displayLabel ? (
    <View style={styles.redeemContainer}>
      <MyText style={styles.redeemText}>{displayLabel}</MyText>
    </View>
  ) : null;
}

export function shouldShowCoinOrExpenseArrow(isCoinBased: boolean, account: { icon: string }): JSX.Element | null {
  return isCoinBased ? (
    <View testID="coinIcon" style={styles.coinIconContainer}>
      <RemoteImage uri={account.icon} height={scale(14)} width={scale(14)} />
    </View>
  ) : null;
}

export function shouldShowDisplayName(item: TransactionItemType): JSX.Element | null {
  return item.displayName ? (
    <Text variant="xsm-normal" color={colors.neutral.darkMist}>
      {item.displayName}
    </Text>
  ) : null;
}

export function shouldShowDescription(item: TransactionItemType): JSX.Element | null {
  return item.description?.length ? (
    <Text variant="sm-normal" color={colors.neutral.darkMist}>
      {' '}
      | {item.description}
    </Text>
  ) : null;
}

export function shouldShowItemIcon(item: TransactionItemType): JSX.Element {
  return item.iconUrl ? (
    <View testID="remoteImage">
      <RemoteImage uri={item.iconUrl} height={32} width={32} />
    </View>
  ) : (
    <View testID="itemIconContainer" style={styles.itemIconContainer} />
  );
}
