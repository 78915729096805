import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

interface LitTitleProps {
  t: (key: string) => string;
}

const ListTitle: React.FC<LitTitleProps> = ({ t }) => (
  <View style={styles.container}>
    <Text variant="md-bold">{t('loan.pilih')}</Text>
  </View>
);

export default ListTitle;
