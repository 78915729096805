import { fetchSubProductsOrNominals } from '@api/useQueryCalls';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { View, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showListOfProducts } from './subProductsUtils';
import { shouldShowAccountInput, shouldShowPhoneSelection, shouldShowTitle, showErrorOnAPI } from '../BPUtils';
import { logPageBack, logPageLaunch } from '../GGNominals/analyticsNominals';

function GGSubProducts(props) {
  const { bpPhone, setLoading, setInitialData, selectedBPAccount, validatedReferralCode } = getStore();
  const { mobileNumber } = bpPhone;
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  const { accountTypeId } = selectedBPAccount;
  const { route } = props;
  const { params } = route;

  const { productConfig, productApiData, source } = params;
  const { productId, productName } = productConfig;

  const { isLoading, isSuccess, isError, error, refetch, data } = fetchSubProductsOrNominals({
    billAccountNumber: mobileNumber,
    productId,
    accountTypeId,
  });
  const [subProducts, setSubProductsData] = useState([]);
  const [productNominals, setProductNominals] = useState([]);
  const [selectedProductItem, setSelectedProductItem] = useState('');
  useEffect(() => {
    navigation.setOptions({ title: productName });
  }, [productName]);
  useEffect(() => {
    logPageLaunch(productConfig, source, validatedReferralCode);
    refetch();
    return () => {
      logPageBack(productConfig, source, validatedReferralCode);
    };
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess && data) {
      const keys = Object.keys(data);
      setSubProductsData(keys);
      setProductNominals(data);
    }
  }, [isSuccess, data]);

  return (
    <View style={appStyles.safeAreaView}>
      {shouldShowPhoneSelection(params)}
      {shouldShowAccountInput({ productConfig, productApiData })}
      {shouldShowTitle(productConfig)}
      {showListOfProducts({
        subProducts,
        productNominals,
        selectedProductItem,
        setSelectedProductItem,
        productId,
        productConfig,
        productApiData,
        source,
      })}
    </View>
  );
}

GGSubProducts.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      productConfig: PropTypes.shape({
        productId: PropTypes.number,
        productName: PropTypes.string,
        noOfColumns: PropTypes.number,
        hasPhoneChange: PropTypes.bool,
      }),
      productApiData: PropTypes.shape({}),
    }),
  }),
};
GGSubProducts.defaultProps = {
  route: {
    params: {
      source: '',
      productConfig: {
        productId: '',
        productName: '',
        hasPhoneChange: false,
        noOfColumns: 1,
      },
      productApiData: {},
    },
  },
};
export default GGSubProducts;
