import MICon from '@expo/vector-icons/MaterialIcons';
import { Text } from 'native-base';
import React from 'react';
import { Pressable } from 'react-native';
import styles from './style';

interface LihatPICGatewayProps {
  show?: boolean;
  title?: string;
  onLihatBenifitHandler?: () => void;
}

const LihatPICGateway: React.FC<LihatPICGatewayProps> = ({
  show = false,
  title = '',
  onLihatBenifitHandler = () => {},
}) => {
  if (!show) return null;

  return (
    <Pressable onPress={onLihatBenifitHandler} style={styles.lihatButtonContainer} testID="lihat-button">
      <Text variant="sm-bold" style={styles.lihatTextStyle}>
        {title}
      </Text>
      <MICon name="arrow-forward" style={styles.iconStyle} />
    </Pressable>
  );
};

export default LihatPICGateway;
