import colors from '@theme/colors';
import { boldFont } from '@theme/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnVerifyCreate: {
    backgroundColor: colors.neutral.cotton,
    marginHorizontal: 16,
    marginVertical: 10,
  },
  createPinContainer: {
    backgroundColor: colors.neutral.cotton,
    flex: 1,
  },
  errorTextStyles: {
    color: colors.error.rose,
    textAlign: 'center',
  },
  instructionStyles: {
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 8,
    flexDirection: 'row',
    marginBottom: 16,
    marginHorizontal: 16,
    marginTop: 24,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  otpDefault: {
    borderColor: colors.disabled.smoke,
    borderRadius: 12,
    borderWidth: 1,
    color: colors.secondary.orchid,
    fontFamily: boldFont,
    fontSize: 20,
    height: 42,
    marginRight: 11,
    width: 42,
  },
  otpFailed: { borderColor: colors.error.rose },
  otpInputContainer: {
    height: 50,
    marginHorizontal: 16,
    marginVertical: 10,
  },
  pinImageContainer: {
    alignSelf: 'center',
    contentFit: 'contain',
    height: 43,
    marginTop: 32,
    width: 36,
  },
  pinTitleContainer: {
    alignSelf: 'center',
    marginVertical: 24,
  },
});
export default styles;
