import { queryClient } from '@api/clientProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonActions, NavigationProp } from '@react-navigation/native';
import { FEATURE_FLAGS } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { SentryHelper } from '@utils/performance/SentryHelper';
import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';
import { getUserBanksFromQuery, serverError } from '@utils/utils';

// Interfaces for function parameters
interface QueryClient {
  clear: () => void;
}

interface UserProfileData {
  employees?: Record<string, any>[];
  users?: Record<string, any>[];
}

interface UserBankData {
  length: number;
}

interface SecurityPinData {
  invalidAttempts: number;
}

interface FeatureFlags {
  [key: string]: { show: boolean };
}

// Function to force logout the user
export async function forceLogout(
  isSuccess: boolean,
  LogoutData: any,
  navigation: NavigationProp<any>,
  setInitialData: () => void,
  queryClient: QueryClient,
): Promise<void> {
  if (isSuccess && LogoutData) {
    queryClient.clear();
    await secureStore.clearAll();
    tokenManager.clearAccessToken();
    tokenManager.clearRefreshToken();
    await AsyncStorage.clear();
    AmplitudeHelper.clearUserProperties();

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Login' }],
      }),
    );

    setInitialData();
    SentryHelper.clearUser();
  }
}

// Function to logout user on error
export function logoutOnError(
  isError: boolean,
  error: string[],
  navigation: NavigationProp<any>,
  setInitialData: () => void,
  toast: any,
  t: (key: string) => string,
): void {
  (async () => {
    if (isError && error) {
      if (error[0] === 'GG-GEN-05') {
        await forceLogout(true, {}, navigation, setInitialData, queryClient);
      }
      serverError(error, toast, t, navigation, setInitialData);
    }
  })();
}

// Function to update user properties in Amplitude
export function onGetUserPropertiesCallBack(setEmployeeProfile: (data: any) => void) {
  return (data: UserProfileData) => {
    const userData = { ...data?.employees?.[0], ...data?.users?.[0] };
    AmplitudeHelper.setUserProperties({
      [AMPLITUDE_CONSTANTS.userProperties.gender]: userData?.gender,
    });
    setEmployeeProfile(userData);
  };
}

// Function to update user bank data
export function onGetUserBankCallBack(dataList: UserBankData, setEmployeeBank: (data: any) => void): void {
  if (dataList.length > 0) {
    const dispatchData = getUserBanksFromQuery(dataList);
    setEmployeeBank(dispatchData);
  }
}

// Function to update user bank data (Correcting the missing function)
export function onGetUserBankCallBank(dataList: UserBankData, setEmployeeBank: (data: any) => void): void {
  if (dataList.length > 0) {
    const dispatchData = getUserBanksFromQuery(dataList);
    setEmployeeBank(dispatchData);
  }
}

// Function to check security pin callback
export function onCheckSecurityPinCallBack(setShowPinRegister: (data: any) => void) {
  return (data: SecurityPinData[]) => {
    if (data?.length > 0) {
      setShowPinRegister({
        showPinRegister: false,
        isActive: true,
        invalidAttempts: data[0].invalidAttempts,
        verifiedPin: false,
      });
    } else {
      setShowPinRegister({ showPinRegister: true, isActive: false, invalidAttempts: 0, verifiedPin: false });
    }
  };
}

// Function to handle security pin errors
export function onCheckSecurityErrorCallBack(setShowPinRegister: (data: any) => void) {
  return () => {
    setShowPinRegister({ showPinRegister: false, isActive: false, invalidAttempts: 0, verifiedPin: false });
  };
}

// Function to check if features should run when screen is focused
export function shouldRunOnFocused(
  isFeatureAvailable: FeatureFlags,
  getUserBanks: () => void,
  checkSecurityPin: () => void,
): void {
  if (isFeatureAvailable[FEATURE_FLAGS.MUL_BANK_ACC]?.show) getUserBanks();
  checkSecurityPin();
}
