import ArrowDown from '@atoms/ArrowDown';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import { AntDesign } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import styles from './styles';

interface Account {
  name: string;
  id: number;
  accountTypeId: number;
  balance: number;
  iconLink: string;
  isDisabled?: boolean;
  disabledReason?: string;
}

interface BPReqAccountWithBalanceProps {
  selectedEligibleAccount?: Account;
  item: Account;
  showChecked?: boolean;
}

const shouldShowCurrencyForPoints = (item: Account) => item?.accountTypeId !== 1;

const shouldShowCheckedOrDown = (
  item: Account,
  selectedEligibleAccount?: Account,
  showChecked?: boolean,
): JSX.Element => {
  if (item === selectedEligibleAccount && showChecked) {
    return (
      <AntDesign
        testID={`selectedAccount${selectedEligibleAccount?.id ?? ''}`}
        name="checkcircle"
        size={24}
        color={colors.secondary.orchid}
      />
    );
  }
  if (item === selectedEligibleAccount && !showChecked && Platform.OS !== 'web') {
    return <ArrowDown />;
  }
  return <View />;
};

const BPReqAccountWithBalance: React.FC<BPReqAccountWithBalanceProps> = ({
  selectedEligibleAccount = {} as Account,
  item,
  showChecked = false,
}) => {
  const { t } = useTranslation('common');
  const customStyles = showChecked ? { flex: 1 } : {};
  const enabledDisabledColor = item?.isDisabled ? colors.neutral.darkMist : colors.neutral.charcoal;

  return (
    <View testID={`dd_${item?.id}`} style={styles.accountSelectorContainer(item, selectedEligibleAccount, showChecked)}>
      <RemoteImage uri={item?.iconLink} width={18} height={18} />
      <View flex={1}>
        <View style={styles.accountSelectorRow}>
          <Text
            testID={`dropSelected_${item?.accountTypeId}`}
            variant="sm-bold"
            flex={1}
            style={styles.txtAccountName}
            color={enabledDisabledColor}>
            {item?.name}
          </Text>
          <View style={[styles.txtAccountBalance(item, selectedEligibleAccount), customStyles]}>
            <Text variant="sm-bold" color={enabledDisabledColor}>
              {' | '}
            </Text>
            <TextCurrency
              variant="sm-bold"
              testID="selectedTxtCurrency"
              amountCurrency={item?.balance}
              showCurrencySymbol={shouldShowCurrencyForPoints(item)}
              color={enabledDisabledColor}
            />
          </View>
          {shouldShowCheckedOrDown(item, selectedEligibleAccount, showChecked)}
        </View>
        {item?.isDisabled ? (
          <Text variant="xsm-bold" style={appStyles.marginLeft(9)} color={colors.error.rose}>
            {t(`errorCodes.${item.disabledReason}`)}
          </Text>
        ) : null}
      </View>
    </View>
  );
};

export default BPReqAccountWithBalance;
