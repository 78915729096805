import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

interface DescriptionRowProps {
  desc: string;
}

const DescriptionRow: React.FC<DescriptionRowProps> = ({ desc }) => (
  <View style={styles.outerRow}>
    <Text variant="md-bold" color={colors.neutral.darkMist} onPress={() => {}} style={styles.bulletStyle}>
      {'\u2022'}
    </Text>
    <Text variant="md-normal" color={colors.neutral.darkMist} onPress={() => {}} style={styles.bulletStyleTwo}>
      {desc}
    </Text>
  </View>
);

export default DescriptionRow;
