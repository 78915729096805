import logEyeIconClicked from '@atoms/BankRow/analyticsBankRow';
import { Ionicons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { showStatusViewForBank } from '@utils/utils';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Bank {
  bankName: string;
}

interface BankItem {
  id: number;
  accountNumber: string;
  beneficiaryName: string;
  nickname: string;
  status: string;
  bank: Bank;
}

interface BankRowStatusPendingProps {
  maskData: boolean;
  maskIcon: string;
  setMaskIcon: (icon: string) => void;
  setMaskData: (mask: boolean) => void;
  fromTransfer: boolean;
  source: string;
  item: BankItem;
}

export function onPressBankIcon(
  source: string,
  maskData: boolean,
  item: BankItem,
  setMaskIcon: (icon: string) => void,
  setMaskData: (mask: boolean) => void,
) {
  return () => {
    logEyeIconClicked(source, maskData, item);
    setMaskIcon(setMaskIconValue(maskData));
    setMaskData(!maskData);
  };
}

const BankRowStatusPending: React.FC<BankRowStatusPendingProps> = ({
  item,
  maskData,
  maskIcon,
  setMaskIcon,
  setMaskData,
  fromTransfer,
  source,
}) => {
  const { t } = useTranslation('common');
  const { bankForTransfer } = getStore();

  return (
    <View style={appStyles.marginVertical(8)}>
      <Text variant="md-bold" style={{ color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }}>
        {item?.bank?.bankName}
      </Text>
      <View flexDirection="row" alignItems="center">
        <Text
          variant="sm-normal"
          style={
            fromTransfer
              ? [appStyles.flex1, { color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }]
              : null
          }>
          {fromTransfer ? item?.accountNumber : getMaskedOrUnmaskedData(maskData, item?.accountNumber)}
        </Text>
        {!fromTransfer && (
          <Ionicons
            name={maskIcon}
            size={18}
            color="black"
            testID="btnMaskUnmaskImage"
            style={[appStyles.marginLeft(8), appStyles.flex1]}
            onPress={onPressBankIcon(source, maskData, item, setMaskIcon, setMaskData)}
          />
        )}
        <View flex={1} alignItems="flex-end">
          {showStatusViewForBank(item, t, fromTransfer, bankForTransfer)}
        </View>
      </View>
      <View flexDirection="row" alignItems="center">
        {item?.nickname && (
          <View flexDirection="row" alignItems="center">
            <Text
              color={colors.neutral.darkMist}
              variant="md-bold"
              style={{ color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }}>
              {item?.nickname}
            </Text>
            <View
              backgroundColor={fromTransfer ? colors.disabled.smoke : colors.neutral.darkMist}
              style={appStyles.dotStyles}
            />
          </View>
        )}

        <Text
          color={colors.neutral.darkMist}
          variant="sm-normal"
          style={{ color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }}>
          {item?.beneficiaryName}
        </Text>
      </View>
    </View>
  );
};

export default BankRowStatusPending;
