import { MaterialIcons } from '@expo/vector-icons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

interface InfoAppStatus {
  backgroundColor: string;
  textDesc: string;
  iconName: string;
  iconColor: string;
  showLink?: boolean;
}

export const INFO_APP_STATUS: Record<string, InfoAppStatus> = {
  APPROVED: {
    backgroundColor: colors.success.softCactus,
    textDesc: 'INFO_STATUS_DESC_1',
    iconName: 'check-circle-outline',
    iconColor: colors.success.darkCactus,
    showLink: true,
  },
  DISBURSED: {
    backgroundColor: colors.success.softCactus,
    textDesc: 'INFO_STATUS_DESC_1',
    iconName: 'check-circle-outline',
    iconColor: colors.success.darkCactus,
    showLink: true,
  },
  PENDING_APPROVAL: {
    backgroundColor: colors.warning.softSunflower,
    textDesc: 'INFO_STATUS_DESC_2',
    iconName: 'query-builder',
    iconColor: colors.warning.darkSunflower,
  },
  default: {
    backgroundColor: colors.secondary.softOrchid,
    textDesc: 'INFO_STATUS_DESC_3',
    iconName: 'info-outline',
    iconColor: colors.secondary.orchid,
  },
};

interface InfoBarProps {
  appStatus?: string;
  show?: boolean;
  onPressLink?: () => void;
  t: (key: string) => string,
}

const InfoBar: React.FC<InfoBarProps> = ({ appStatus = 'default', show = true, onPressLink, t }) => {
  if (!show) return null;

  const statusInfo = INFO_APP_STATUS[appStatus] || INFO_APP_STATUS.default;

  return (
    <View style={[styles.container, { backgroundColor: statusInfo.backgroundColor }]}>
      <MaterialIcons
        size={scale(24)}
        style={[styles.iconContainer, { color: statusInfo.iconColor }]}
        name={statusInfo.iconName}
      />
      <View style={styles.appStatusStyle}>
        <Text variant="xsm-bold" style={styles.textStyle}>
          {t(`loan.${statusInfo.textDesc}`)}
          {statusInfo.showLink && (
            <Text testID="linkText" onPress={onPressLink} variant="sm-bold" style={styles.linkTextStyle}>
              {`
              ${t('loan.riwayat')}`}
            </Text>
          )}
        </Text>
      </View>
    </View>
  );
};

export default InfoBar;
