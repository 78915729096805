import BankRowStatusApproved from '@atoms/BankRowStatusApproved';
import BankRowStatusPending from '@atoms/BankRowStatusPending';
import BankRowStatusRejected from '@atoms/BankRowStatusRejected';
import { maskIconInit } from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import React, { useState } from 'react';

interface Bank {
  bankName: string;
}

interface BankItem {
  id: number;
  accountNumber: string;
  beneficiaryName: string;
  nickname: string;
  status: string;
  bank: Bank;
}

interface BankRowStatusProps {
  item?: BankItem;
  maskData?: boolean;
  maskIcon?: string;
  setMaskIcon?: (icon: string) => void;
  setMaskData?: (mask: boolean) => void;
  fromTransfer?: boolean;
  setBankForTransfer?: (item: BankItem) => void; // Added this to match expected props
  source?: string;
}

const statusComponents: Record<string, React.FC<BankRowStatusProps>> = {
  PENDING: BankRowStatusPending as React.FC<BankRowStatusProps>,
  APPROVED: BankRowStatusApproved as React.FC<BankRowStatusProps>,
  REJECTED: BankRowStatusRejected as React.FC<BankRowStatusProps>,
};

const BankRowStatus: React.FC<BankRowStatusProps> = props => {
  const { item } = props;
  const StatusComponent = item?.status ? statusComponents[item.status.toUpperCase()] : null;

  return StatusComponent ? <StatusComponent {...props} /> : null;
};


interface BankRowProps {
  item?: BankItem;
  fromTransfer?: boolean;
  setBankForTransfer?: (item: BankItem) => void;
  source?: string;
}

const BankRow: React.FC<BankRowProps> = ({
  item = {
    id: 0,
    accountNumber: '',
    beneficiaryName: '',
    nickname: '',
    status: '',
    bank: { bankName: '' },
  },
  fromTransfer = true,
  setBankForTransfer = () => { },
  source = '',
}) => {
  const [maskData, setMaskData] = useState<boolean>(true);
  const [maskIcon, setMaskIcon] = useState<string>(maskIconInit(maskData));

  if (fromTransfer && item?.status?.toUpperCase() === 'REJECTED') {
    return null;
  }

  return (
    <BankRowStatus
      item={item}
      maskData={maskData}
      setMaskData={setMaskData}
      maskIcon={maskIcon}
      setMaskIcon={setMaskIcon}
      fromTransfer={fromTransfer}
      setBankForTransfer={setBankForTransfer}
      source={source}
    />
  );
};

export default BankRow;
