import LoanMetaItem from '@atoms/Loan/LoanMetaItem';
import React, { useCallback } from 'react';
import { FlatList } from 'react-native';
import styles from './style';

export const LOAN_META_INFO: string[] = [
  'loanMetaInfo1',
  'loanMetaInfo2',
  'loanMetaInfo3',
  'loanMetaInfo4',
  'loanMetaInfo5',
  'loanMetaInfo6',
];

export const keyExtract = (item: string, index: number) => index.toString();

type LoanMetaListProps = {
  t: (key: string) => string,
};

const LoanMetaList: React.FC<LoanMetaListProps> = ({ t }) => {
  const renderItem = useCallback(
    ({ item, index }: { item: string; index: number }) => <LoanMetaItem key={index} item={item} index={index} t={t} />,
    [t],
  );

  return (
    <FlatList
      testID="loanMetalist"
      contentContainerStyle={styles.contentContainerStyle}
      data={LOAN_META_INFO}
      keyExtractor={keyExtract}
      renderItem={renderItem}
    />
  );
};

export default LoanMetaList;
