import TNCitem from '@atoms/TNCitem';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import React from 'react';
import styles from './style';

type DataItem = {
  item: string;
  refereeAmt: number;
  referrerAmtts: number;
};
type AttentionBoardProps = {
  show?: boolean,
  dataList: DataItem[];
  t: (key: string) => string,
};

const AttentionBoard: React.FC<AttentionBoardProps> = ({ show = false, dataList = [], t }) => {
  if (!show) return null;

  return (
    <View style={styles.outerContainer}>
      <View style={styles.row}>
        <View style={styles.iconStyle}>{SVG_ICONS.loan.verifiedUser}</View>
        <View style={styles.textContainer}>
          <Text variant="md-bold">{t('loan.harapa')}</Text>
          <Text variant="sm-normal">{t('loan.baca')}</Text>
        </View>
      </View>

      <View style={styles.listContainer}>
        {dataList.map((o, i) => (
          <TNCitem key={o} item={o} index={i} />
        ))}
      </View>
    </View>
  );
};

export default AttentionBoard;
