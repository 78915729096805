import BankRow from '@atoms/BankRow';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { View, Divider } from 'native-base';
import React, { useEffect } from 'react';
import { FlatList } from 'react-native';

const itemSeperator = () => <Divider color={colors.neutral.mist} height="1px" />;

interface Bank {
  id?: number;
  bankName?: string;
  accountNumber?: string;
  [key: string]: any;
}

interface BankFlatListDataProps {
  bankList?: Bank[];
  setBankForTransfer?: (bank: Bank) => void;
  fromTransfer?: boolean;
  source?: string;
}

const BankFlatListData: React.FC<BankFlatListDataProps> = ({
  bankList = [{}],
  setBankForTransfer = () => {},
  fromTransfer = true,
  source = '',
}) => {
  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_page;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
    });
  }, [source]);

  const bankListRow = ({ item }: { item: Bank }) => (
    <BankRow item={item} fromTransfer={fromTransfer} setBankForTransfer={setBankForTransfer} source={source} />
  );

  return (
    <View>
      <View style={appStyles.marginHorizontal(16)}>
        <FlatList
          testID="fltLstBanks"
          style={appStyles.marginVertical(8)}
          ItemSeparatorComponent={itemSeperator}
          data={bankList}
          renderItem={bankListRow}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => item.id?.toString() ?? index.toString()} // ✅ Added keyExtractor for optimization
        />
      </View>
    </View>
  );
};

export default BankFlatListData;
