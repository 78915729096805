import {
  apiBanksEnrolled,
  apiBillPayment,
  apiForAutoDisburse,
  apiMarketingBanners,
  apiMe,
  apiNumberHistory,
  apiPaymentCycles,
  apiReasons,
  apiReferralValidation,
  apiSubProductsOrNominals,
  apiTopProducts,
  apiTransactionHistory,
  apiTransactionSummary,
  apiToUploadImage,
  apiToDeleteImage,
  apiToEditTransactions,
  apiForPromocode,
  apiForVerifyKTP,
  apiForGoldRequest,
  apiForTxnGold,
  apiForGoldConfirmation,
  apiForGoldTransactionCancel,
  apiForPicEmployeeReferralList,
  apiForShareEmployeeReferral,
  apiProfile,
  apiBankList,
  getLoan,
  markUserNotified,
  getLoanProductsList,
  loanApply,
  apiGetPhoneList,
  getSalaryInfo,
  getLogs,
  checkTimeout,
  getAppUpdates,
  getRoles,
  getDepartmentNames,
  getSecurityPinInvalidAttempts,
  getFeatureFlags,
  getEarnedReferrals,
  getReferralTNC,
  setOptInForAutoDisburse,
  getUserProperties,
  updateDeparmentsDesignationApi,
} from '@api/apiCall';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

export function fetchReasonsUseQuery(payload) {
  return useQuery(
    {
      queryKey: ['reasons_api', payload],
      queryFn: () => apiReasons(payload),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchMeUseQuery() {
  return useQuery(
    {
      queryKey: ['me_api'],
      queryFn: () => apiMe(),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchPaymentCycles(payload) {
  return useQuery(
    {
      queryKey: ['paycycles_api', payload],
      queryFn: () => apiPaymentCycles(payload),
      enabled: true,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, retry: false, refetchOnWindowFocus: false },
  );
}

export function fetchTransactionHistory(payload) {
  const PAGE_LIMIT = 10;
  return useInfiniteQuery(
    ['transactions_history', payload],
    ({ pageParam = 0 }) => apiTransactionHistory({ ...payload, skip: pageParam, limit: PAGE_LIMIT }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage && lastPage.length === PAGE_LIMIT) {
          return PAGE_LIMIT * allPages.length;
        }
        return false;
      },
      staleTime: 5 * 60 * 1000,
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export function fetchTxnSummary(payload) {
  return useQuery(
    {
      queryKey: ['transaction_summary', payload],
      queryFn: () => apiTransactionSummary(payload),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, retry: false, refetchOnWindowFocus: false },
  );
}

export function fetchMarketingBannerUseQuery() {
  return useQuery(
    {
      queryKey: ['marketingBanner_api'],
      queryFn: () => apiMarketingBanners(),
      enabled: true,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchBillPayments(payload) {
  return useQuery(
    {
      queryKey: ['billpayment_api', payload],
      queryFn: () => apiBillPayment(payload),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchTopProducts() {
  return useQuery(
    {
      queryKey: ['topProducts_api'],
      queryFn: () => apiTopProducts(),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function validateReferral(referralCode) {
  return useQuery(
    {
      queryKey: ['validateReferral', referralCode],
      queryFn: () => apiReferralValidation(referralCode),
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export function fetchSubProductsOrNominals(payload) {
  return useQuery(
    {
      queryKey: ['subProductsOrNominals', payload],
      queryFn: () => apiSubProductsOrNominals(payload),
      enabled: true,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchAccountNumberHistory(payload) {
  return useQuery(
    {
      queryKey: ['accountNumberInput', payload],
      queryFn: () => apiNumberHistory(payload),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function uploadImages(payload, onSuccessCallback) {
  const imageUri = payload?.file?.assets?.[0]?.uri;
  const imagePayload = {
    uri: imageUri,
    type: 'image/png',
    name: 'Bukti.png',
  };
  const formData = new FormData();
  formData.append('imageData', imagePayload?.uri ? imagePayload : payload?.file); // mobile  :  web
  formData.append('transactionId', payload?.transactionId);

  return useMutation(
    {
      queryKey: ['uploadImages', formData],
      mutationFn: () => apiToUploadImage(formData),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function deleteImage(payload, onSuccessCallback) {
  return useMutation(
    {
      queryKey: ['delete_image', payload],
      mutationFn: () => apiToDeleteImage(payload),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function shareEmployeeReferral() {
  return useMutation({
    mutationFn: payload => apiForShareEmployeeReferral(payload),
  });
}

export function fetchProfile() {
  return useQuery(
    {
      queryKey: ['apiProfile'],
      queryFn: () => apiProfile(),
      enabled: false,
    },

    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchBanksEnrolled() {
  return useQuery(
    {
      queryKey: ['banksEnrolled'],
      queryFn: () => apiBanksEnrolled(),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function editTransction(payload, onSuccessCallback) {
  return useQuery(
    {
      queryKey: ['edit_transactions', payload],
      queryFn: () => apiToEditTransactions(payload),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchAutoDisburse(payload) {
  return useQuery(
    {
      queryKey: ['autoDisburse', payload],
      queryFn: () => apiForAutoDisburse(payload),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchPromoCode(payload) {
  return useQuery(
    {
      queryKey: ['promocode_gold', payload],
      queryFn: () => apiForPromocode(payload),
      enabled: true,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function verifyKTP(payload, onSuccessCallback) {
  return useMutation(
    {
      queryKey: ['verify_gold_ktp', payload],
      mutationFn: () => apiForVerifyKTP(payload),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchEmployeeRegList(payload, onSuccessCallback) {
  return useQuery(
    {
      queryKey: ['pic_employee_referralList', payload],
      queryFn: () => apiForPicEmployeeReferralList(payload),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function goldRequest(payload, onSuccessCallback) {
  return useMutation(
    {
      queryKey: ['gold_request', payload],
      mutationFn: () => apiForGoldRequest(payload),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function goldTxnRequest(payload, onSuccessCallback) {
  return useMutation(
    {
      queryKey: ['gold_txn_request', payload],
      mutationFn: data => apiForTxnGold(data),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function goldOConfirmation(payload, onSuccessCallback) {
  return useMutation(
    {
      queryKey: ['gold_confirmation', payload],
      mutationFn: data => apiForGoldConfirmation(data),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function cancelGoldTrasansaction(payload, onSuccessCallback) {
  return useMutation(
    {
      queryKey: ['cancel_gold_transaction', payload],
      mutationFn: data => apiForGoldTransactionCancel(data),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export function fetchAllBanks() {
  return useQuery({ queryKey: ['allBanks'], queryFn: () => apiBankList({ skip: 0, limit: 200 }) });
}

export function fetchAllPhones() {
  return useQuery(
    {
      queryKey: ['allPhones'],
      queryFn: () => apiGetPhoneList(),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

export const fetchLoanDetails = onSuccessCallback =>
  useQuery(
    { queryKey: ['fetch_loan_details'], queryFn: () => getLoan(), enabled: false, onSuccess: onSuccessCallback },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const updateUserNotified = (onSuccessCallback = () => {}) =>
  useMutation(
    {
      queryKey: ['update_loan'],
      mutationFn: payload => markUserNotified(payload),
      onSuccess: onSuccessCallback,
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchLoanProduucts = onSuccessCallback =>
  useQuery(
    {
      queryKey: ['fetch_loan_products'],
      queryFn: () => getLoanProductsList(),
      enabled: true,
      onSuccess: onSuccessCallback,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const applyForLoan = onSuccessCallback =>
  useMutation(
    {
      queryKey: ['apply_loan'],
      mutationFn: payload => loanApply(payload),
      enabled: false,
      onSuccess: onSuccessCallback,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchSalaryInfo = () =>
  useQuery(
    {
      queryKey: ['fetch_salary_info'],
      queryFn: () => getSalaryInfo(),
      enabled: true,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchLogs = () =>
  useQuery(
    {
      queryKey: ['fetch_logs'],
      queryFn: () => getLogs(),
      enabled: false,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchTimeout = () =>
  useQuery({
    queryKey: ['checkTimeout'],
    queryFn: () => checkTimeout(),
    enabled: false,
  });

export function fetchAppUpdates() {
  return useQuery({
    queryKey: ['appUpdates'],
    queryFn: () => getAppUpdates(),
    enabled: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
}
export const fetchRoles = ({ onSuccess }) =>
  useQuery(
    {
      queryKey: ['department_roles'],
      queryFn: () => getRoles(),
      enabled: false,
      onSuccess,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

export const fetchDepartments = ({ onSuccess }) =>
  useQuery(
    {
      queryKey: ['department_names'],
      queryFn: () => getDepartmentNames(),
      enabled: false,
      onSuccess,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchPinInvalidAttempts = () =>
  useQuery({ queryKey: ['pin_invalid_attempts'], queryFn: () => getSecurityPinInvalidAttempts(), enabled: false });

export const fetchFeatureFlags = () => useQuery({ queryKey: ['feature_flags'], queryFn: () => getFeatureFlags() });
export const fetchEarnedReferrals = ({ onSuccess }) =>
  useQuery(
    {
      queryKey: ['earned_referrals'],
      queryFn: () => getEarnedReferrals(),
      enabled: true,
      onSuccess,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchReferralTNC = ({ onSuccess }) =>
  useQuery(
    {
      queryKey: ['referrals_tnc'],
      queryFn: () => getReferralTNC(),
      enabled: true,
      onSuccess,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const setAutoDisburseOptIn = ({ onSuccess }) =>
  useMutation(
    {
      queryKey: ['update_auto_disburse'],
      mutationFn: () => setOptInForAutoDisburse({ autoDisburse: true }),
      enabled: true,
      onSuccess,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const fetchUserProperties = ({ onSuccess }) =>
  useQuery(
    {
      queryKey: ['user_properties'],
      queryFn: () => getUserProperties(),
      enabled: false,
      onSuccess,
    },
    { enabled: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );

export const updateDeparmentsDesignation = ({ onSuccess, payload }) =>
  useMutation(
    {
      queryKey: ['update_auto_disburse'],
      mutationFn: () => updateDeparmentsDesignationApi(payload),
      enabled: true,
      onSuccess,
    },
    { enabled: true, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
