import ReferralEarnPoins from '@atoms/ReferralEarnPoins';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ReferralInfoUserCardProps {
  amount?: number;
}

const ReferralInfoUserCard: React.FC<ReferralInfoUserCardProps> = ({ amount = 0 }) => {
  const { t } = useTranslation('common');

  return (
    <View
      backgroundColor={colors.warning.softSunflower}
      style={[
        appStyles.flex1,
        appStyles.borderRadius(12),
        appStyles.paddingHorizontal(10),
        appStyles.paddingVertical(12),
        appStyles.marginRight(8),
      ]}>
      <ReferralEarnPoins amount={amount} />
      <Text
        color={colors.neutral.charcoal}
        variant="lg-bold"
        alignSelf="center"
        textAlign="center"
        style={appStyles.marginTop(3)}>
        {t('referral.txtDoYou')}
      </Text>
      <Text color={colors.neutral.charcoal} variant="sm-normal" alignSelf="center" textAlign="center">
        {t('referral.txtSuccess')}
      </Text>
    </View>
  );
};

export default ReferralInfoUserCard;
