import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

interface TagBadgeProps {
  title?: string;
  show?: boolean;
}

const TagBadge: React.FC<TagBadgeProps> = ({ title = '', show = false }) => {
  if (!show) return null;

  return (
    <View style={styles.hrBadgeContainer}>
      <Text variant="xsm-bold" style={styles.hrTitleStyle}>
        {title}
      </Text>
    </View>
  );
};

export default TagBadge;
