import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Button } from 'native-base';
import React from 'react';
import { Platform, SafeAreaView, StatusBar, ViewStyle } from 'react-native';
import styles from './styles';

interface GenericErrorProps {
  t: (key: string) => string;
  buttonStyles?: ViewStyle;
  error?: {
    message: string;
    stack?: string;
  };
  restartApp: (error?: { message: string; stack?: string }) => void;
}

const GenericErrorComponent: React.FC<GenericErrorProps> = ({
  t,
  buttonStyles = {},
  error = { message: 'Generic Error', stack: 'Generic Stack' },
  restartApp,
}) => (
  <SafeAreaView style={appStyles.safeAreaView}>
    <StatusBar translucent backgroundColor={colors.secondary.orchid} />
    <View style={styles.errorContainer}>
      <View style={[appStyles.marginHorizontal(16), styles.errorData]}>
        <View alignSelf="center">{SVG_ICONS.fixingImage}</View>
        <Text testID="txtOopsError" textAlign="center" variant="xl-bold">
          {t('common.oopsError')}
        </Text>
        <Text testID="txtOopsDescription" style={appStyles.marginTop(8)} textAlign="center" variant="lg-normal">
          {t('common.oopsErrorDescription')}
        </Text>
      </View>
    </View>
    <Button testID="btnRestartApp" onPress={() => restartApp(error)} style={buttonStyles}>
      {Platform.OS === 'web' ? t('common.oopsRetryWeb') : t('common.oopsRetryApp')}
    </Button>
  </SafeAreaView>
);

export default GenericErrorComponent;
