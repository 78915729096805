import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { Checkbox, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logCheckUncheck from './analyticsTnCBox';
import styles from './styles';

interface BPTncCheckBoxProps {
  tncChecked?: boolean;
  setTncOpen?: (value: boolean) => void;
  setTncChecked?: (value: boolean) => void;
  amplitudeAccountName?: string;
  transaction_category_id?: string;
  source?: string;
}

const BPTncCheckBox: React.FC<BPTncCheckBoxProps> = ({
  tncChecked = false,
  setTncOpen = () => {},
  setTncChecked = () => {},
  amplitudeAccountName = '',
  transaction_category_id = '',
  source = '',
}) => {
  const { validatedReferralCode } = getStore();
  const { t } = useTranslation('common');

  return (
    <TouchableOpacity style={styles.checkBoxContainer}>
      <Checkbox
        testID="cbTnC"
        value="test"
        accessibilityLabel="This is a dummy checkbox"
        isChecked={tncChecked}
        _checked={{
          backgroundColor: colors.primary.carnation,
          borderColor: colors.primary.carnation,
        }}
        onChange={() => {
          logCheckUncheck(transaction_category_id, amplitudeAccountName, tncChecked, validatedReferralCode, source);
          setTncChecked(!tncChecked);
        }}
      />
      <Text
        testID="txtTncText"
        variant="sm-bold"
        style={styles.tncText}
        color={colors.secondary.orchid}
        onPress={() => {
          setTncOpen(true);
        }}>
        {t('reqTxSheet.confirmTnC')}
      </Text>
    </TouchableOpacity>
  );
};

export default BPTncCheckBox;
