import { numberWithDots } from '@utils/utils';
import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

type RowProps = {
  title: string,
  value: string | number;
};

export const Row: React.FC<RowProps> = ({ title, value }) => (
  <View style={styles.rowStyle}>
    <Text style={styles.titleText}>{title}</Text>
    <Text style={styles.textAdj}>{value}</Text>
  </View>
);

type TransaksiDetailsProps = {
  fee: number;
  bankName: string;
  benificiaryName: string;
  bankAccountNo: number;
  show: boolean;
  t: (key: string) => string;
};

const TransaksiDetails: React.FC<TransaksiDetailsProps> = ({
  fee,
  bankName,
  benificiaryName,
  bankAccountNo,
  show,
  t,
}) => {
  if (!show) return null;

  return (
    <View style={styles.outerContainer}>
      <Text style={styles.mainTitleStyle}>{t('loan.transaksiDetail')}</Text>

      <View style={styles.innerContainer}>
        <Row title={t('loan.transaksiDetail2')} value={`Rp${numberWithDots(fee)}`} />
        <Row title={t('loan.transaksiDetail3')} value={bankName} />
        <Row title={t('loan.transaksiDetail4')} value={bankAccountNo} />
        <Row title={t('loan.transaksiDetail5')} value={benificiaryName} />
      </View>
    </View>
  );
};

export default TransaksiDetails;
