import { apiToEditTransactions } from '@api/apiCall';
import CommonTextInput from '@atoms/CommonTextInput';
// import InfoTextComponent from '@atoms/InfoTextComponent';
import useBeforeUnload from '@customHooks/useBeforeUnload';
import EditReasons from '@molecules/EditReasons';
import DetailsComponent from '@molecules/EditTransactions/DetailsComponent';
// import ImageUPloadComponent from '@molecules/EditTransactions/ImageUploadComponent';
import TokenContainer from '@molecules/EditTransactions/TokenContainer';
import VoucherCode from '@molecules/EditTransactions/VoucherCode';
import { useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
// import { scale } from '@utils/normalize';
import { Button, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { logDetailPage, logFocus } from './analyticsEditTransaction';
import styles from './styles';

export function shouldRenderTitle(title) {
  return (
    <View style={styles.titleStyle}>
      <Text variant="sm-bold" color={colors.neutral.darkMist}>
        {title}
      </Text>
    </View>
  );
}

export function getChangedField(oldReason, newReason, oldDesc, newDesc) {
  if (!newReason) return false;
  if (oldReason !== newReason && oldDesc !== newDesc) return 'notes,reason';
  if (oldReason !== newReason) return 'reason';
  if (oldDesc !== newDesc) return 'notes';
  return false;
}

export function handleUnload(navigation) {
  return () => navigation.goBack();
}

export function getDescTitleText(accountTypeId, t) {
  if (accountTypeId === 4) return t('transactionDetailsScreen.uangdescTitle');
  return t('transactionDetailsScreen.keterangan');
}

export function getParameterChanged(isImageAdded, detailsData, withdrawalReason, description) {
  return isImageAdded && detailsData?.description?.length === 0
    ? 'image_added'
    : getChangedField(detailsData?.transReason, withdrawalReason?.reason, detailsData?.description, description);
}

export function onSimpanPressHandler(
  editTransactioCall,
  detailsData,
  withdrawalReason,
  description,
  selectedTxnAccount,
) {
  return () => {
    editTransactioCall({
      transactionHistoryId: detailsData.transHistoryId,
      transactionReasonId: withdrawalReason?.id,
      description,
      accountNumber: selectedTxnAccount?.id ?? 0,
    });
  };
}

export function onDeleteHandler(setImageAdded) {
  return () => setImageAdded(false);
}

export function onUploadHandler(setImageAdded) {
  return () => setImageAdded(true);
}

export function onSucessHandler(
  isSuccess,
  detailsData,
  isImageAdded,
  withdrawalReason,
  description,
  navigation,
  setEditRefresh,
) {
  if (isSuccess) {
    const e = AMPLITUDE_CONSTANTS.TransactionPage.transaction_edit_saved;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
      [e.attributes.transaction_id]: detailsData?.transactionId ?? null,
      [e.attributes.parameter_changed]: getParameterChanged(isImageAdded, detailsData, withdrawalReason, description),
      [e.attributes.old_reason_input]: detailsData?.transReason ?? null,
      [e.attributes.new_reason_input]: withdrawalReason?.reason ?? '',
      [e.attributes.old_description_input]: detailsData?.description ?? null,
      [e.attributes.new_description_input]: description ?? null,
    });
    navigation.goBack();
    setEditRefresh(true);
  }
}

function GGEditTransaction() {
  const route = useRoute();
  const {
    params: { detailsConfig = [], detailsData, accountTypeId },
  } = route;

  const [isImageAdded] = useState(false);

  const { withdrawalReason, setWithdrawalReason, selectedTxnAccount, setEditRefresh } = getStore();

  const { t } = useTranslation('common');
  const navigation = useNavigation();

  const [description, setDescription] = useState(detailsData.description);

  const {
    isLoading: editTrasnactionloading = false,
    isSuccess,
    mutate: editTransactioCall,
  } = useMutation({
    mutationFn: payload => apiToEditTransactions(payload),
  });

  useEffect(() => {
    onSucessHandler(isSuccess, detailsData, isImageAdded, withdrawalReason, description, navigation, setEditRefresh);
  }, [isSuccess, setEditRefresh]);

  useEffect(() => () => setWithdrawalReason(null), []);

  useEffect(() => {
    logDetailPage(detailsData);
  }, []);

  useBeforeUnload(handleUnload(navigation));

  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <ScrollView contentContainerStyle={styles.scrollViewContainerStyle}>
        <EditReasons
          disabled={detailsData.canEditReason === false}
          iconUrl={detailsData.iconUrl}
          transReason={detailsData?.transReason}
          transReasonId={detailsData?.transactionReasonId}
          position={detailsData?.position}
        />
        <TokenContainer title={detailsData?.displayName} token={detailsData.token} />
        <VoucherCode redeemUrl={detailsData.redeemUrl} />
        {shouldRenderTitle(t('transactionDetailsScreen.detailTransaksi'))}
        <DetailsComponent detailsData={detailsData} detailsConfig={detailsConfig} />
        {shouldRenderTitle(getDescTitleText(accountTypeId, t))}
        <CommonTextInput
          style={styles.textAreaStyles}
          // variant="sm-normal"
          multiline
          value={description}
          onChangeText={setDescription}
          onFocus={logFocus}
          placeholder={t('transactionDetailsScreen.masukkanKeterangan')}
          placeholderTextColor={colors.neutral.darkMist}
        />
      </ScrollView>
      <View style={[appStyles.bottomView, styles.buttonViewStyle]}>
        <Button
          isLoading={editTrasnactionloading}
          isDisabled={
            !getChangedField(detailsData?.transReason, withdrawalReason?.reason, detailsData?.description, description)
          }
          marginX="16px"
          textAlign="center"
          testID="editTransactionBtn"
          onPress={onSimpanPressHandler(
            editTransactioCall,
            detailsData,
            withdrawalReason,
            description,
            selectedTxnAccount,
          )}>
          <Text testID="txtWhatsapp" variant="sm-bold" color={colors.neutral.cotton}>
            {t('transactionDetailsScreen.simpan')}
          </Text>
        </Button>
      </View>
    </SafeAreaView>
  );
}

export default GGEditTransaction;
