import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  phoneBookStyles: {
    alignSelf: 'center',
    contentFit: 'contain',
    height: 172,
    marginTop: 32,
    width: '100%',
  },
});

export default styles;
