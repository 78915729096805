import Info from '@expo/vector-icons/MaterialIcons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { ViewStyle, TextStyle } from 'react-native';
import styles from './style';

interface InfoTextComponentProps {
  infoText: string;
  iconColor?: string;
  containerStyle?: ViewStyle;
  customTextStyle?: TextStyle;
  iconContainer?: ViewStyle;
  size?: number;
  iconName?: string;
  show?: boolean;
  textBucket?: string[];
  variant?: string;
}

const InfoTextComponent: React.FC<InfoTextComponentProps> = ({
  infoText,
  iconColor,
  containerStyle = {},
  customTextStyle = {},
  iconContainer = {},
  size = 20,
  iconName = 'info-outline',
  show = false,
  textBucket = [],
  variant = 'xsm-bold',
}) => {
  function getText(txtArr: string[]) {
    return (
      <View flex={1}>
        {txtArr.map((t, i) => (
          <View key={i} style={appStyles.flexRow}>
            <Text variant={variant} style={styles.textContainer}>{`${i + 1}.`}</Text>
            <Text variant={variant} style={[styles.txtAdj, customTextStyle]}>
              {t}
            </Text>
          </View>
        ))}
      </View>
    );
  }

  function shouldShowText(txt: string, txtArr: string[]) {
    if (txtArr.length > 0) return getText(txtArr);
    return (
      <Text testID="txtAtm" numberOfLines={3} variant={variant} style={customTextStyle}>
        {txt}
      </Text>
    );
  }

  if (!show) return null;

  return (
    <View style={[styles.mainContainer, containerStyle]}>
      <View style={iconContainer}>
        <Info name={iconName} color={iconColor || colors.secondary.orchid} size={size} />
      </View>
      {shouldShowText(infoText, textBucket)}
    </View>
  );
};

export default InfoTextComponent;
