import IconRightArrow from '@atoms/IconRightArrow';
import TextCurrency from '@atoms/TextCurrency';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logPointsRow from './analyticsProfilePointsRow';
import styles from './styles';

interface Account {
  accountTypeId?: number;
  balance: number;
}

interface Store {
  accounts: Account[];
  setSelectedBPAccounts: (account: Account) => void;
  employeeId: string;
}

export function onPresPointsRowHandler(
  pointsAccount: Account,
  setSelectedBPAccounts: (account: Account) => void,
  navigation: any,
  employeeId: string,
) {
  return () => {
    if (pointsAccount?.accountTypeId) setSelectedBPAccounts(pointsAccount);
    logPointsRow(employeeId, pointsAccount);
    navigation.navigate(navigationConstants.PembayaranTab);
  };
}

const ProfilePointsRow: React.FC = () => {
  const { accounts, setSelectedBPAccounts, employeeId } = getStore() as Store;
  const { t } = useTranslation('common');
  const navigation = useNavigation();

  const pointsAccount = accounts.find(item => item.accountTypeId === 1) || { balance: 0 };

  const handlePress = useCallback(
    onPresPointsRowHandler(pointsAccount, setSelectedBPAccounts, navigation, employeeId),
    [pointsAccount, setSelectedBPAccounts, navigation, employeeId],
  );

  return (
    <TouchableOpacity testID="btnPointsRow" onPress={handlePress}>
      <View flexDirection="row" marginY="8px" alignItems="center">
        <View
          backgroundColor={colors.warning.softSunflower}
          paddingX="4px"
          paddingBottom="4.5px"
          paddingTop="3.5px"
          borderRadius="12px"
          justifyContent="center">
          {SVG_ICONS.coin}
        </View>
        <Text
          testID="txtProfilePoints"
          marginLeft="8px"
          flex={1}
          variant="md-normal"
          fontWeight="400"
          alignSelf="center">
          {t('profile.myPoints')}
        </Text>

        <View style={styles.coinContainer}>
          {SVG_ICONS.coin}
          <TextCurrency
            showCurrencySymbol={false}
            amountCurrency={pointsAccount.balance}
            variant="xsm-bold"
            style={styles.textStyle}
          />
        </View>

        <IconRightArrow />
      </View>
    </TouchableOpacity>
  );
};

export default ProfilePointsRow;
