import BPSubProdNominals from '@molecules/BPSubProdNominals';
import BPSubProductTile from '@molecules/BPSubProductTile';
import { View } from 'native-base';
import React from 'react';
import styles from './styles';

export function shouldShowNominalsOnSelection(subProdNominalsProps) {
  const { productNominals, selectedProductItem, productId, productConfig, productApiData, source } =
    subProdNominalsProps;
  if (selectedProductItem !== '') {
    return (
      <BPSubProdNominals
        productNominals={productNominals}
        selectedProductItem={selectedProductItem}
        productId={productId}
        productConfig={productConfig}
        productApiData={productApiData}
        source={source}
      />
    );
  }
  return null;
}

export function showListOfProducts(listOfProdProps) {
  const {
    subProducts,
    productNominals,
    selectedProductItem,
    setSelectedProductItem,
    productId,
    productConfig,
    productApiData,
    source,
  } = listOfProdProps;

  return (
    <View>
      <View style={styles.subProductContainer}>
        {subProducts.map(productItem => {
          const { iconLink } = productNominals[productItem][0];

          if (iconLink) {
            return (
              <BPSubProductTile
                key={productItem}
                selectedProductItem={selectedProductItem}
                productItem={productItem}
                iconLink={productNominals[productItem][0].iconLink}
                setSelectedProductItem={setSelectedProductItem}
                productId={productId}
                productConfig={productConfig}
                source={source}
              />
            );
          }
          return null;
        })}
      </View>
      {shouldShowNominalsOnSelection({
        productNominals,
        selectedProductItem,
        productId,
        productConfig,
        productApiData,
        source,
      })}
    </View>
  );
}
