import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WithdrawalAdminFeeProps {
  fee?: number;
}

const WithdrawalAdminFee: React.FC<WithdrawalAdminFeeProps> = ({ fee = 0 }) => {
  const { t } = useTranslation('common');

  return (
    <View style={appStyles.amountContainer}>
      <Text testID="lblAdminFee" flex={1} variant="lg-normal">
        {t('withdrawalPage.adminFee')}
      </Text>
      <TextCurrency variant="sm-bold" color={colors.error.rose} amountCurrency={fee} showCurrencySymbol />
    </View>
  );
};

export default WithdrawalAdminFee;
