import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WithdrawalAmountProps {
  requestedAmount?: number;
}

const WithdrawalAmount: React.FC<WithdrawalAmountProps> = ({ requestedAmount = 0 }) => {
  const { t } = useTranslation('common');
  return (
    <View style={appStyles.amountContainer}>
      <Text testID="lblAmountOfWithdrawal" variant="lg-normal" flex={1}>
        {t('withdrawalPage.amountOfWithdrawal')}
      </Text>
      <TextCurrency variant="sm-bold" amountCurrency={requestedAmount} showCurrencySymbol />
    </View>
  );
};

export default WithdrawalAmount;
