import RightArrow from '@atoms/RightArrow';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

function BPDropdownWithRegions(props) {
  const { t } = useTranslation('common');
  const { productConfig, regionsData, setSelectedPDAMRegion } = props;
  const { accountInput } = productConfig;
  const [selectedRegion, setSelectedRegion] = useState({ id: -1, name: '' });
  const { fetchRegions } = accountInput;
  const navigation = useNavigation();

  useEffect(() => {
    if (selectedRegion.id !== -1) {
      setSelectedPDAMRegion(selectedRegion);
    }
  }, [selectedRegion]);

  if (fetchRegions) {
    return (
      <View>
        <Text style={styles.txtSelectRegionHeader} variant="sm-normal" color={colors.neutral.darkMist}>
          {t('bp.selectRegionHeader')}
        </Text>
        <TouchableOpacity
          testID="btnRegionsNavigate"
          onPress={() => {
            navigation.navigate(navigationConstants.GG_REGIONS, { regionsData, setSelectedRegion });
          }}
          style={styles.btnRegionStyles}>
          <Text
            variant="md-normal"
            color={selectedRegion.id === -1 ? colors.neutral.darkMist : colors.neutral.charcoal}
            flex={1}>
            {selectedRegion.id === -1 ? t('bp.selectRegion') : selectedRegion.name}
          </Text>
          <RightArrow />
        </TouchableOpacity>
      </View>
    );
  }
  return null;
}

BPDropdownWithRegions.propTypes = {
  setSelectedPDAMRegion: PropTypes.func,
  productConfig: PropTypes.shape({
    accountInput: PropTypes.shape({
      fetchRegions: PropTypes.bool,
    }),
  }),
  regionsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
};

BPDropdownWithRegions.defaultProps = {
  setSelectedPDAMRegion: () => {},
  productConfig: {
    accountInput: {
      fetchRegions: false,
    },
  },
  regionsData: [{ id: 1, title: '' }],
};

export default React.memo(BPDropdownWithRegions);
