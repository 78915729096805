import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

interface PointsAccount {
  balance: number;
}

function logPointsRow(employeeId: string, pointsAccount: PointsAccount): void {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.UserProfile.poin_clicked.name, {
    employee_id: employeeId,
    poin_balance: pointsAccount?.balance || 0, // ✅ Ensure balance is always a number
    source: AMPLITUDE_EVENT_SOURCES.profile_page,
  });
}

export default logPointsRow;
