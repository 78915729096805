import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './styles';

interface ToastErrorProps {
  data?: string;
}

const ToastError: React.FC<ToastErrorProps> = ({ data = '' }) => (
  <View style={styles.toastContainer}>
    <AntDesign name="warning" size={scale(24)} color={colors.neutral.cotton} />
    <Text testID="txtSuccess" variant="sm-bold" color={colors.neutral.cotton} style={styles.textStyle}>
      {data}
    </Text>
  </View>
);

export default ToastError;
