import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  reasonGrid: {
    alignItems: 'center',
    alignSelf: 'center',
    columnGap: 14,
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  reasonParent: {
    paddingBottom: 150,
    alignContent: 'center',
    flex: 1,
    marginHorizontal: -10,
  },
});
export default styles;
