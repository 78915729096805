import { numberWithDots } from '@utils/utils';
import * as yup from 'yup';

export const bankTransferSchema = (minWithdrawalAmount, balance, t, currencySymbol) =>
  yup.object().shape({
    bankAccountNumber: yup.object().required(t('addBank.selectBank')),
    amount: yup
      .number()
      .typeError(t('addBank.numValidation'))
      .required(t('addBank.requiredTransfer'))
      .lessThan(balance + 1, t('addBank.notEnoughBalance'))
      .moreThan(minWithdrawalAmount - 1, `Minimum ${currencySymbol}${numberWithDots(minWithdrawalAmount)}`),
  });

export const bankAddSchema = t =>
  yup.object().shape({
    selectedBank: yup.object().required(t('addBank.selectBank')),
    accountNumber: yup.string().matches(/^\d+$/, t('addBank.numericString')).required(t('addBank.numericString')),
    accountName: yup
      .string()
      .matches(/^[a-zA-Z\s]*[a-zA-Z]+$/, t('addBank.accountNameError'))
      .required(t('addBank.reqName')),
  });
