import { fetchPinInvalidAttempts } from '@api/useQueryCalls';
import IconRightArrow from '@atoms/IconRightArrow';
import SecurityPinBanner from '@atoms/SecurityPinBanner';
import SkeletonComponent from '@atoms/SkeletonComponent';
import ProfileActivePinRow from '@molecules/ProfileActivePinRow';
import ProfileRejectedPinRow from '@molecules/ProfileRejectedPinRow';
import navigationConstants from '@navigation/navigationConstants';
import { onCheckSecurityErrorCallBack, onCheckSecurityPinCallBack } from '@organisms/ProfileTab/profileUtils';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Divider } from 'native-base';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logSecurityPin from './analyticsProfileSecurityPin';

interface PinRegister {
  isActive: boolean;
  showPinRegister?: boolean;
}

interface ProfileSecurityPinProps {
  showSecurityPin?: boolean;
  route?: string;
}

export function onPressSecurityPin(navigation: any) {
  return () => {
    logSecurityPin();
    navigation.navigate(navigationConstants.GG_CREATE_PIN, { type: 'create', source: 'pin_creation' });
  };
}

export function getSecurityPinStatusView(pinRegister: PinRegister, childRef: React.RefObject<any>) {
  if (pinRegister.isActive) {
    return <ProfileActivePinRow childRef={childRef} />;
  }
  return <ProfileRejectedPinRow childRef={childRef} onPressSecurityPin={onPressSecurityPin} />;
}

export function onParentPressHandler(childRef: React.RefObject<any>) {
  return () => {
    if (childRef?.current) {
      if (childRef.current.click) {
        childRef.current.click(); // Web click
      }
      if (childRef.current.props?.onPress) {
        childRef.current.props.onPress(); // Mobile press
      }
    }
  };
}

const ProfileSecurityPin: React.FC<ProfileSecurityPinProps> = ({ showSecurityPin = false, route = '' }) => {
  const { t } = useTranslation('common');
  const { pinRegister, setShowPinRegister } = getStore() as { pinRegister: PinRegister; setShowPinRegister: (val: boolean) => void };
  const childRef = useRef<any>(null);
  const PadLock = SVG_ICONS.securityPin.padLock;

  const { isSuccess, data, error, refetch, isLoading } = fetchPinInvalidAttempts() as {
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    data?: any; // If there's a strict type for `fetchPinInvalidAttempts`, replace `any` with it
    error?: any;
    refetch: () => void;
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      onCheckSecurityPinCallBack(setShowPinRegister)(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (error) {
      onCheckSecurityErrorCallBack(setShowPinRegister)();
    }
  }, [error]);

  if (isLoading) {
    return <SkeletonComponent />;
  }

  const onParentPress = onParentPressHandler(childRef);

  if (showSecurityPin) {
    if (pinRegister.showPinRegister && route === 'Home') {
      return <SecurityPinBanner />;
    }
    if (route !== 'Home') {
      return (
        <>
          <View style={appStyles.marginLeft(16)} marginY="16px">
            <Text testID="txtProfileInformation" variant="md-bold">
              {t('profile.securityHeader')}
            </Text>

            <TouchableOpacity onPress={onParentPress}>
              <View marginY="8px" flexDirection="row" alignItems="center">
                <View paddingX="4px" paddingY="4px" background={colors.secondary.softOrchid} borderRadius="12px">
                  <PadLock />
                </View>
                <Text testID="txtPinStatus" marginLeft="8px" variant="md-normal" flex={1}>
                  {t('profile.pinsText')}
                </Text>
                <View flexDirection="row" alignItems="center">
                  {getSecurityPinStatusView(pinRegister, childRef)}
                  <IconRightArrow />
                </View>
              </View>
            </TouchableOpacity>
          </View>
          <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
        </>
      );
    }
    return null;
  }
  return null;
};

export default ProfileSecurityPin;
