import colors from '@theme/colors';
import { numberWithDots } from '@utils/utils';
import { Text } from 'native-base';
import React, { ReactNode } from 'react';
import { View, Linking } from 'react-native';
import styles from './style';

export const HYPERLINK_TEXT = 'Baca syarat dan ketentuan di sini';
export const PWA_REFFERAL_LINK = 'https://gajigesa.com/gajigesa-referral';

export const displayText = (item: string, referrerAmt: number, refereeAmt: number): string => {
  let text = item.replace('$$referrerRewardAmount$$', numberWithDots(referrerAmt));
  text = text.replace('$$refereeRewardAmount$$', numberWithDots(refereeAmt));
  return text;
};

interface TNCitemProps {
  item: string;
  index: number;
  refereeAmt: number;
  referrerAmt: number;
  component?: ReactNode;
}

const TNCitem: React.FC<TNCitemProps> = ({ item, index, refereeAmt, referrerAmt, component = null }) => {
  const shouldRenderText = () => {
    if (component) return component;

    return (
      <Text variant="md-normal" style={styles.termsTextStyle}>
        {displayText(item, referrerAmt, refereeAmt)}
        {item === HYPERLINK_TEXT && (
          <Text
            testID="hyperLink"
            variant="md-normal"
            style={{ color: colors.secondary.orchid }}
            onPress={() => Linking.openURL(PWA_REFFERAL_LINK)}>
            {' '}
            {PWA_REFFERAL_LINK}
          </Text>
        )}
      </Text>
    );
  };

  return (
    <View style={styles.termsRow}>
      <View style={styles.termsIconContainer}>
        <View style={styles.circlePlaceholder}>
          <Text variant="sm-bold" style={styles.numericText}>
            {index + 1}
          </Text>
        </View>
      </View>
      <View style={styles.termsTextContainer}>{shouldRenderText()}</View>
    </View>
  );
};

export default TNCitem;
