import RightArrow from '@atoms/RightArrow';
import appStyles from '@root/appStyles';
import {
  colorForSelectedBank,
  shouldShowAccountNumberWhenBankSelected,
  shouldShowBankNameWhenBankSelected,
  shouldShowBeneficiaryNameWhenBankSelected,
  shouldShowSeperatorWhenBankSelected,
  styleForSelectedBank,
  variantForSelectedBank,
} from '@screens/GGBankTransfer/bankTransferUtils';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

interface BankSelectionButtonProps {
  navigateToBankListOnPress: () => void;
  bankAccountNumber: Record<string, any>;
}

const BankSelectionButton: React.FC<BankSelectionButtonProps> = ({
  navigateToBankListOnPress = () => {},
  bankAccountNumber = {},
}) => (
  <View
    borderColor={colors.neutral.darkMist}
    style={[
      appStyles.borderRadius(8),
      appStyles.borderWidth(1),
      appStyles.paddingHorizontal(16),
      appStyles.paddingVertical(13),
    ]}>
    <TouchableOpacity testID="btnBankPress" onPress={navigateToBankListOnPress} style={styles.bankPressStyles}>
      <Text
        testID="txtBankOrText"
        noOfLines={1}
        variant={variantForSelectedBank(bankAccountNumber)}
        style={styleForSelectedBank(bankAccountNumber)}
        color={colorForSelectedBank(bankAccountNumber)}>
        {shouldShowBankNameWhenBankSelected(bankAccountNumber)}
      </Text>
      {shouldShowSeperatorWhenBankSelected(bankAccountNumber)}
      {shouldShowAccountNumberWhenBankSelected(bankAccountNumber)}
      <RightArrow />
    </TouchableOpacity>
    {shouldShowBeneficiaryNameWhenBankSelected(bankAccountNumber)}
  </View>
);

export default BankSelectionButton;
