import TextCurrency from '@atoms/TextCurrency';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styles from './style';

interface MetaTagProps {
  showTag: boolean;
}

export const MetaTag: React.FC<MetaTagProps> = ({ showTag }) => {
  const { t } = useTranslation('common');

  if (!showTag) return null;

  return (
    <View style={styles.metaContainer}>
      <Text variant="sm-bold" style={styles.metaTagStyle}>
        {t('loan.menunggu')}
      </Text>
    </View>
  );
};
interface LoanDetailCardProps {
  principalAmt?: number;
  recievable?: number;
  tenure?: number;
  installAmt?: string;
  showTag?: boolean;
}

const LoanDetailCard: React.FC<LoanDetailCardProps> = ({
  principalAmt = 0,
  recievable = 0,
  tenure = 0,
  installAmt = '0',
  showTag = false,
}) => {
  const { t } = useTranslation('common');

  return (
    <View style={styles.outerContainer}>
      <View style={styles.principleRow}>
        <View style={styles.row}>
          {SVG_ICONS.loan.cashTwo}
          <TextCurrency
            amountCurrency={principalAmt}
            variant="xl-bold"
            style={styles.principleAmtStyle}
            showCurrencySymbol
          />
        </View>
        <MetaTag showTag={showTag} />
      </View>

      <View style={styles.metaRow}>
        <View>
          <Text variant="sm-normal" style={styles.metaTitleStyle}>
            {t('loan.jumlah')}
          </Text>
          <TextCurrency amountCurrency={recievable} variant="sm-bold" style={styles.metaInfoStyle} showCurrencySymbol />
        </View>
        <View style={styles.containerAdj}>
          <Text variant="sm-normal" style={styles.metaTitleStyle}>
            {t('loan.cicilan')}
          </Text>
          <Text variant="sm-bold" style={styles.tenureTxtStyle}>
            {`${tenure}x`}
          </Text>
        </View>
        <View style={styles.containerAdj}>
          <Text variant="sm-normal" style={styles.metaTitleStyle}>
            {t('loan.ciciBulan')}
          </Text>

          <TextCurrency
            amountCurrency={parseInt(installAmt, 10)}
            variant="sm-bold"
            style={styles.installAmtTxt}
            showCurrencySymbol
          />
        </View>
      </View>
    </View>
  );
};

export default LoanDetailCard;
