import { MaterialIcons } from '@expo/vector-icons';
import { scale } from '@utils/normalize.js';
import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, ViewStyle } from 'react-native';
import Collapsible from 'react-native-collapsible';
import styles from './style';
import Item from '../Item/index';

interface FAQItem {
  icon: string | object;
  desc: string;
}

interface FAQSectionProps {
  title: string;
  data: FAQItem[];
  collapse: boolean;
  handleCollapse: () => void;
  containerStyle?: ViewStyle;
}

const FAQSection: React.FC<FAQSectionProps> = ({ title, data, collapse, handleCollapse, containerStyle = {} }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Pressable testID="title" style={[styles.container, containerStyle]} onPress={handleCollapse}>
        <Text variant="md-bold">{title}</Text>
        <View style={styles.iconContainerStyle}>
          <MaterialIcons name={collapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'} size={scale(24)} />
        </View>
      </Pressable>
      <Collapsible collapsed={collapse} style={styles.collapseContainerStyle}>
        {data.map(e => (
          <Item key={e.desc} icon={e.icon} title={e.desc} t={t} />
        ))}
      </Collapsible>
    </>
  );
};

export default FAQSection;
