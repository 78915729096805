import { analyticsTimeStampFormat } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getNowInFormat } from '@utils/dateUtils';

interface LogPressParams {
  transactionCategoryId: string | number;
  accountName: string;
  validatedReferralCode: string;
  source: string;
}

export function logRejectPress({
  transactionCategoryId,
  accountName,
  validatedReferralCode,
  source,
}: LogPressParams): void {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.name, {
    [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.transaction_category_id]: transactionCategoryId,
    [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.timestamp]: getNowInFormat(analyticsTimeStampFormat),
    [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.dynamic_account_name]: accountName,
    [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.referral_code]: validatedReferralCode,
  });
}

export function logAcceptPress({
  transactionCategoryId,
  accountName,
  validatedReferralCode,
  source,
}: LogPressParams): void {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedWithdrawalTerms.name, {
    [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedWithdrawalTerms.attributes.transaction_category_id]:
      transactionCategoryId,
    [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedWithdrawalTerms.attributes.timestamp]:
      getNowInFormat(analyticsTimeStampFormat),
    [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedWithdrawalTerms.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedWithdrawalTerms.attributes.dynamic_account_name]: accountName,
    [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedWithdrawalTerms.attributes.referral_code]: validatedReferralCode,
  });
}
