import { Text } from 'native-base';
import React, { ReactNode } from 'react';
import { View } from 'react-native';
import styles from './style';

interface ItemProps {
  icon: ReactNode;
  title: string;
  t: (key: string) => string;
}

const Item: React.FC<ItemProps> = ({ icon, title, t }) => (
  <View style={styles.itemContainer}>
    <View>{icon}</View>
    <Text variant="xsm-normal" style={styles.itemStyle}>
      {t(`salaryInfo.${title}`)}
    </Text>
  </View>
);

export default Item;
