import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { formatPhoneNumber } from '@utils/utils';
import { Button, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface BtnSMSProps {
  isLoadingSMS?: boolean;
  isDisabled?: boolean;
  phoneNumber?: string;
  setIsLoadingSMS?: (loading: boolean) => void;
  setIsLoadingWhatsapp?: (loading: boolean) => void;
  performLogin?: (data: { otpType: string; mobileNumber: string }) => void;
}

const BtnSMS: React.FC<BtnSMSProps> = ({
  isLoadingSMS = false,
  isDisabled = false,
  phoneNumber = '',
  setIsLoadingSMS = () => {},
  setIsLoadingWhatsapp = () => {},
  performLogin = () => {},
}) => {
  const { t } = useTranslation('common');
  const { tenantSettings } = getStore();
  const { countryCode } = tenantSettings;

  return (
    <Button
      isLoading={isLoadingSMS}
      isDisabled={isDisabled}
      flex={1}
      variant="solid"
      testID="btnSMS"
      sentry-label="btnSMS"
      style={styles.btnSmsStyles}
      onPress={() => {
        setIsLoadingSMS(true);
        setIsLoadingWhatsapp(false);
        performLogin({
          otpType: 'SMS',
          mobileNumber: formatPhoneNumber(phoneNumber, countryCode),
        });
      }}>
      <Text testID="txtSms" variant="sm-bold" color={colors.neutral.cotton}>
        {t('login.sms')}
      </Text>
    </Button>
  );
};

export default BtnSMS;
