import SearchListHeaderComponent from '@atoms/SearchListHeaderComponent';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Divider, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, FlatList } from 'react-native';

interface Bank {
  id: number,
  bankName: string;
}

interface ListWithFilterProps {
  allBanks: Bank[];
  setSelectedBank: (bank: Bank) => void;
  source: string;
}

const ListWithFilter: React.FC<ListWithFilterProps> = ({ allBanks = [], setSelectedBank, source = '' }) => {
  const navigation = useNavigation();
  const [searchData, setSearchData] = useState<Bank[]>(allBanks);
  const [filteredData, setFilteredData] = useState<Bank[]>(allBanks);
  const { t } = useTranslation('common');

  useEffect(() => {
    setFilteredData(allBanks);
    setSearchData(allBanks);
  }, [allBanks]);

  const renderItem = ({ item }: { item: Bank }) => (
    <TouchableOpacity
      style={[appStyles.paddingHorizontal(16), appStyles.paddingVertical(16)]}
      onPress={() => {
        setSelectedBank(item);
        navigation.pop();
      }}>
      <Text variant="lg-normal">{item.bankName}</Text>
    </TouchableOpacity>
  );

  const itemSeperatorComponent = () => <Divider color={colors.neutral.mist} />;

  return (
    <FlatList
      data={filteredData}
      stickyHeaderIndices={[0]}
      ListHeaderComponent={
        <SearchListHeaderComponent
          searchKey="bankName"
          searchData={searchData}
          setFilteredData={setFilteredData}
          searchPlaceholder={t('addBank.findBank')}
          logSearchDone={() => {
            const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_name_searched;
            const { name, attributes } = e;
            AmplitudeHelper.logEvent(name, { [attributes.source]: source });
          }}
        />
      }
      renderItem={renderItem}
      ItemSeparatorComponent={itemSeperatorComponent}
      keyExtractor={item => item?.id?.toString()}
    />
  );
};

export default ListWithFilter;
