const apiConfig = {
  login: {
    name: 'login',
    urlEndpoint: 'auth/login/otp?',
    httpMethod: 'GET',
    hasQueryProps: true,
    isAuthenticated: false,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  otp: {
    name: 'otp',
    urlEndpoint: 'auth/login/otp',
    httpMethod: 'POST',
    isAuthenticated: false,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  me: {
    name: 'me',
    urlEndpoint: 'employee/me',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 5,
  },
  logout: {
    name: 'logout',
    urlEndpoint: 'auth/logout',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  reasons: {
    name: 'reasons',
    urlEndpoint: 'list/txn-reasons/{accountNumber}?',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  paymentCycles: {
    name: 'paymentCycles',
    urlEndpoint: 'payment-cycles?',
    httpMethod: 'GET',
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  transactionSummary: {
    name: 'transactionSummary',
    urlEndpoint: 'transactions/{accountNumber}/summary?',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  transactionsHistory: {
    name: 'transactionsHistory',
    urlEndpoint: 'transactions/{accountNumber}?',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  marketingBanner: {
    name: 'marketingBanner',
    urlEndpoint: 'marketing/banners',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  billPayment: {
    name: 'billPayment',
    urlEndpoint: 'products/list?',
    httpMethod: 'GET',
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  topProducts: {
    name: 'topProducts',
    urlEndpoint: 'products/top',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  referralValidation: {
    name: 'referralValidation',
    urlEndpoint: 'referral/validatecode?',
    httpMethod: 'GET',
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  subProductsOrNominals: {
    name: 'subProductsOrNominals',
    urlEndpoint: 'products/view/{productId}?',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  numberHistory: {
    name: 'numberHistory',
    urlEndpoint: 'bill-payment/number-history/{productPrefix}',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  bpRequest: {
    name: 'bpRequest',
    urlEndpoint: 'bill-payment/request',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  imageUpload: {
    name: 'imageUpload',
    urlEndpoint: 'file',
    httpMethod: 'POST',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  bpConfirm: {
    name: 'bpConfirm',
    urlEndpoint: 'bill-payment/confirm',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  banksEnrolled: {
    name: 'banksEnrolled',
    urlEndpoint: 'employee/beneficiary/list',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  withdrawRequest: {
    name: 'withdrawRequest',
    urlEndpoint: 'withdrawal/request',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  withdrawConfirm: {
    name: 'withdrawConfirm',
    urlEndpoint: 'withdrawal/confirm',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  deletImage: {
    name: 'deletImage',
    urlEndpoint: 'file/{documentId}',
    httpMethod: 'DELETE',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },

  autoDisburseDetails: {
    name: 'autoDisburseDetails',
    urlEndpoint: 'auto-disburse/detail/{employeeId}',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  updateTransaction: {
    name: 'updateTransaction',
    urlEndpoint: 'transactions/{transactionHistoryId}',
    httpMethod: 'PATCH',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  getPromoCode: {
    name: 'getPromoCode',
    urlEndpoint: 'partners/gold/getPromoCodeStatus/{employeeId}',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  verifyKTP: {
    name: 'verifyKTP',
    urlEndpoint: 'user/ktp-registration',
    httpMethod: 'POST',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  goldRequest: {
    name: 'goldRequest',
    urlEndpoint: 'partners/gold/request',
    httpMethod: 'POST',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  goldTxnRequest: {
    name: 'goldTxnRequest',
    urlEndpoint: 'gold-transaction/get/{invoiceNo}',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  goldConfirmation: {
    name: 'goldConfirmation',
    urlEndpoint: 'gold-transaction/payment',
    httpMethod: 'POST',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  cancelGoldTransaction: {
    name: 'cancelGoldTransaction',
    urlEndpoint: 'gold-transaction/cancel/{txnID}',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },

  securityPinSimpleRegistration: {
    name: 'securityPinSimpleRegistration',
    urlEndpoint: 'security-pin/simple-registration',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  securityPinVerify: {
    name: 'securityPinVerify',
    urlEndpoint: 'security-pin/verify',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  picEmployeeReferralList: {
    name: 'picEmployeeReferralList',
    urlEndpoint: 'referral/influencer/list-employees/{employeeId}',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  employerShareReferral: {
    name: 'employerShareReferral',
    urlEndpoint: 'employee/refer',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  profile: {
    name: 'profile',
    urlEndpoint: 'employee/profile',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  allBanks: {
    name: 'allBanks',
    urlEndpoint: 'list/bank?',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  addBank: {
    name: 'addBank',
    urlEndpoint: 'employee/beneficiary/add',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  deleteBank: {
    name: 'deleteBank',
    urlEndpoint: 'employee/beneficiary/delete/{beneficiaryId}',
    httpMethod: 'DELETE',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  loan: {
    name: 'loan',
    urlEndpoint: 'loan',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  userNotified: {
    name: 'userNotified',
    urlEndpoint: 'loan/userNotified/{applicationId}',
    httpMethod: 'PATCH',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  loanProducts: {
    name: 'loanProducts',
    urlEndpoint: 'loan/products',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  loanApply: {
    name: 'loanApply',
    urlEndpoint: 'loan/apply',
    httpMethod: 'POST',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  phoneNumberList: {
    name: 'phoneNumberList',
    urlEndpoint: 'employee/phone-numbers/list',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  addPhone: {
    name: 'addPhone',
    urlEndpoint: 'employee/phone-numbers/add',
    httpMethod: 'POST',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  deletePhone: {
    name: 'deletePhone',
    urlEndpoint: 'employee/phone-numbers/delete/{phoneNumberId}',
    httpMethod: 'DELETE',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  salaryInfo: {
    name: 'salaryInfo',
    urlEndpoint: 'salary-profile/info',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  logInfo: {
    name: 'logInfo',
    urlEndpoint: 'employee/amplitude-event-context',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  testTimeOut: {
    name: 'testTimeOut',
    urlEndpoint: 'timeout-test?sync=true&time=1000',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  appUpdate: {
    name: 'appUpdate',
    urlEndpoint: 'app-update',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  fetchRoles: {
    name: 'fetchRoles',
    urlEndpoint: 'department-roles',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  fetchDepartments: {
    name: 'fetchDepartments',
    urlEndpoint: 'department-names',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  pinInvalidAttempts: {
    name: 'pinInvalidAttempts',
    urlEndpoint: 'security-pin/invalid-attempts',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  fetchEarnedReferrals: {
    name: 'fetchEarnedReferrals',
    urlEndpoint: 'referral/earned-referral-points',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  fetchReferralTNC: {
    name: 'fetchReferralTNC',
    urlEndpoint: 'referral/referral-tnc',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  featureFlag: {
    name: 'featureFlag',
    urlEndpoint: 'features',
    httpMethod: 'GET',
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  setOptIn: {
    name: 'setOptIn',
    urlEndpoint: 'update-auto-disburse',
    httpMethod: 'PATCH',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  userProperties: {
    name: 'userProperties',
    urlEndpoint: 'employee-user',
    httpMethod: 'GET',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  updateDepartments: {
    name: 'updateDepartments',
    urlEndpoint: 'update-department-designation',
    httpMethod: 'PATCH',
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
  },
};

/**
 *
 * @param {string} name for the api name
 * @param {string} baseUrl for api base URL
 * @returns {string} as concatenated string with base url
 */
apiConfig.getApiUrl = (name, baseUrl) => baseUrl + apiConfig[name].urlEndpoint;

export default apiConfig;
