import { boldFont } from '@theme/theme';
import { Modal, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ModalHeaderProps {
  headerTranslationString?: string;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ headerTranslationString = '' }) => {
  const { t } = useTranslation('common');

  return (
    <Modal.Header w="100%" fontFamily={boldFont}>
      <Text testID="txtHeaderTranslated" variant="lg-bold">
        {t(headerTranslationString)}
      </Text>
    </Modal.Header>
  );
};

export default ModalHeader;
