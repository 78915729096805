import colors from '@theme/colors';
import { StyleSheet } from 'react-native';
import { getSelectedReasonBackgroundColor, getSelectedReasonBorderColor } from './reasonSwitchUtils';

const styles = StyleSheet.create({
  reasonContainer: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
  },
  reasonSwitch: (selectedReason, empty) => ({
    alignItems: 'center',
    borderColor: selectedReason.selected ? colors.error.rose : colors.disabled.smoke,
    borderRadius: 12,
    borderWidth: empty ? 0 : selectedReason.selected ? 2 : 1,
    flexDirection: 'column',
    height: 100,
    maxHeight: 100,
    width: '30%',
    aspectRatio: 1,
    marginVertical: 8,
    overflow: 'hidden',
    paddingHorizontal: 5,
    paddingVertical: 10,
  }),
  selectedDotStyle: selectedReason => ({
    width: 8,
    height: 8,
    borderRadius: 8,
    borderWidth: 1,
    top: 5,
    right: 5,
    position: 'absolute',
    borderColor: getSelectedReasonBorderColor(selectedReason),
    backgroundColor: getSelectedReasonBackgroundColor(selectedReason),
  }),
});
export default styles;
