import ToastSuccess from '@atoms/ToastSuccess';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import { ToastType } from '@root/types';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getReferralEncryption } from '@utils/utils';
import * as Clipboard from 'expo-clipboard';
import i18n from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { Platform, Share } from 'react-native';

async function buildLink(
  code: string,
  campaign_source: string,
  campaign_medium: string,
  campaign_name: string,
): Promise<string> {
  const utmParameters = `utm_source=${campaign_source}&utm_medium=${campaign_medium}&utm_campaign=${campaign_name}`;

  return dynamicLinks().buildShortLink(
    {
      link: `https://gajigesanative.page.link/referral/${code}?${utmParameters}`,
      // domainUriPrefix is created in your Firebase console
      domainUriPrefix: 'https://gajigesanative.page.link',
      ios: {
        bundleId: 'org.reactjs.native.example.GajiGesaNative',
        customScheme: 'com.googleusercontent.apps.959826625148-n033p2bufr42l3nr9aki29hql01r4cn1',
        fallbackUrl: 'https://gg-employee.gajigesa-infra.com/',
      },
      android: {
        packageName: 'com.gajigesanative',
      },
      navigation: {
        forcedRedirectEnabled: true,
      },
      analytics: {
        source: campaign_source,
        medium: campaign_medium,
        campaign: campaign_name,
      },
    },
    dynamicLinks.ShortLinkType.SHORT,
  );
}

export default buildLink;

export function getSharingText(referralCode: string, shortLink: string): string {
  const { t } = i18n;
  if (Platform.OS === 'web') return referralCode;
  return (
    t('common:referral.textCopiedToClipboardPart1') +
    referralCode +
    t('common:referral.textCopiedToClipboardPart2') +
    shortLink +
    t('common:referral.txtTnCPoins')
  );
}

export async function shareApiHandler(referralCode: string, shortLink: string): Promise<void> {
  try {
    const result = await Share.share({
      message: getSharingText(referralCode, shortLink),
    });
    if (result.action === Share.sharedAction) {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Select.name);
    }
  } catch (e) {
    // console.error('Share API failed:', e); // Better error logging
  }
}

export function shareApiGeneric(employeeId: string | number | null, shortLink: string): void {
  if (!employeeId) return; // Guard clause to prevent errors
  const referralCode = getReferralEncryption(employeeId.toString());
  shareApiHandler(referralCode, shortLink);
}

const onCopyToClipBoardHandler = async (
  shortLink: string,
  referralCode: string,
  toast: ToastType,
  t: (key: string) => string,
): Promise<void> => {
  try {
    await Clipboard.setStringAsync(getSharingText(referralCode, shortLink));
    toast.show({
      render: () => <ToastSuccess data={t('referral.codeCopiedSuccessfully')} />,
      placement: 'top',
    });

    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.CopySuccess.name, {
      [AMPLITUDE_CONSTANTS.Referral.CopySuccess.attributes.referral_code]: referralCode || null,
    });
  } catch (e) {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.CopyFailed.name, {
      [AMPLITUDE_CONSTANTS.Referral.CopyFailed.attributes.referral_code]: referralCode || null,
      [AMPLITUDE_CONSTANTS.Referral.CopyFailed.attributes.failure_message]: 'system_error',
    });
    //  console.error('Clipboard copy failed:', e);
  }
};

const shareShortLinkCallBackHandler = async (shortLink: string, referralCode: string): Promise<void> => {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Share.name, {
    [AMPLITUDE_CONSTANTS.Referral.Share.attributes.referral_code]: referralCode || null,
  });

  await shareApiHandler(referralCode, shortLink);
};

export function referralPageHandlers(
  setCollapsedUsage: Dispatch<SetStateAction<boolean>>,
  collapsedUsage: boolean,
  referralCode: string,
  toast: ToastType,
  t: (key: string) => string,
) {
  const onPressDropDown = () => {
    setCollapsedUsage(!collapsedUsage);
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Dropdown.name, {
      [AMPLITUDE_CONSTANTS.Referral.Dropdown.attributes.action]: collapsedUsage ? 'collapse' : 'expand',
    });
  };

  const shareShortLinkCallBack = async (shortLink: string) => {
    await shareShortLinkCallBackHandler(shortLink, referralCode);
  };

  const onCopyToClipBoard = async (shortLink: string) => {
    await onCopyToClipBoardHandler(shortLink, referralCode, toast, t);
  };

  return { shareShortLinkCallBack, onPressDropDown, onCopyToClipBoard };
}
