import { apiBPConfirm, apiForGoldConfirmation } from '@api/apiCall';
import BPTnCHeader from '@atoms/BPTnCHeader';
import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import BPTncAndBillDetails from '@molecules/BPTncAndBillDetails';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getAmplitudeKeyValue } from '@utils/utils';
import { Modal, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logErrorAnalytics, logSheetOpened, logTnCClosed, logTxClosedWithSource } from './analyticsRequestTxSheet';

const getMutationConfig = isGold => {
  if (isGold) {
    return {
      mutationFn: payload => apiForGoldConfirmation(payload),
    };
  }
  return {
    mutationFn: payload => apiBPConfirm(payload),
  };
};
function RequestTransactionSheet(props) {
  const {
    validatedReferralCode,
    setLoading,
    setInitialData,
    selectedBPAccount,
    setValidatedReferralCode,
    meAmplitudeContext,
  } = getStore();
  const [tncChecked, setTncChecked] = useState(false);
  const [tncOpen, setTncOpen] = useState(false);
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const { openRTS, setOpenRTS, productConfig, bpReqData, isGold, errorHandler, selectedAccountNumber, source } = props;
  const queryClient = useQueryClient();

  const [selectedBPAccountForConfirm, setSelectedBPAccountForConfirm] = useState({});

  const toast = useToast();

  const { isLoading, isError, isSuccess, error, data, mutate: confirmBP } = useMutation(getMutationConfig(isGold));

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError && (error || isGold)) {
      logErrorAnalytics(productConfig, bpReqData, t, error, selectedBPAccountForConfirm, source);
    }
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
    if (isError && isGold) {
      errorHandler();
    }
  }, [isError, error, isGold]);

  useEffect(() => {
    if (isSuccess && data) {
      queryClient.invalidateQueries({ queryKey: ['transactions_history'] });
      setOpenRTS(false);

      const e1 = AMPLITUDE_CONSTANTS.BillPayment.selamatPage;
      const e2 = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess;

      const logAmplitudeEvent = (eventName, metaParams = {}) => {
        const { name, attributes } = eventName;
        AmplitudeHelper.logEvent(name, {
          [attributes.transaction_category_id]: productConfig?.transaction_category_id,
          [attributes.product_price]: bpReqData?.subtotal,
          [attributes.product_code]: bpReqData?.productCode,
          [attributes.reason]: productConfig?.reason,
          [attributes.reason_id]: productConfig?.reasonId,
          [attributes.payment_method]: selectedBPAccountForConfirm?.amplitudeAccountName,
          [attributes.source]: source,
          [attributes.fee_charged]: bpReqData?.fee,
          [attributes.referral_code]: validatedReferralCode,
          ...metaParams,
        });
      };
      logAmplitudeEvent(e1);
      logAmplitudeEvent(e2, getAmplitudeKeyValue(e2.name, data?.amplitudeEventContext));

      setValidatedReferralCode(null);
      navigation.navigate(navigationConstants.GG_BP_SUCCESS, {
        bpRequestSuccess: data,
        ...props,
        bpReqData,
        productConfig,
        selectedBPAccountForConfirm,
        source,
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (bpReqData.paymentMethods && bpReqData.paymentMethods.length > 0 && bpReqData !== undefined) {
      setSelectedBPAccountForConfirm(bpReqData.paymentMethods.filter(item => item.id === selectedBPAccount.id)[0]);
    }
  }, [bpReqData]);

  useEffect(() => {
    if (openRTS) {
      logSheetOpened(selectedAccountNumber, productConfig, bpReqData, source, validatedReferralCode);
    }
  }, [openRTS]);

  const closeRequestTxSheet = () => {
    if (tncOpen) {
      logTnCClosed(productConfig, source);
    } else {
      logTxClosedWithSource(productConfig, source, 'X');
    }

    setOpenRTS(false, true);
    setTncOpen(false);
    setTncChecked(false);
  };

  return (
    <Modal
      isOpen={openRTS}
      closeOnOverlayClick
      placement="bottom"
      style={appStyles.modalParent}
      onClose={closeRequestTxSheet}>
      <Modal.Content style={appStyles.modalContainer}>
        {tncOpen ? <BPTnCHeader /> : <ModalHeader headerTranslationString="reqTxSheet.chosenProduct" />}
        <ModalClose testID="modalClose" sentry-label="modalClose" onPress={closeRequestTxSheet} />
        <BPTncAndBillDetails
          isLoading={isLoading}
          tncOpen={tncOpen}
          productConfig={productConfig}
          props={props}
          bpReqData={bpReqData}
          tncChecked={tncChecked}
          setOpenRTS={setOpenRTS}
          validatedReferralCode={validatedReferralCode}
          selectedBPAccount={selectedBPAccountForConfirm}
          setTncOpen={setTncOpen}
          setTncChecked={setTncChecked}
          confirmBP={confirmBP}
          setSelectedBPAccount={setSelectedBPAccountForConfirm}
          source={source}
          meAmplitudeContext={meAmplitudeContext}
        />
      </Modal.Content>
    </Modal>
  );
}

RequestTransactionSheet.propTypes = {
  openRTS: PropTypes.bool,
  setOpenRTS: PropTypes.func,
  bpReqData: PropTypes.shape({
    paymentMethods: PropTypes.oneOfType([PropTypes.array]),
    productCode: PropTypes.string,
    subtotal: PropTypes.number,
    fee: PropTypes.number,
  }),
  productConfig: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  productApiData: PropTypes.shape({
    prefix: PropTypes.string,
  }),
  selectedAccountNumber: PropTypes.string,
  source: PropTypes.string,
  nominalSelected: PropTypes.shape({
    code: PropTypes.string,
  }),
  isGold: PropTypes.bool,
  errorHandler: PropTypes.func,
};
RequestTransactionSheet.defaultProps = {
  openRTS: false,
  setOpenRTS: () => {},
  bpReqData: {
    paymentMethods: [{ id: -1 }],
    productCode: '',
    subTotal: 0,
    fee: 0,
  },
  productConfig: {
    hasAccountInput: false,
    requestInfo: {
      header: [{ key: '', value: '' }],
    },
    transaction_category_id: '',
    reason: '',
    reasonId: 0,
  },
  productApiData: {
    prefix: '',
  },
  selectedAccountNumber: '',
  source: '',
  nominalSelected: {
    code: '',
  },
  isGold: false,
  errorHandler: () => {},
};

export default RequestTransactionSheet;
