import { View, Text } from 'native-base';
import React, { ReactNode } from 'react';
import styles from './style';

interface SecurityRowProps {
  title?: string;
  description?: string;
  show?: boolean;
  featureImage?: ReactNode;
}

const SecurityRow: React.FC<SecurityRowProps> = ({
  title = '',
  description = '',
  show = false,
  featureImage = null,
}) => {
  if (!show) return null;

  return (
    <View style={styles.outerContainer}>
      <View style={styles.subContainer}>
        <View style={styles.imageContainer}>{featureImage}</View>
      </View>
      <View style={styles.viewRightContainer}>
        <Text variant="md-bold">{title}</Text>
        <Text variant="sm-normal">{description}</Text>
      </View>
    </View>
  );
};

export default SecurityRow;
