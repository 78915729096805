import React from 'react';

interface Account {
  id: number;
  name: string;
  balance: number;
  accountTypeId: number;
}

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export default function checkIfSDModalNeedsToBeShown(
  accounts: Account[] | null,
  setSelectedSDAccountWallet: SetState<Account | null>,
  showSDModal: boolean,
  isInLockPeriod: boolean,
  isFocused: boolean,
  setSDModalVisible: SetState<boolean>,
): void {
  if (!accounts || accounts.length === 0) return;

  const sdAccount = accounts.filter(item => item.accountTypeId === 3);

  if (sdAccount.length > 0) {
    setSelectedSDAccountWallet(sdAccount[0]);
    const sdBalance = sdAccount[0].balance;

    if (showSDModal && sdBalance > 0 && isInLockPeriod && isFocused) {
      setSDModalVisible(true);
    }
  }
}
