import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LoginPhoneTxtProps {
  isMandatory?: boolean;
  variant?: string;
  marginTop?: number;
}

const shouldShowMandatory = (isMandatory?: boolean) =>
  isMandatory ? (
    <Text testID="txtMandatoryAsteriks" variant="xsm-bold" color={colors.error.rose}>
      *
    </Text>
  ) : null;

const LoginPhoneTxt: React.FC<LoginPhoneTxtProps> = ({
  isMandatory = false,
  variant = 'sm-normal',
  marginTop = 12,
}) => {
  const { t } = useTranslation('common');

  return (
    <Text
      testID="txtLoginPhoneNumber"
      marginTop={`${marginTop}px`}
      alignSelf="stretch"
      variant={variant}
      color={colors.neutral.darkMist}>
      {t('login.phoneNumber')}
      {shouldShowMandatory(isMandatory)}
    </Text>
  );
};

export default LoginPhoneTxt;
