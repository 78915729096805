import AntDesign from '@expo/vector-icons/AntDesign';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import listingItemStyles from './style';

interface ListingItemProps {
  text?: string;
  points?: number;
  customContainer?: ViewStyle;
  show?: boolean;
  disabled?: boolean;
}

const ListingItem: React.FC<ListingItemProps> = ({
  text = '',
  points = 0,
  customContainer = {},
  show = false,
  disabled = false,
}) => {
  if (!show) return null;

  return (
    <View style={[listingItemStyles.container, customContainer]}>
      <View style={listingItemStyles.iconContainer}>
        <AntDesign
          name="checkcircle"
          color={disabled ? colors.disabled.smoke : colors.success.cactus}
          size={scale(18)}
        />
      </View>
      <View style={appStyles.marginLeft(5)}>
        <Text variant="sm-normal" style={listingItemStyles.text}>
          {text}
        </Text>
        <Text variant="xsm-normal" style={[listingItemStyles.points, disabled && listingItemStyles.disabledStyle]}>
          {`+${points} Poin`}
        </Text>
      </View>
    </View>
  );
};

export default ListingItem;
