import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

interface TitleTextProps {
  title: string;
}

const TitleText: React.FC<TitleTextProps> = ({ title }) => (
  <View style={styles.titleTextContainer}>
    <Text variant="md-bold">{title}</Text>
  </View>
);

export default TitleText;
