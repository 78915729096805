import { fetchFeatureFlags } from '@api/useQueryCalls';
import * as Sentry from '@sentry/react-native';
import { getStore } from '@store/storeUtils';
import AmplitudeHelper from '@utils/analytics';
import { isFeatureEnabled } from '@utils/utils';
import { useEffect, useMemo } from 'react';
import { featureFlagOnComplete, featureFlagOnError } from './customHookUtils';

interface UseFeatureFlagProps {
  featureName?: string[];
  onCompleted?: (data: any) => void;
  onCompleteWithDynamicFeature?: (featureName: string, data: any) => void;
}

const useFeatureFlag = ({ featureName, onCompleted, onCompleteWithDynamicFeature }: UseFeatureFlagProps) => {
  const { featureFlags, setFeatureFlags } = getStore();

  const { isSuccess, data, isError, error, refetch: getFeatureFlags } = fetchFeatureFlags();

  useEffect(() => {
    if (isSuccess && data) {
      featureFlagOnComplete(setFeatureFlags, data, onCompleted, featureName, onCompleteWithDynamicFeature);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError && error) {
      Sentry.addBreadcrumb({ category: 'info', message: `Error in gql feature flag ${error?.message}`, level: 'info' });
      AmplitudeHelper.logEvent('gql_error_featureflag', { message: error?.message });
      featureFlagOnError();
    }
  }, [isError, error]);

  useEffect(() => {
    getFeatureFlags();
  }, [getFeatureFlags]);

  const isFeatureAvailable = useMemo(() => isFeatureEnabled(featureFlags, featureName), [featureFlags, featureName]);

  return featureName ? [isFeatureAvailable, getFeatureFlags] : [getFeatureFlags];
};

export default useFeatureFlag;
