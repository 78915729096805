import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface Nominal {
  amount: number;
  value: number;
}

interface SelectedNominal {
  availability: boolean;
  name: string;
  nominal: Nominal;
}

interface BPSubProdSelectedNominalProps {
  selectedNominal?: SelectedNominal;
}

export function getNominalColorBasedOnAvailability(selectedNominal: SelectedNominal): string {
  return selectedNominal.availability ? colors.neutral.charcoal : colors.neutral.darkMist;
}

export function getPriceColorBasedOnAvailability(selectedNominal: SelectedNominal): string {
  return selectedNominal.availability ? colors.success.cactus : colors.neutral.darkMist;
}

const BPSubProdSelectedNominal: React.FC<BPSubProdSelectedNominalProps> = ({
  selectedNominal = { availability: true, name: '', nominal: { amount: 0, value: 0 } },
}) => {
  const { t } = useTranslation('common');

  return (
    <View style={appStyles.flex1}>
      <Text variant="md-normal" color={getNominalColorBasedOnAvailability(selectedNominal)}>
        {selectedNominal.name}
      </Text>
      <View style={styles.nominalStyles}>
        <Text variant="lg-bold" color={getPriceColorBasedOnAvailability(selectedNominal)}>
          {t('bp.price')}{' '}
        </Text>
        <TextCurrency
          variant="md-bold"
          color={getPriceColorBasedOnAvailability(selectedNominal)}
          amountCurrency={selectedNominal.nominal.amount}
        />
      </View>
      {!selectedNominal.availability && (
        <Text color={colors.error.rose} variant="xsm-bold">
          {t('bankTransfer.notEnoughBalance')}
        </Text>
      )}
    </View>
  );
};

export default BPSubProdSelectedNominal;
