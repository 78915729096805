export function getSelectedNames(
  departmentName: string,
  designationName: string,
  selectedDepartmentName: string,
  selectedDesignationName: string,
) {
  if (!departmentName && !designationName) {
    return selectedDepartmentName && selectedDesignationName;
  }
  if (!departmentName) {
    return selectedDepartmentName;
  }
  if (!designationName) {
    return selectedDesignationName;
  }

  return false;
}

/* eslint-disable no-restricted-syntax */
export function masker(phoneNumber: number) {
  let output = '';
  for (const j in phoneNumber) {
    if (phoneNumber.length - j <= 4) {
      output += phoneNumber[j];
    } else {
      output += '*';
    }
  }
  return output;
}
