import OpenPayPeriodText from '@atoms/OpenPayPeriodText';
import TextCurrency from '@atoms/TextCurrency';
import { MaterialIcons } from '@expo/vector-icons';
import WithdrawButton from '@molecules/WithdrawButton';
import { Slider } from '@rneui/base';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { changeAmountWithFormatting, numberWithDots } from '@utils/utils';
import { Divider, Icon, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TextInput } from 'react-native';
import { onManualEditingFinished, sliderCompleteAnalytics } from './analyticsSlider';
import { isWithdrawButtonDisabled } from './sliderUtils';
import styles from './styles';

function shouldShowSlider(sliderProps) {
  const { hasSlider, sInputAmountValue, setInputAmountValue, maxValue, minValue, incrementBy, validatedReferralCode } =
    sliderProps;
  if (hasSlider) {
    return (
      <Slider
        testID="accountSlider"
        value={sInputAmountValue}
        onValueChange={setInputAmountValue}
        maximumValue={maxValue}
        minimumValue={minValue}
        step={incrementBy}
        height={25}
        trackStyle={styles.trackStyles}
        thumbStyle={styles.thumbStyles}
        minimumTrackTintColor={colors.primary.carnation}
        maximumTrackTintColor={colors.primary.softCarnation}
        onSlidingComplete={sliderCompleteAnalytics(validatedReferralCode)}
      />
    );
  }
  return null;
}

function SliderView(props) {
  const { tenantSettings, selectedAccount, validatedReferralCode } = getStore();

  const { balance, minTxnValue, hasSlider, openPeriod, slider, defaultWithdrawalAmount, withdrawalButton } =
    selectedAccount;
  const { minValue, maxValue, incrementBy } = slider;
  const calulatedInputValue = useMemo(() => defaultWithdrawalAmount, [defaultWithdrawalAmount]);

  const [sInputAmountValue, setInputAmountValue] = useState(calulatedInputValue);

  const { currencySymbol, currencySeperatorRegEx } = tenantSettings;

  const { editable, setEditable } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    if (editable) {
      inputRef.current.focus();
      setInputAmountValue('');
    } else {
      setInputAmountValue(calulatedInputValue);
    }
  }, [editable, calulatedInputValue]);

  useEffect(() => {
    if (editable) setEditable(false);
  }, [selectedAccount]);

  const sliderProps = {
    hasSlider,
    sInputAmountValue,
    setInputAmountValue,
    maxValue,
    minValue,
    incrementBy,
    validatedReferralCode,
  };

  const rememberDisableFlag = useMemo(
    () => isWithdrawButtonDisabled(sInputAmountValue, minValue, maxValue, withdrawalButton?.enabled),
    [sInputAmountValue, minValue, maxValue, withdrawalButton],
  );

  return (
    <View>
      <View style={styles.flexRow}>
        <View style={styles.balanceInput}>
          <Text variant="md-bold" style={appStyles.marginRight(0)}>
            {currencySymbol}
          </Text>

          <TextInput
            ref={inputRef}
            testID="txtInputAmount"
            sentry-label="txtInputAmount"
            editable={editable}
            maxLength={15}
            style={styles.textInputStyle}
            value={numberWithDots(sInputAmountValue)}
            onChangeText={changeAmountWithFormatting(currencySeperatorRegEx, () => {}, setInputAmountValue)}
            blurOnSubmit
            onBlur={onManualEditingFinished(validatedReferralCode)}
            keyboardType="numeric"
          />
        </View>
        <Icon
          testID="btnEditIcon"
          as={MaterialIcons}
          name="edit"
          style={{
            height: scale(16),
          }}
          onPress={() => setEditable(prevState => !prevState)}
        />
      </View>
      <Divider />

      {shouldShowSlider(sliderProps)}
      <View style={styles.flexRowtwo}>
        <TextCurrency
          testID="txtMinWithdrawalAmount"
          variant="xsm-normal"
          color={colors.neutral.darkMist}
          flex={1}
          amountCurrency={minTxnValue}
        />
        <TextCurrency
          testID="txtMaxWithdrawalAmount"
          variant="xsm-normal"
          color={colors.neutral.darkMist}
          amountCurrency={balance}
        />
      </View>
      <WithdrawButton
        withdrawalAmount={sInputAmountValue}
        isDisabled={rememberDisableFlag}
        source={AMPLITUDE_EVENT_SOURCES.home_page}
        transaction_category_id="wd-01"
      />
      <OpenPayPeriodText openPeriod={openPeriod} />
    </View>
  );
}

SliderView.propTypes = {
  editable: PropTypes.bool,
  setEditable: PropTypes.func,
};
SliderView.defaultProps = {
  editable: true,
  setEditable: () => {},
};

export default SliderView;
