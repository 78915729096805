import { MaterialIcons } from '@expo/vector-icons';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { Button, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface ReferralCheckButtonProps {
  referralCode?: string;
  validateReferralCodeApi: (referralCode: string) => void;
  pageSource: string;
}

const ReferralCheckButton: React.FC<ReferralCheckButtonProps> = ({
  referralCode = '',
  validateReferralCodeApi,
  pageSource,
}) => {
  const { t } = useTranslation('common');
  return (
    <Button
      testID="btnReferralValidate"
      variant="ghost"
      borderRadius={scale(8)}
      borderWidth={scale(1)}
      style={styles.buttonStyle}
      size=""
      borderColor={colors.disabled.smoke}
      onPress={() => {
        const e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_submit_clicked;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: referralCode,
          [e.attributes.source]: pageSource,
        });
        validateReferralCodeApi(referralCode);
      }}
      rightIcon={
        <MaterialIcons
          name="arrow-right-alt"
          size={scale(24)}
          color={colors.disabled.smoke}
          style={styles.arrowIconStyles}
        />
      }>
      <Text testID="referralVerifyBtn" color={colors.neutral.darkMist} style={styles.textStyle} variant="md-normal">
        {t('referral.verifyBtn')}
      </Text>
    </Button>
  );
};

export default ReferralCheckButton;
