import { fetchSubProductsOrNominals } from '@api/useQueryCalls';
import BPNoDataFound from '@atoms/BPNoDataFound';
import useBPMutation from '@customHooks/useBPRequest';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { View, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logErrorOnAnalytics, logPageBack, logPageLaunch } from './analyticsNominals';
import showListOfNominals from './nominalUtils';
import {
  refetchBPIfStale,
  shouldShowAccountInput,
  shouldShowPhoneSelection,
  shouldShowTitle,
  showErrorOnAPI,
} from '../BPUtils';

function GGNominals(props) {
  const { bpPhone, setBPPhone, setInitialData, selectedBPAccount, validatedReferralCode, meAmplitudeContext } =
    getStore();
  const { mobileNumber } = bpPhone;
  const { accountTypeId, amplitudeAccountName } = selectedBPAccount;
  const { route } = props;
  const { params } = route;
  const { productConfig, productApiData, source } = params;
  const { productId, noOfColumns, productName } = productConfig;
  const [selectedAccountNumber, setSelectedAccountNumber] = useState(
    productConfig.hasAccountInput ? null : mobileNumber,
  );
  useEffect(() => {
    setBPPhone(bpPhone);
    if (!productConfig.hasAccountInput) {
      setSelectedAccountNumber(bpPhone.mobileNumber);
    }
  }, [bpPhone]);
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  useEffect(() => {
    navigation.setOptions({ title: productName });
  }, [productName]);

  const [nominalsData, setNominalsData] = useState([]);
  const [openRTS, setOpenRTS] = useState(false);
  const [nominalSelected, setNominalSelected] = useState({ id: -1 });
  const [bpReqData, setBpReqData] = useState({});
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [sAccountInputError, setAccountInputError] = useState('');

  useEffect(() => {
    if (selectedAccountNumber && selectedAccountNumber.length > 0) {
      setAccountInputError('');
    }
  }, [selectedAccountNumber]);
  const { isLoading, isSuccess, data, isError, error, isStale, refetch } = fetchSubProductsOrNominals({
    billAccountNumber: mobileNumber,
    productId,
    accountTypeId,
  });

  const { requestBP, isBPReqError, bpError, apiPayload } = useBPMutation({ setOpenRTS, setBpReqData });

  useEffect(() => {
    refetch();
  }, [bpPhone]);

  useEffect(() => {
    logPageLaunch(productConfig, source, validatedReferralCode);
    refetchBPIfStale(isStale, refetch);
    const backAction = () => {
      logPageBack(productConfig, source, validatedReferralCode);
    };
    return () => {
      backAction();
    };
  }, []);

  useEffect(() => {
    logErrorOnAnalytics(
      isBPReqError,
      selectedAccountNumber,
      productConfig,
      apiPayload,
      t,
      bpError,
      source,
      validatedReferralCode,
    );
  }, [isBPReqError, bpError]);

  // useEffect(() => {
  //   setLoading(isLoading);
  // }, [isLoading]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess && data) {
      const nominalsApiData = data;
      const keys = Object.keys(data);
      if (keys.length > 0) {
        keys.forEach(key => {
          setNominalsData(nominalsApiData[key]);
        });
        setShowEmptyView(false);
      } else {
        setShowEmptyView(true);
      }
    }
  }, [isSuccess, data]);
  // const txnCategory = formatUnderScoreLowerCase(productApiData.prefix);

  return (
    <View style={appStyles.safeAreaView}>
      {shouldShowPhoneSelection(params)}
      {shouldShowAccountInput({
        productConfig,
        productApiData,
        setSelectedAccountNumber,
        selectedAccountNumber,
        sAccountInputError,
        setAccountInputError,
        source,
      })}
      {shouldShowTitle(productConfig)}
      {showEmptyView ? (
        <BPNoDataFound />
      ) : (
        showListOfNominals({
          noOfColumns,
          productConfig,
          nominalsData,
          productApiData,
          setOpenRTS,
          setNominalSelected,
          selectedAccountNumber,
          selectedBPAccount,
          requestBP,
          validatedReferralCode,
          t,
          setAccountInputError,
          isLoading,
          toast,
          source,
          meAmplitudeContext,
          amplitudeAccountName,
        })
      )}
      <RequestTransactionSheet
        openRTS={openRTS}
        setOpenRTS={setOpenRTS}
        productConfig={productConfig}
        productApiData={productApiData}
        selectedAccountNumber={selectedAccountNumber}
        nominalSelected={nominalSelected}
        bpReqData={bpReqData}
        source={source}
      />
    </View>
  );
}

GGNominals.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      productConfig: PropTypes.shape({
        productId: PropTypes.number,
        productName: PropTypes.string,
        noOfColumns: PropTypes.number,
        hasAccountInput: PropTypes.bool,
        transaction_category_id: PropTypes.string,
      }),
      productApiData: PropTypes.shape({
        prefix: PropTypes.string,
      }),
    }),
  }),
};
GGNominals.defaultProps = {
  route: {
    params: {
      source: '',
      productConfig: {
        productId: '',
        productName: '',
        noOfColumns: 1,
        hasAccountInput: false,
        transaction_category_id: '',
      },
      productApiData: {
        prefix: '',
      },
    },
  },
};

export default GGNominals;
