import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import bulletRowStyle from './style';

interface RenderBulletRowProps {
  desc?: string;
}

const RenderBulletRow: React.FC<RenderBulletRowProps> = ({ desc = '' }) => (
  <View style={bulletRowStyle.outerRow}>
    <Text variant="sm-normal" style={bulletRowStyle.bulletStyle}>
      {'\u2022'}
    </Text>
    <Text variant="sm-normal" style={bulletRowStyle.bulletStyleTwo}>
      {desc}
    </Text>
  </View>
);

export default RenderBulletRow;
