import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ReferralCodeProps {
  referralCode?: string;
}

const ReferralCode: React.FC<ReferralCodeProps> = ({ referralCode = '' }) => {
  const { t } = useTranslation('common');

  return (
    <View style={appStyles.marginVertical(16)}>
      <Text variant="md-bold">{t('referral.txtAchievePrize')}</Text>
      <View
        backgroundColor={colors.secondary.softOrchid}
        alignItems="center"
        style={[
          appStyles.marginTop(4),
          appStyles.borderRadius(12),
          appStyles.paddingTop(12),
          appStyles.paddingBottom(10),
        ]}>
        <Text variant="sm-normal">{t('referral.txtGGReferralCode')}</Text>
        <Text variant="xl-bold">{referralCode}</Text>
      </View>
    </View>
  );
};

export default ReferralCode;
