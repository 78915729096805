import { Text } from 'native-base';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import styles from './style';

interface RowProps {
  title: string;
  value: string;
  containerStyle?: ViewStyle;
}

const Row: React.FC<RowProps> = ({ title, value, containerStyle = {} }) => (
  <View style={[styles.innerRowStyle, containerStyle]}>
    <Text variant="sm-bold">{title}</Text>
    <Text style={styles.innterRowValue} variant="sm-normal">
      {value}
    </Text>
  </View>
);

export default Row;
