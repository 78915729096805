import logEyeIconClicked from '@atoms/BankRow/analyticsBankRow';
import DotComponent from '@atoms/DotComponent';
import { Ionicons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { showStatusViewForBank } from '@utils/utils';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

interface Bank {
  bankName: string;
}

interface BankRowItem {
  id: number;
  accountNumber: string;
  beneficiaryName: string;
  nickname?: string;
  status: string;
  bank: Bank;
}

interface BankRowStatusApprovedProps {
  setBankForTransfer: (bank: BankRowItem) => void;
  maskData: boolean;
  maskIcon: string;
  setMaskIcon: (icon: string) => void;
  setMaskData: (mask: boolean) => void;
  fromTransfer?: boolean;
  source?: string;
  item: BankRowItem;
}

const BankRowStatusApproved: React.FC<BankRowStatusApprovedProps> = ({
  setBankForTransfer,
  maskData = false,
  maskIcon = '',
  setMaskIcon,
  setMaskData,
  fromTransfer = false,
  source = '',
  item,
}) => {
  const { t } = useTranslation('common');
  const { bankForTransfer } = getStore();
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      testID="bankRowApproved"
      style={appStyles.marginVertical(8)}
      onPress={() => {
        if (fromTransfer) {
          setBankForTransfer(item);
          navigation.goBack();
        }
      }}>
      <Text variant="md-bold">{item?.bank?.bankName}</Text>
      <View flexDirection="row" alignItems="center">
        <Text variant="sm-normal">{getMaskedOrUnmaskedData(maskData, item?.accountNumber)}</Text>
        <Ionicons
          name={maskIcon}
          size={18}
          color="black"
          testID="btnMaskUnmaskImage"
          style={appStyles.marginLeft(8)}
          onPress={() => {
            logEyeIconClicked(source, maskData, item);
            setMaskIcon(setMaskIconValue(maskData));
            setMaskData(!maskData);
          }}
        />
        <View flex={1} alignItems="flex-end">
          {showStatusViewForBank(item, t, fromTransfer, bankForTransfer)}
        </View>
      </View>
      <View flexDirection="row" alignItems="center">
        {item?.nickname && (
          <View flexDirection="row" alignItems="center">
            <Text color={colors.neutral.darkMist} variant="md-bold">
              {item?.nickname}
            </Text>
            <DotComponent />
          </View>
        )}
        <Text color={colors.neutral.darkMist} variant="sm-normal">
          {item?.beneficiaryName}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(BankRowStatusApproved);
