import { Text } from 'native-base';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyle, ViewStyle } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withTiming,
  Easing,
  interpolate,
} from 'react-native-reanimated';
import styles from './style';

type BaruProps = {
  testID?: string,
  showBaru?: boolean,
  customStyles?: ViewStyle,
  textStyle?: TextStyle,
  isRegular?: boolean,
  animate?: boolean,
};

const Baru: React.FC<BaruProps> = ({
  testID = 'newBadge',
  showBaru = false,
  customStyles = {},
  textStyle = {},
  isRegular = false,
  animate = false,
}) => {
  const { t } = useTranslation('common');
  const tiltValue = useSharedValue(0);
  const DURATION = 700;

  useEffect(() => {
    if (showBaru && animate) {
      tiltValue.value = withRepeat(withTiming(-1, { duration: DURATION, easing: Easing.linear }), -1, true);
    }
    return () => {
      tiltValue.value = 0;
    };
  }, [showBaru, animate]);

  const tiltStyle = useAnimatedStyle(() => {
    const rotate = interpolate(tiltValue.value, [-1, 0, 1], [-30, 0, 20]);
    return {
      transform: [{ rotate: `${rotate}deg` }],
    };
  });

  if (!showBaru) return null;

  return (
    <Animated.View testID={testID} style={[styles.container, customStyles, tiltStyle]}>
      <Text testID="newText" variant={isRegular ? 'sm-normal' : 'sm-bold'} style={[styles.textStyle, textStyle]}>
        {t('new')}
      </Text>
    </Animated.View>
  );
};

export default Baru;
