import { Image } from 'expo-image';
import React from 'react';
import { ImageStyle } from 'react-native';
import styles from './styles';

function endsWithPNGJPGOrIsBase64(url: string): boolean {
  const withoutQueryString = url.split('?')[0];
  const isPNG = withoutQueryString.endsWith('.png');
  const isJPEG = withoutQueryString.endsWith('.jpeg') || withoutQueryString.endsWith('.jpg');
  const isBase64 = withoutQueryString.startsWith('data:image/') && withoutQueryString.includes('base64,');

  return isPNG || isJPEG || isBase64;
}

interface RemoteImageProps {
  uri: string;
  width?: number;
  height?: number;
  isFullSize?: boolean;
}

const RemoteImage: React.FC<RemoteImageProps> = ({ uri = '', width = 0, height = 0, isFullSize = false, ...props }) => {
  if (!uri) return null;

  const uriEndsWithPNG = endsWithPNGJPGOrIsBase64(uri);
  const imageStyles: ImageStyle[] = [styles.imageStyles(width, height), isFullSize && styles.fullSize];

  return uriEndsWithPNG ? (
    <Image contentFit="contain" style={imageStyles} source={{ uri }} {...props} />
  ) : (
    <Image style={imageStyles} source={{ uri }} {...props} />
  );
};

export default RemoteImage;
