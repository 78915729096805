import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { scale } from '@utils/normalize';
import { View, Text, Button } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { logAcceptPress, logRejectPress } from './analyticsBPTnc';

interface BPTncProps {
  setTncChecked: (checked: boolean) => void;
  setTncOpen: (open: boolean) => void;
  transactionCategoryId: string;
  accountName: string;
  source: string;
}

const BPTnc: React.FC<BPTncProps> = ({ setTncChecked, setTncOpen, transactionCategoryId, accountName, source }) => {
  const { validatedReferralCode } = getStore();
  const { t } = useTranslation('common');

  return (
    <View style={{ marginHorizontal: scale(16) }}>
      <View style={appStyles.marginBottom(24)}>
        <Text testID="lblTnCText" variant="sm-normal" style={appStyles.marginTop(8)}>
          {t('reqTxSheet.bpTnc')}
        </Text>
      </View>
      <View style={[appStyles.flexRow, appStyles.marginBottom(12)]}>
        <Button
          testID="btnRejectTnC"
          variant="ghost"
          flex={1}
          style={[appStyles.marginRight(8), appStyles.borderWidth(2)]}
          onPress={() => {
            logRejectPress({ transactionCategoryId, accountName, validatedReferralCode, source });
            setTncChecked(false);
            setTncOpen(false);
          }}>
          {t('reqTxSheet.cancelTnC')}
        </Button>
        <Button
          testID="btnAccpetTnC"
          variant="solid"
          flex={1}
          onPress={() => {
            logAcceptPress({ transactionCategoryId, accountName, validatedReferralCode, source });
            setTncChecked(true);
            setTncOpen(false);
          }}>
          {t('reqTxSheet.acceptTnC')}
        </Button>
      </View>
    </View>
  );
};

export default BPTnc;
