import Info from '@expo/vector-icons/MaterialIcons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View, Pressable } from 'react-native';
import departmentStyles from './style';

interface DepartmentPickerProps {
  title: string;
  valueHolder: string;
  isSelected?: boolean;
  onPress: () => void;
  show: boolean;
}

const DepartmentPicker: React.FC<DepartmentPickerProps> = ({
  title,
  valueHolder,
  isSelected = false,
  onPress,
  show,
}) => {
  if (!show) return null;

  return (
    <Pressable onPress={onPress} style={departmentStyles.container} testID={`picker-container${title}`}>
      <Text variant="sm-normal" style={departmentStyles.label}>
        {title}
      </Text>
      <View style={departmentStyles.pickerContainer}>
        <Text
          variant="sm-normal"
          style={[departmentStyles.placeholder, isSelected && departmentStyles.selectedstyle]}
          testID="value-holder">
          {valueHolder}
        </Text>
        <View testID="arrow-icon">
          <Info name="keyboard-arrow-right" color={colors.neutral.charcoal} size={scale(24)} />
        </View>
      </View>
    </Pressable>
  );
};

export default DepartmentPicker;
