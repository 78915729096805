import colors from '@theme/colors';
import { Text, View } from 'native-base';
import React from 'react';

interface HiUserNameProps {
  t?: (key: string) => string;
  userName?: string;
  commonColor?: string;
}

const HiUserName: React.FC<HiUserNameProps> = ({
  t = () => '',
  userName = '',
  commonColor = colors.neutral.charcoal,
}) => (
  <View marginLeft="11px" flexDirection="row">
    <Text testID="txtProfileHi" variant="sm-bold" color={commonColor}>
      {t('profile.hi')},{' '}
    </Text>
    <Text testID="txtUserName" variant="sm-bold" color={commonColor}>
      {userName}
    </Text>
  </View>
);

export default HiUserName;
