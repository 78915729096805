import IconRightArrow from '@atoms/IconRightArrow';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import navigationConstants from '@navigation/navigationConstants';
import { NavigationProps } from '@navigation/types';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { getAmplitudeKeyValue } from '@utils/utils';
import { Text, View } from 'native-base';
import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

// Define Account Type
interface AccountProps {
  balance?: number;
  icon?: string;
  displayName?: string;
  color?: string;
  accountTypeId?: number;
  enableAccountCard?: boolean;
}

// Define Component Props
interface AccountCardProps {
  account: AccountProps;
}

// Logging Function
const logEventForPoinClick = (meAmplitudeContext: any) => {
  const event = AMPLITUDE_CONSTANTS.poin_card_clicked;
  AmplitudeHelper.logEvent(event.name, {
    [event.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
    ...getAmplitudeKeyValue(event.name, meAmplitudeContext),
  });
};

// Component Definition
const AccountCard: React.FC<AccountCardProps> = ({ account }) => {
  const navigation = useNavigation<NavigationProps>();
  const { setSelectedBPAccounts, meAmplitudeContext } = getStore();

  // Memoized Navigation Handler
  const handleCardPress = useMemo(
    () => () => {
      if (account.accountTypeId === 1) {
        logEventForPoinClick(meAmplitudeContext);
        setSelectedBPAccounts(account);
        navigation.navigate(navigationConstants.PembayaranTab, {
          source: AMPLITUDE_EVENT_SOURCES.poin_card_clicked,
        });
      } else if (account.accountTypeId === 0) {
        navigation.navigate(navigationConstants.SALARY_INFORMATION);
      }
    },
    [account, navigation, setSelectedBPAccounts, meAmplitudeContext],
  );

  const showRightArrow = useMemo(() => account.enableAccountCard ?? false, [account]);

  return (
    <TouchableOpacity
      style={styles.accountCardContainer}
      sentry-label={account.displayName}
      testID={`account_${account?.accountTypeId?.toString()}`}
      onPress={handleCardPress}>
      <View style={styles.iconAndAccountName}>
        <View style={styles.iconStyles}>
          <RemoteImage uri={account.icon ?? ''} width={scale(15)} height={scale(15)} isFullSize={false} />
        </View>
        <Text
          alignSelf="stretch"
          flex={1}
          testID="txtAccountDisplayName"
          variant="sm-normal"
          color={colors.neutral.darkMist}>
          {account.displayName}
        </Text>
      </View>
      <View flexDirection="row" alignItems="center" marginTop="4px" marginBottom="8px">
        <TextCurrency
          testID={`txt_account_${account?.accountTypeId?.toString()}`}
          variant="md-bold"
          style={styles.accountAmount}
          showCurrencySymbol={account.accountTypeId !== 1}
          amountCurrency={account?.balance ?? 0}
        />
        {showRightArrow && <IconRightArrow />}
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(AccountCard);
