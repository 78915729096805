import appStyles from '@root/appStyles';
import { PWA_REFFERAL_LINK } from '@root/constants';
import colors from '@theme/colors';
import { numberWithDots } from '@utils/utils';
import { View, Text, Link } from 'native-base';
import React from 'react';
import styles from './styles';

interface ReferralTnCProps {
  tncItem?: string;
  index?: number;
  referrerAmt?: number;
  refereeAmt?: number;
}

const shouldShowLink = (index: number): JSX.Element | null =>
  index === 5 ? (
    <Link
      _text={{
        color: colors.secondary.orchid,
        variant: 'sm-normal',
      }}
      isUnderlined={false}
      href={PWA_REFFERAL_LINK}
      isExternal>
      {PWA_REFFERAL_LINK}
    </Link>
  ) : null;

const ReferralTnC: React.FC<ReferralTnCProps> = ({ tncItem = '', index = 0, referrerAmt = 0, refereeAmt = 0 }) => {
  const displayText = (): string => {
    let text = tncItem.replace('$$referrerRewardAmount$$', numberWithDots(referrerAmt));
    text = text.replace('$$refereeRewardAmount$$', numberWithDots(refereeAmt));
    return text;
  };

  return (
    <View style={styles.tncContainer}>
      <Text style={styles.tncNumberStyle}>{index + 1}</Text>
      <Text variant="sm-normal" style={[appStyles.marginHorizontal(10), appStyles.paddingRight(20)]}>
        {displayText()}
        {shouldShowLink(index)}
      </Text>
    </View>
  );
};

export default ReferralTnC;
