import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  errorContainerStyle: {
    borderColor: colors.error.rose,
  },
  errorText: {
    color: colors.error.rose,
    marginTop: scale(5),
  },
  inputContainer: {
    borderColor: colors.neutral.darkMist,
    borderRadius: scale(10),
    borderWidth: 1,
    marginTop: scale(5),
    paddingHorizontal: scale(15),
    paddingVertical: scale(8),
  },
  mainContainer: {
    marginVertical: scale(10),
  },
  star: {
    color: colors.error.rose,
  },
  textInput: {
    color: colors.neutral.charcoal,
    fontFamily: regularFont,
    fontSize: scale(14),
    paddingVertical: 0,
    ...(Platform.OS === 'web' && { outlineStyle: 'none' }), // ✅ Only applies on web
  },
  titleText: {
    color: colors.neutral.darkMist,
  },
});

export default styles;
