import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  borderStyle: { borderWidth: 0 },
  closeIconStyle: { marginLeft: -19 },
  container: {
    backgroundColor: colors.neutral.cotton,
    flexGrow: 1,

    paddingTop: 0,
  },
  containerStyle: { backgroundColor: colors.secondary.orchid, paddingHorizontal: 12, paddingVertical: 16 },
  contentContainerStyle: { backgroundColor: colors.neutral.cotton, flexGrow: 1 },
  inputSearch: {
    color: colors.neutral.charcoal,
    fontFamily: regularFont,
    fontSize: scale(14),
    ...(Platform.OS === 'web' && { outlineStyle: 'none' }), // ✅ Only applies on web
    paddingVertical: scale(10),
    width: '90%',
  },
  inputTextContainer: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.neutral.darkMist,
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    height: scale(48),
    justifyContent: 'space-between',
    paddingLeft: scale(8),
    paddingRight: scale(12),
  },
  itemContainer: { paddingHorizontal: scale(16), paddingVertical: scale(16) },
  itemTextStyle: { color: colors.neutral.charcoal },
});

export default styles;
