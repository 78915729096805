import ArrowDown from '@atoms/ArrowDown';
import BPSubProdSelectedNominal from '@atoms/BPSubProdSelectedNominal';
import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import useBPMutation from '@customHooks/useBPRequest';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { logNominalsPress } from '@screens/GGBillPayments/GGNominals/analyticsNominals';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { View, Text, Modal, FlatList, Divider, Button, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logSubProdNominalFailure from './analyticsSubProducts';
import setOfInlineFunctions from './bpSubProdNominalUtils';
import styles from './styles';

function getItemSeperatorComponent() {
  return () => <Divider color={colors.disabled.smoke} height="1px" />;
}

function BPSubProdNominals(props) {
  const [openRTS, setOpenRTS] = useState(false);
  const { bpPhone, selectedBPAccount, validatedReferralCode, meAmplitudeContext } = getStore();
  const { mobileNumber } = bpPhone;
  const { productNominals, selectedProductItem, productId, productConfig, productApiData, source } = props;
  const { t } = useTranslation('common');
  const toast = useToast();
  const [selectedNominal, setSelectedNominal] = useState(productNominals[selectedProductItem][0]);
  const [showNominalsModal, setShowNominalsModal] = useState(false);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState(
    productConfig.hasAccountInput ? null : mobileNumber,
  );
  const navigation = useNavigation();
  useEffect(() => {
    setSelectedAccountNumber(bpPhone.mobileNumber);
  }, [bpPhone]);
  useEffect(() => {
    setSelectedNominal(productNominals[selectedProductItem][0]);
  }, [selectedProductItem]);
  const [bpReqData, setBpReqData] = useState({});
  const { requestBP, isBPReqError, bpError, apiPayload, isBPReqLoading } = useBPMutation({ setOpenRTS, setBpReqData });
  useEffect(() => {
    /**
      1. account_number -> selectedAccountNumber
      2. product_price -> selectedNominal.nominal.amount
      3. transaction_category_id -> productConfig.transaction_category_id
      4. product_code -> selectedNominal.code
      5. reason -> productConfig.reason
      6. reason_id -> productConfig.reasonId
      7. source -> source
     */
    logNominalsPress(selectedAccountNumber, selectedNominal, productConfig, source);
  }, [selectedNominal]);
  const { btnToShowNominals, onPressSubProdOrNominals, closeModalCallBack, listOfNominalsForSubProducts } =
    setOfInlineFunctions({
      setSelectedNominal,
      setShowNominalsModal,
      productId,
      navigation,
      selectedProductItem,
      productNominals,
      selectedNominal,
      selectedAccountNumber,
      selectedBPAccount,
      productConfig,
      productApiData,
      requestBP,
      validatedReferralCode,
      toast,
      t,
      source,
      meAmplitudeContext,
    });
  useEffect(() => {
    if (isBPReqError && bpError) {
      logSubProdNominalFailure(selectedAccountNumber, productConfig, apiPayload, t, bpError, source);
    }
  }, [isBPReqError, bpError]);
  return (
    <View style={{ marginTop: verticalScale(32), marginHorizontal: scale(16) }}>
      <Text testID="txtChooseProduct" variant="lg-bold" color={colors.secondary.orchid}>
        {t('bp.subProductNominals')}
      </Text>
      <View>
        <TouchableOpacity testID="btnNominalDropDown" style={styles.selectedNominalStyles} onPress={btnToShowNominals}>
          <BPSubProdSelectedNominal
            selectedAccountNumber={selectedAccountNumber}
            selectedNominal={selectedNominal}
            productConfig={productConfig}
          />
          <ArrowDown />
        </TouchableOpacity>
        <Divider
          height="1px"
          color={colors.neutral.darkMist}
          style={{
            marginTop: verticalScale(8),
          }}
        />
        <Button
          testID="btnRequestFromSubProducts"
          style={appStyles.marginTop(24)}
          disabled={isBPReqLoading}
          isLoading={isBPReqLoading}
          onPress={onPressSubProdOrNominals}>
          <Text variant="md-bold" color={colors.neutral.cotton}>
            {t('common.continue')}
          </Text>
        </Button>
        <RequestTransactionSheet
          openRTS={openRTS}
          setOpenRTS={setOpenRTS}
          productConfig={productConfig}
          productApiData={productApiData}
          selectedAccountNumber={selectedAccountNumber}
          nominalSelected={selectedNominal}
          selectedProductItem={selectedProductItem}
          bpReqData={bpReqData}
        />
      </View>
      <Modal
        isOpen={showNominalsModal}
        style={appStyles.modalParent}
        closeOnOverlayClick
        size="full"
        onClose={closeModalCallBack}>
        <Modal.Content style={appStyles.modalContainer}>
          <ModalHeader headerTranslationString="bp.subProductNominals" />
          <ModalClose testID="modalClose" sentry-label="modalClose" onPress={closeModalCallBack} />
          <FlatList
            ItemSeparatorComponent={getItemSeperatorComponent()}
            data={productNominals[selectedProductItem]}
            renderItem={listOfNominalsForSubProducts}
          />
        </Modal.Content>
      </Modal>
    </View>
  );
}

BPSubProdNominals.propTypes = {
  source: PropTypes.string,
  productNominals: PropTypes.shape({}),
  selectedProductItem: PropTypes.string,
  productId: PropTypes.number,
  productConfig: PropTypes.shape({
    hasAccountInput: PropTypes.bool,
  }),
  productApiData: PropTypes.shape({
    prefix: PropTypes.string,
  }),
};
BPSubProdNominals.defaultProps = {
  source: '',
  productNominals: {},
  selectedProductItem: '',
  productId: 1,
  productConfig: {
    hasAccountInput: false,
  },
  productApiData: {
    prefix: '',
  },
};

export default BPSubProdNominals;
