import Baru from '@atoms/Loan/Baru';
import RemoteImage from '@atoms/RemoteImage';
import { AntDesign } from '@expo/vector-icons';
import { IAccount, IAccountRowProps } from '@root/types';
import colors from '@theme/colors';
import { Text, Icon } from 'native-base';
import React from 'react';
import { Pressable, View } from 'react-native';
import styles from './styles';

function setSelectedAccountOnPress(
  selectedAccountDetails: IAccount,
  setSelectedAccount: (account: IAccount) => void,
  setShowAccountSelector: (show: boolean) => void,
) {
  setSelectedAccount(selectedAccountDetails);
  setShowAccountSelector(false);
}

export function shouldRenderRightCheck(show: boolean) {
  if (show)
    return (
      <View style={styles.checkStyle}>
        <Icon
          testID="account-selector-check"
          as={AntDesign}
          name="check"
          color={colors.error.rose}
          marginRight={0}
          size="24px"
        />
      </View>
    );

  return null;
}

const AccountRow: React.FC<IAccountRowProps> = ({
  item,
  setSelectedAccount,
  setShowAccountSelector,
  excludeNewTag = false,
  isSelected = false,
}) => (
  <Pressable
    sentry-level={`${item.accountTypeId}_account`}
    testID={`${item.accountTypeId}_account`}
    sentry-label={`${item.accountTypeId}_account`}
    style={styles.accountRow}
    onPress={() => setSelectedAccountOnPress(item, setSelectedAccount, setShowAccountSelector)}>
    <View>
      <RemoteImage uri={item.icon} width={15} height={15} isFullSize={false} />
    </View>
    <Text testID="txtItemDisplayName" variant="md-normal" marginLeft="16px">
      {item.displayName}
    </Text>

    <Baru showBaru={item.canShowNew && !excludeNewTag} customStyles={styles.baruStyle} />

    {shouldRenderRightCheck(isSelected)}
  </Pressable>
);

export default AccountRow;
