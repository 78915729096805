import { generalEmptyState } from '@assets/png/imagesPng';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-native';
import styles from './styles';

function BPNoDataFound() {
  const { t } = useTranslation('common');
  return (
    <View alignSelf="center" justifyContent="center" flex={1}>
      <View style={styles.containerStyle}>
        <Image source={generalEmptyState} style={styles.imageStyle} contentFit="contain" />
      </View>
      <Text variant="md-bold" alignSelf="center" color={colors.neutral.darkMist}>
        {t('bp.unavailableNominalHeader')}
      </Text>
      <Text variant="sm-normal" textAlign="center" marginTop="3px" color={colors.neutral.darkMist}>
        {t('bp.unavailableNominalDescription')}
      </Text>
    </View>
  );
}

export default BPNoDataFound;
