import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

interface StatusBoxProps {
  isActive: boolean;
  displayText: string;
  timeStamp: string;
}

const StatusBox: React.FC<StatusBoxProps> = ({ isActive, displayText, timeStamp }) => {
  function shouldRenderClosePeriod(show: boolean) {
    return show ? <Text variant="sm-bold">{timeStamp}</Text> : null;
  }

  return (
    <View style={styles.containerStyle}>
      <View style={styles.rowStyle}>
        <View
          style={[
            styles.circleStyle,
            {
              backgroundColor: isActive ? colors.success.cactus : colors.error.rose,
            },
          ]}
        />
        <Text variant="sm-normal" style={styles.displayText}>
          {displayText}
        </Text>
      </View>

      {shouldRenderClosePeriod(isActive)}
    </View>
  );
};

export default StatusBox;
