import Ionicons from '@expo/vector-icons/Ionicons';
import { Text } from 'native-base';
import React from 'react';
import { View, Pressable } from 'react-native';
import styles from './style';

interface PhoneNumberRevealProps {
  revealEye?: boolean;
  number?: string;
  onEyePress?: () => void;
}

const PhoneNumberReveal: React.FC<PhoneNumberRevealProps> = ({
  revealEye = false,
  number = '',
  onEyePress = () => {},
}) => (
  <View style={styles.container}>
    <Pressable testID={revealEye ? 'eye-icon' : 'eye-off-icon'} onPress={onEyePress}>
      <Ionicons name={revealEye ? 'eye' : 'eye-off'} style={styles.eyeIcon} />
    </Pressable>

    <Text variant="md-normal" style={styles.phoneNumberText} testID="phone-number-text">
      {number}
    </Text>
  </View>
);

export default PhoneNumberReveal;
