import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { formatPhoneNumber } from '@utils/utils';
import { Button, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface BtnWhatsappProps {
  isLoadingWhatsapp?: boolean;
  isDisabled?: boolean;
  phoneNumber?: string;
  setIsLoadingSMS: (loading: boolean) => void;
  setIsLoadingWhatsapp: (loading: boolean) => void;
  performLogin: (params: { otpType: string; mobileNumber: string }) => void;
}

const BtnWhatsapp: React.FC<BtnWhatsappProps> = ({
  isLoadingWhatsapp = false,
  isDisabled = false,
  phoneNumber = '',
  setIsLoadingSMS,
  setIsLoadingWhatsapp,
  performLogin,
}) => {
  const { t } = useTranslation('common');
  const { tenantSettings } = getStore();
  const { countryCode } = tenantSettings;

  return (
    <Button
      isLoading={isLoadingWhatsapp}
      isDisabled={isDisabled}
      flex={1}
      variant="solid"
      style={styles.btnWhatsappStyles}
      testID="btnWhatsapp"
      sentry-label="btnWhatsapp"
      onPress={() => {
        setIsLoadingSMS(false);
        setIsLoadingWhatsapp(true);
        performLogin({
          otpType: 'WHATSAPP',
          mobileNumber: formatPhoneNumber(phoneNumber, countryCode),
        });
      }}>
      <Text testID="txtWhatsapp" variant="sm-bold" color={colors.neutral.cotton}>
        {t('login.whatsapp')}
      </Text>
    </Button>
  );
};

export default BtnWhatsapp;
