import IconRightArrow from '@atoms/IconRightArrow';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logSalaryInformation from './analyticsProfileInformation';

interface ProfileSalaryInfoProps {
  showSalaryInfo?: boolean;
}

function navigateToSalaryInfo(navigation: NavigationProp<any>): () => void {
  return () => {
    logSalaryInformation();
    navigation.navigate(navigationConstants.SALARY_INFORMATION);
  };
}

const ProfileSalaryInfo: React.FC<ProfileSalaryInfoProps> = ({ showSalaryInfo = false }) => {
  const { t } = useTranslation('common');
  const navigation = useNavigation<NavigationProp<any>>();

  if (!showSalaryInfo) return null;

  return (
    <TouchableOpacity testID="btnSalaryInfo" onPress={navigateToSalaryInfo(navigation)}>
      <View flexDirection="row" marginTop="16px" alignItems="center">
        <View
          backgroundColor={colors.secondary.softOrchid}
          paddingX="4px"
          paddingBottom="4.5px"
          paddingTop="3.5px"
          borderRadius="12px"
          justifyContent="center">
          <MaterialCommunityIcons name="wallet" size={24} color={colors.secondary.orchid} />
        </View>
        <Text testID="txtSalaryInfo" marginLeft="8px" flex={1} variant="md-normal" alignSelf="center">
          {t('profile.salaryInfo')}
        </Text>
        <IconRightArrow />
      </View>
    </TouchableOpacity>
  );
};

export default ProfileSalaryInfo;
