import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import colors from '@theme/colors';
import { verticalScale } from '@utils/normalize';
import { Modal, Text, View } from 'native-base';
import React from 'react';

interface ModalInactiveWASProps {
  closeModalWDStatus?: () => void;
  showWDModal?: boolean;
  modalHeader?: string;
  imgStatusFile?: React.ReactNode;
  wdModalText?: string;
}

const ModalInactiveWAS: React.FC<ModalInactiveWASProps> = ({
  closeModalWDStatus = () => {},
  showWDModal = false,
  modalHeader = '',
  imgStatusFile = null,
  wdModalText = '',
}) => (
  <Modal
    closeOnOverlayClick
    testID="modalWDStatus"
    sentry-label="modalWDStatus"
    onClose={closeModalWDStatus}
    isOpen={showWDModal}
    size="full">
    <Modal.Content
      marginTop="auto"
      bottom={0}
      position="absolute"
      backgroundColor={colors.neutral.cotton}
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}>
      <ModalHeader headerTranslationString={modalHeader} />
      <ModalClose testID="modalClose" sentry-label="modalClose" onPress={closeModalWDStatus} />
      <View alignContent="center" alignItems="center" marginX="5" marginY={verticalScale(8)}>
        <View alignItems="center" alignContent="center">
          {imgStatusFile}
        </View>
        <Text variant="md-normal">{wdModalText}</Text>
      </View>
    </Modal.Content>
  </Modal>
);

export default ModalInactiveWAS;
