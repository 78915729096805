import CommonTextInput from '@atoms/CommonTextInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface ReferralValidatedInputProps {
  validatedReferralCode?: string;
}

const ReferralValidatedInput: React.FC<ReferralValidatedInputProps> = ({ validatedReferralCode = '' }) => {
  const { t } = useTranslation('common');

  return (
    <CommonTextInput
      testID="txtInputReferral"
      editable={false}
      size="md"
      style={styles.referralInputStyle}
      customBorderStyle={styles.customBorderStyle}
      value={validatedReferralCode}
      keyboardType="number-pad"
      placeholder={t('referral.inputPlaceHolder')}
    />
  );
};

export default ReferralValidatedInput;
