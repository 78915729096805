import RemoteImage from '@atoms/RemoteImage';
import { getStore } from '@store/storeUtils';
import { View, Text } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import logReasonTap from './analyticsReasonTile';
import styles from './styles';

interface Reason {
  id: number;
  reason: string;
  imageUrl: string;
  selected: boolean;
  empty: boolean;
}

interface ReasonsSwitchProps {
  source?: string;
  reason: Reason;
  selectedReason: Reason;
  setSelectedReason: (reasons: Reason[]) => void;
  setDisableContinue: (disabled: boolean) => void;
  allReasons: Reason[];
}

function reasonTapped({
  allReasons,
  selectedReason,
  setSelectedReason,
  setDisableContinue,
  setWithdrawalReason,
}: ReasonsSwitchProps & { setWithdrawalReason: (reason: Reason) => void }) {
  const changedReasons = allReasons.map(item => {
    if (selectedReason.reason.toUpperCase() === item.reason.toUpperCase()) {
      setWithdrawalReason({ ...item, selected: true });
      return { ...item, selected: true };
    }
    return { ...item, selected: false };
  });
  setSelectedReason(changedReasons);
  setDisableContinue(false);
}

const ReasonsSwitch: React.FC<ReasonsSwitchProps> = ({
  reason: selectedReason,
  setSelectedReason,
  setDisableContinue,
  allReasons,
  source = '',
}) => {
  const { setWithdrawalReason, selectedAccount, validatedReferralCode } = getStore();
  const { amplitudeAccountName } = selectedAccount;

  return (
    <TouchableOpacity
      testID={`btnReasonSwitch_${selectedReason.id}`}
      sentry-label="btnReasonSwitch"
      style={styles.reasonSwitch(selectedReason, selectedReason?.empty)}
      disabled={selectedReason?.empty}

      aria-label="reason"
      onPress={() => {
        logReasonTap(selectedReason, source, amplitudeAccountName, validatedReferralCode);
        reasonTapped({ allReasons, selectedReason, setSelectedReason, setDisableContinue, setWithdrawalReason });
      }}>
      <RemoteImage uri={selectedReason.imageUrl} width={40} height={40} />
      <View style={styles.reasonContainer}>
        <Text
          testID={`txtSelectedReason_${selectedReason.id}`}
          alignSelf="center"
          justifyContent="center"
          numberOfLines={2}
          variant="xsm-normal"
          overflow="hidden"
          minW={70}
          textAlign="center">
          {selectedReason.reason}
        </Text>
      </View>
      {!selectedReason?.empty && <View style={styles.selectedDotStyle(selectedReason)} />}
    </TouchableOpacity>
  );
};

export default ReasonsSwitch;
