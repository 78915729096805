import ModalClose from '@atoms/ModalClose';
import { useIsFocused } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Modal, Button } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import checkIfSDModalNeedsToBeShown from './sdModalUtil';

interface SDModalProps {
  showSDModal?: boolean;
}

interface AccountWallet {
  id: number;
  name: string;
  balance: number;
  accountTypeId: number;
}

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export function onPressHandler(
  setSelectedAccount: (account: AccountWallet) => void,
  sdAccountWallet: AccountWallet | null,
  setSDModalVisible: SetState<boolean>,
) {
  return () => {
    if (sdAccountWallet) {
      setSelectedAccount(sdAccountWallet);
    }
    setSDModalVisible(false);
  };
}

export function onCloseHandler(setSDModalVisible: SetState<boolean>) {
  return () => {
    setSDModalVisible(false);
  };
}

const SDModal: React.FC<SDModalProps> = ({ showSDModal = false }) => {
  const [sdAccountWallet, setSdAccountWallet] = useState<AccountWallet | null>(null);
  const { accounts, isInLockPeriod, setSelectedAccount } = getStore();
  const [sdModalVisible, setSDModalVisible] = useState<boolean>(false);
  const isFocused = useIsFocused();
  const { t } = useTranslation('common');

  useEffect(() => {
    checkIfSDModalNeedsToBeShown(
      accounts,
      setSdAccountWallet,
      showSDModal,
      isInLockPeriod,
      isFocused,
      setSDModalVisible,
    );
  }, [accounts, isInLockPeriod, showSDModal, isFocused]);

  return (
    <Modal
      closeOnOverlayClick
      isOpen={sdModalVisible}
      style={appStyles.modalParent}
      onClose={onCloseHandler(setSDModalVisible)}>
      <Modal.Content style={[appStyles.modalContainer, { backgroundColor: colors.neutral.cotton }]}>
        <ModalClose />
        <View alignContent="center" style={appStyles.marginHorizontal(16)}>
          <View alignItems="center">{SVG_ICONS.dynamicAccounts.artInfo}</View>
          <Text color={colors.neutral.charcoal} variant="xl-bold" style={appStyles.marginVertical(8)}>
            {t('sdModal.waitAMinute')}
          </Text>
          <Text color={colors.neutral.darkMist} variant="md-normal" style={appStyles.marginBottom(12)}>
            {t('sdModal.chooseSD')}
          </Text>
          <Button
            testID="btnCloseSDModal"
            variant="solid"
            style={appStyles.marginBottom(16)}
            onPress={onPressHandler(setSelectedAccount, sdAccountWallet, setSDModalVisible)}>
            {t('sdModal.sdBtn')}
          </Button>
        </View>
      </Modal.Content>
    </Modal>
  );
};

export default SDModal;
