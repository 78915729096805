import TextCurrency from '@atoms/TextCurrency';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import colors from '@theme/colors';
import { getDateInFormatLocaleWithInputFormat } from '@utils/dateUtils';
import { Text } from 'native-base';
import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import styles from './style';

interface BgColorStyles {
  bg?: unknown;
  txt?: unknown;
}

export const getBgColor = (count: number, idx: number, total: number): BgColorStyles => {
  if (count === 0 && idx === 0) {
    return { bg: styles.ongoingHighlighter, txt: styles.txtStyle };
  }
  if (count === total) {
    return { bg: styles.paidHighter, txt: styles.txtStyle };
  }
  if (count === idx) {
    return { bg: styles.ongoingHighlighter, txt: styles.txtStyle };
  }
  if (count > idx) {
    return { bg: styles.paidHighter, txt: styles.txtStyle };
  }
  return {};
};

interface InstallmentItem {
  status: string;
  dueDate: string;
  installmentValue: number;
}

interface InstallMentRowProps {
  item: InstallmentItem;
  index: number;
  total: number;
  installMentsData: InstallmentItem[];
}

export const InstallMentRow: React.FC<InstallMentRowProps> = ({ item, index, total, installMentsData }) => {
  const paidCount = installMentsData.filter(e => e.status === 'PAID').length;

  let displayMeta = {
    icon: 'check-circle',
    color: colors.success.darkCactus,
    text: 'Selesai',
  };

  if (item.status !== 'PAID') {
    displayMeta = {
      icon: 'calendar-blank',
      color: colors.neutral.darkMist,
      text: getDateInFormatLocaleWithInputFormat(item.dueDate, 'DD MMM YYYY', 'YYYY-MM-DD'),
    };
  }

  const bgStyles = getBgColor(paidCount, index, total) ?? {};
  const txtStyles = bgStyles.txt as StyleProp<TextStyle>;

  return (
    <View style={[styles.installmentRow, bgStyles.bg as StyleProp<ViewStyle>]}>
      <View>
        <Text style={[styles.srStyles, txtStyles]} variant="sm-bold">
          {index + 1}
          <Text variant="sm-bold" style={styles.totalTextColor}>
            {`/${total}`}{' '}
          </Text>
        </Text>
        <TextCurrency
          variant="sm-bold"
          amountCurrency={parseInt(item.installmentValue?.toString() ?? '0', 10)}
          showCurrencySymbol
          style={[styles.installTextSyle, txtStyles]}
        />
      </View>

      <View style={styles.secondPart}>
        <Icon name={displayMeta.icon} color={displayMeta.color} size={20} />
        <Text variant="sm-bold" style={styles.dueTextStyle}>
          {displayMeta.text}
        </Text>
      </View>
    </View>
  );
};

interface LoanInstallMentsProps {
  show: boolean;
  installMentsData: InstallmentItem[];
  t: (key: string) => string;
}

const LoanInstallMents: React.FC<LoanInstallMentsProps> = ({ show, installMentsData, t }) => {
  if (!show || installMentsData.length === 0) return null;

  return (
    <>
      <View style={styles.outerContainer}>
        <Text variant="md-bold">{t('loan.loanText1')}</Text>
        <Text variant="sm-normal" style={styles.descStyle}>
          {t('loan.loanText2')}
        </Text>
      </View>

      <View style={styles.titleContainer}>
        <View style={styles.titleRow}>
          <Text variant="sm-bold">{t('loan.loanText3')}</Text>
          <Text variant="sm-bold">{t('loan.loanText4')}</Text>
        </View>

        {installMentsData.map((o, i) => (
          <InstallMentRow
            key={i}
            item={o}
            index={i}
            total={installMentsData.length}
            installMentsData={installMentsData}
          />
        ))}
      </View>
    </>
  );
};

export default LoanInstallMents;
