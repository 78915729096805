import colors from '@theme/colors';
import { boldFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  balanceInput: { alignItems: 'center', flexDirection: 'row' },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: scale(4),
    marginTop: scale(4),
  },
  flexRowtwo: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  textInputStyle: {
    color: colors.neutral.charcoal,
    fontFamily: boldFont,
    fontSize: scale(14),
    fontWeight: 'normal',
    width: '80%',
    ...(Platform.OS === 'web' && { outlineStyle: 'none' }), // ✅ Only applies on web
  },
  thumbStyles: { backgroundColor: colors.primary.carnation, height: scale(20), width: scale(20) },
  trackStyles: { borderRadius: 8, height: 4 },
});
export default styles;
