import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logSecurityPin(): void {
  const e = AMPLITUDE_CONSTANTS.securityPin.create_pin_clicked;

  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
  });
}

export default logSecurityPin;
