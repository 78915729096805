import colors from '@theme/colors';
import { maskPhoneNumber } from '@utils/utils';
import { Text, View } from 'native-base';
import React from 'react';

interface MaskedPhoneNumberProps {
  mobileNumber?: string;
  commonColor?: string;
}

const MaskedPhoneNumber: React.FC<MaskedPhoneNumberProps> = ({
  mobileNumber = '',
  commonColor = colors.neutral.charcoal,
}) => (
  <View marginLeft="11px" marginTop="4px">
    <Text testID="txtMaskedPhone" variant="sm-bold" color={commonColor}>
      {maskPhoneNumber(mobileNumber)}
    </Text>
  </View>
);

export default MaskedPhoneNumber;
